import { FC } from 'react';
import './Tab.scss';
import { Link } from 'components/shared/Link/Link';
import { Icon } from 'components/shared/Icon/Icon';
import { IconEnums } from 'common/IconEnums';
import { SizeEnums } from 'common/SizeEnums';
import { useTabsLink } from 'hooks/tabs/use-tabs-link';

interface Props {
  tab: string;
  index: number;
  to: string;
  className: string;
  activeClassName: string;
  setActiveTabIndex?: (index: number) => void;
  icon?: IconEnums;
  onBeforeTabChange?: () => Promise<boolean>;
}

export const Tab: FC<Props> = ({
  tab = '',
  index,
  setActiveTabIndex,
  to,
  className,
  activeClassName,
  icon,
  onBeforeTabChange = () => Promise.resolve(true),
}) => {
  const { handleTabClick } = useTabsLink(to, onBeforeTabChange, index, setActiveTabIndex);

  return (
    <Link to={to} className={className} activeClassName={activeClassName} onClick={handleTabClick}>
      {icon && <Icon iconName={icon} iconSize={SizeEnums.SMALL} />}
      <span className="tab-text" title={tab}>
        {tab}
      </span>
    </Link>
  );
};
