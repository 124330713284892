import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config/endpoints';
import {
  AsyncThunkParams,
  BackendResponse,
  FileUploadRequest,
  UploadedFile,
  types,
  Regulation,
  Country,
  LanguageWithIcon,
  UserSessionResponse,
  PutSessionResponse,
  PutUpdateSessionRequest,
  PutSession,
} from './types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const getPermissionsMap = createAsyncThunk<[] | null, AsyncThunkParams<BackendResponse<[]>>>(
  types.PERMISSIONS_MAP_FETCH,
  async (props) => {
    try {
      const result = await props.execute('GET', endpoints.getPermissionsMap);

      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.PERMISSIONS_MAP_FETCH, err);
      return null;
    }
  },
);

export const getUserSession = createAsyncThunk<
  UserSessionResponse | null,
  AsyncThunkParams<BackendResponse<UserSessionResponse>>
>(types.USER_SESSION_FETCH, async ({ execute }) => {
  try {
    const result = await execute('GET', endpoints.getUserSession);
    return BackendResponseService.getBackendResponseData(result);
  } catch (err) {
    console.error(types.USER_SESSION_FETCH, err);
    return null;
  }
});

export const updateUserSession = createAsyncThunk<
  PutSession | null,
  AsyncThunkParams<PutSessionResponse, Partial<PutUpdateSessionRequest>>
>(types.USER_SESSION_PUT, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('PUT', endpoints.putUserSession, payload);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.USER_SESSION_PUT, err);
    throw err;
  }
});

export const uploadFile = createAsyncThunk<
  UploadedFile | null,
  AsyncThunkParams<BackendResponse<UploadedFile>, FileUploadRequest>
>(types.FILE_UPLOAD, async ({ execute, payload }) => {
  if (!payload) {
    return null;
  }

  try {
    // prepare multi/part form request
    const formData = new FormData();

    // fields
    formData.append('file', payload.file, payload.file.name);
    formData.append('altText', payload.altText);
    if (payload.siteId) {
      formData.append('siteId', String(payload.siteId));
    }
    formData.append('fileCategoryId', String(payload.fileCategoryId));

    const result = await execute('POST', endpoints.uploaderFile, formData as unknown as FileUploadRequest);

    return BackendResponseService.getBackendResponseData(result);
  } catch (err) {
    console.error(types.FILE_UPLOAD, err);
    return null;
  }
});

export const fetchLanguages = createAsyncThunk<
  LanguageWithIcon[] | null,
  AsyncThunkParams<BackendResponse<LanguageWithIcon[]>>
>(types.LANGUAGES_FETCH, async (props) => {
  try {
    const result = await props.execute('GET', endpoints.languages);

    return BackendResponseService.getBackendResponseData(result);
  } catch (err) {
    console.error(types.LANGUAGES_FETCH, err);
    return null;
  }
});

export const fetchRegulations = createAsyncThunk<Regulation[] | null, AsyncThunkParams<BackendResponse<Regulation[]>>>(
  types.REGULATIONS_FETCH,
  async (props) => {
    try {
      const result = await props.execute('GET', endpoints.regulationPropertiesRegulations);

      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.REGULATIONS_FETCH, err);
      return null;
    }
  },
);

export const fetchCountries = createAsyncThunk<Country[] | null, AsyncThunkParams<BackendResponse<Country[]>>>(
  types.COUNTRIES_FETCH,
  async (props) => {
    try {
      const result = await props.execute('GET', endpoints.countries);

      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.COUNTRIES_FETCH, err);
      return null;
    }
  },
);
