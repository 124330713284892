import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  types,
  GetBrandAssets,
  GetBrandAssetsResponse,
  PostBrandAsset,
  PostBrandAssetResponse,
  PatchBrandAsset,
  PatchBrandAssetResponse,
  GetLogosAssetsResponse,
  GetLogosAssetsFields,
  PutLogosRequest,
  DeleteBrandAsset,
  DeleteBrandAssetResponse,
  GetBrandAssetsRequest,
  GetBrandIcons,
  GetBrandIconsResponse,
  GetBrandIconsRequest,
  PutBrandIconsResponse,
  PutIconsRequest,
  PostBrandAssetsRequest,
  PatchBrandAssetsRequest,
  BrandsComponentsSettings,
  GetBrandsComponentsSettingsResponse,
  GetBrandsComponentsSettingsRequest,
  PutBrandsComponentsSettings,
  PutBrandsComponentsSettingsResponse,
  PutBrandsComponentsSettingsRequest,
  PatchBrandsComponentsSettingsResponse,
  PatchBrandsComponentsSettingsRequest,
  PatchBrandsComponentsSettings,
  PutBrandColorsRequest,
  PutBrandColorsResponse,
  GetBrandColorsResponse,
  GetBrandColorsPaletteResponse,
  PutBrandColorsPaletteResponse,
  PutBrandColorsPaletteRequest,
  GetBrandColorsPalette,
  GetBrandColors,
  PutBrandColorsThemes,
  GetBrandColorsThemesResponse,
  GetBrandColorsThemes,
  PutBrandColorsThemesResponse,
} from './types';
import { AsyncThunkParams } from '../app/types';
import { endpoints } from '../../config/endpoints';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchBrandAssets = createAsyncThunk<
  GetBrandAssets | null,
  AsyncThunkParams<GetBrandAssetsResponse, GetBrandAssetsRequest>
>(types.ASSETS_IMAGES_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams('');

      // optional tabName param
      if (payload.tabName) {
        params.append('tabName', payload.tabName.toString());
      }
      // optional systemGroupId param
      if (payload.systemGroupId) {
        params.append('systemGroupId', payload.systemGroupId.toString());
      }

      if (payload.orderBy) {
        params.append('orderBy', payload.orderBy.toString());
      }

      if (payload.orderDirection) {
        params.append('orderDirection', payload.orderDirection.toString());
      }

      if (payload.companyId) {
        params.append('companyId', payload.companyId.toString());
      }

      if (payload.siteId) {
        params.append('siteId', payload.siteId.toString());
      }

      const urlParams = params.toString() ? `?${params.toString()}` : '';

      const url = `${endpoints.brandAssetsGetImages}${urlParams}`;

      const result = await execute('GET', url);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.ASSETS_IMAGES_FETCH, err);
    throw err;
  }
});

export const fetchBrandIcons = createAsyncThunk<
  GetBrandIcons | null,
  AsyncThunkParams<GetBrandIconsResponse, GetBrandIconsRequest>
>(types.ASSETS_ICONS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams('');

      if (payload.tabName) {
        params.append('tabName', payload.tabName.toString());
      }

      const result = await execute(
        'GET',
        `${endpoints.brandAssetsGetIcons.replace(':siteId', payload.siteId)}?${params}`,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.ASSETS_ICONS_FETCH, err);
    throw err;
  }
});

export const postBrandAsset = createAsyncThunk<
  PostBrandAsset | null,
  AsyncThunkParams<PostBrandAssetResponse, PostBrandAssetsRequest>
>(types.ASSETS_IMAGES_ASSET_POST, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('POST', endpoints.brandAssetsPostAsset, payload);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.ASSETS_IMAGES_ASSET_POST, err);
    throw err;
  }
});

export const patchBrandAsset = createAsyncThunk<
  PatchBrandAsset | null,
  AsyncThunkParams<PatchBrandAssetResponse, PatchBrandAssetsRequest>
>(types.ASSETS_IMAGES_ASSET_PATCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('PATCH', endpoints.brandAssetsPatchAsset, payload);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.ASSETS_IMAGES_ASSET_POST, err);
    throw err;
  }
});

export const deleteBrandAsset = createAsyncThunk<
  DeleteBrandAsset | null,
  AsyncThunkParams<DeleteBrandAssetResponse, { assetId: number }>
>(types.ASSETS_IMAGES_ASSET_DELETE, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute(
        'DELETE',
        endpoints.brandAssetsDeleteAsset.replace(':assetId', payload.assetId.toString()),
        payload,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.ASSETS_IMAGES_ASSET_DELETE, err);
    throw err;
  }
});

export const fetchLogosAssets = createAsyncThunk<
  GetLogosAssetsFields | null,
  AsyncThunkParams<GetLogosAssetsResponse, { siteId: number }>
>(types.ASSETS_LOGOS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute(
        'GET',
        endpoints.brandAssetsGetLogos.replace(':siteId', payload.siteId.toString() as string),
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.ASSETS_LOGOS_FETCH, err);
    throw err;
  }
});

export const putLogosAssets = createAsyncThunk<void | null, AsyncThunkParams<void, PutLogosRequest>>(
  types.ASSETS_LOGOS_PUT,
  async ({ execute, payload }) => {
    try {
      await execute('PUT', endpoints.brandAssetsPutLogos, payload);
    } catch (err) {
      console.error(types.ASSETS_LOGOS_PUT, err);
      throw err;
    }
  },
);

export const putBrandIcons = createAsyncThunk<void | null, AsyncThunkParams<PutBrandIconsResponse, PutIconsRequest>>(
  types.ASSETS_ICONS_PUT,
  async ({ execute, payload }) => {
    try {
      await execute('PUT', endpoints.brandAssetsPutIcons, payload);
    } catch (err) {
      console.error(types.ASSETS_ICONS_PUT, err);
      throw err;
    }
  },
);

export const fetchBrandsComponentsSettings = createAsyncThunk<
  BrandsComponentsSettings | null,
  AsyncThunkParams<GetBrandsComponentsSettingsResponse, GetBrandsComponentsSettingsRequest>
>(types.ASSETS_GLOBAL_COMPONENTS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams('');

      const result = await execute(
        'GET',
        `${endpoints.brandComponentsSettings.replace(':siteId', payload.siteId)}?${params}`,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.ASSETS_GLOBAL_COMPONENTS_FETCH, err);
    throw err;
  }
});

export const putBrandsComponentsSettings = createAsyncThunk<
  PutBrandsComponentsSettings | null,
  AsyncThunkParams<PutBrandsComponentsSettingsResponse, PutBrandsComponentsSettingsRequest>
>(types.ASSETS_GLOBAL_COMPONENTS_PUT, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('PUT', endpoints.brandComponentsSettingsPut, payload);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.ASSETS_GLOBAL_COMPONENTS_PUT, err);
    throw err;
  }
});

export const patchBrandsComponentsSettings = createAsyncThunk<
  PatchBrandsComponentsSettings | null,
  AsyncThunkParams<PatchBrandsComponentsSettingsResponse, PatchBrandsComponentsSettingsRequest>
>(types.ASSETS_GLOBAL_COMPONENTS_PATCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('PATCH', endpoints.brandComponentsSettingsPatch, payload);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.ASSETS_GLOBAL_COMPONENTS_PATCH, err);
    throw err;
  }
});
export const getBrandColors = createAsyncThunk<
  GetBrandColors | null,
  AsyncThunkParams<GetBrandColorsResponse, { siteId: number }>
>(types.ASSETS_COLORS_GET, async ({ execute, payload }) => {
  if (payload) {
    try {
      const result = await execute(
        'GET',
        endpoints.brandAssetsGetColors.replace(':siteId', payload?.siteId.toString()),
      );

      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.ASSETS_COLORS_GET, err);
      throw err;
    }
  } else {
    return null;
  }
});

export const putBrandColors = createAsyncThunk<
  void | null,
  AsyncThunkParams<PutBrandColorsResponse, PutBrandColorsRequest>
>(types.ASSETS_COLORS_PUT, async ({ execute, payload }) => {
  try {
    await execute('PUT', endpoints.brandAssetsPutColors, payload);
  } catch (err) {
    console.error(types.ASSETS_COLORS_PUT, err);
    throw err;
  }
});

export const getBrandColorsThemes = createAsyncThunk<
  GetBrandColorsThemes | null,
  AsyncThunkParams<GetBrandColorsThemesResponse, { siteId: number }>
>(types.ASSETS_COLORS_THEMES_GET, async ({ execute, payload }) => {
  if (payload) {
    try {
      const result = await execute(
        'GET',
        endpoints.brandAssetsGetColorsThemes.replace(':siteId', payload?.siteId.toString()),
      );

      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.ASSETS_COLORS_THEMES_GET, err);
      throw err;
    }
  } else {
    return null;
  }
});

export const putBrandColorsThemes = createAsyncThunk<
  void | null,
  AsyncThunkParams<PutBrandColorsThemesResponse, PutBrandColorsThemes>
>(types.ASSETS_COLORS_THEMES_PUT, async ({ execute, payload }) => {
  try {
    await execute('PUT', endpoints.brandAssetsPutColorsThemes, payload);
  } catch (err) {
    console.error(types.ASSETS_COLORS_THEMES_PUT, err);
    throw err;
  }
});

export const getBrandColorsPalette = createAsyncThunk<
  GetBrandColorsPalette | null,
  AsyncThunkParams<GetBrandColorsPaletteResponse, { siteId: number }>
>(types.ASSETS_COLORS_PALETTE_GET, async ({ execute, payload }) => {
  if (payload) {
    try {
      const result = await execute(
        'GET',
        endpoints.brandAssetsGetColorsPalette.replace(':siteId', payload?.siteId.toString()),
      );

      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.ASSETS_COLORS_PALETTE_GET, err);
      throw err;
    }
  } else {
    return null;
  }
});

export const putBrandColorsPalette = createAsyncThunk<
  void | null,
  AsyncThunkParams<PutBrandColorsPaletteResponse, PutBrandColorsPaletteRequest>
>(types.ASSETS_COLORS_PALETTE_PUT, async ({ execute, payload }) => {
  try {
    await execute('PUT', endpoints.brandAssetsPutColorsPalette, payload);
  } catch (err) {
    console.error(types.ASSETS_COLORS_PALETTE_PUT, err);
    throw err;
  }
});
