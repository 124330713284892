import { FC } from 'react';
import './NoBrandComponent.scss';
import { Icon } from '../Icon/Icon';
import { IconEnums } from 'common/IconEnums';

export const NoBrandComponent: FC = () => {
  return (
    <div className="no-brand-component">
      <div className="no-brand-component-content">
        <Icon iconName={IconEnums.GHOST} />
        <div className="no-brand-component-content__title">Select a brand to view</div>
        <div className="no-brand-component-content__subtitle">Nothing is selected</div>
      </div>
    </div>
  );
};
