import './index.scss';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { WithRouter } from './components/shared/WithRouter/WithRouter';
import { getRoutesConfig } from './router/routes';
import { ChunkLoader } from './components/layouts/ChunkLoader/ChunkLoader';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);

  const routesConfig = getRoutesConfig();

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <Suspense
          fallback={
            <BrowserRouter>
              <ChunkLoader />
            </BrowserRouter>
          }
        >
          <BrowserRouter>
            <WithRouter routes={routesConfig} />
          </BrowserRouter>
        </Suspense>
      </Provider>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
