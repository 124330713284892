import { useAppSelector } from '../store/hooks';
import { InstanceMenuItem } from '../components/shared/InstancesMenu/InstancesMenu';

export const useChangeInstances = () => {
  const { cmsInstances } = useAppSelector((state) => state.app);

  const currentPathname = window.location.origin;

  const currentInstance = cmsInstances?.find((instance) => instance.url === currentPathname);

  const instanceMenuItems: InstanceMenuItem[] = cmsInstances.map(({ name, url, icon }) => ({
    text: name,
    icon,
    url,
    onClick: () => window.location.replace(url),
  }));

  return {
    currentInstanceIcon: currentInstance?.icon || 'vegas',
    currentInstanceUrl: currentInstance?.url,
    currentInstanceName: currentInstance?.name || 'Vegasapp',
    instanceMenuItems,
  };
};
