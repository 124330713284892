import { BackendResponse, Country, LanguageWithIcon } from '../app/types';
import { Experience, PutExperienceRequest } from '../experience/types';

export const types = Object.freeze({
  LOCALIZATION_PROPS_FETCH: '[NetworkEditor] LOCALIZATION_PROPS_FETCH',
  LOCALIZATION_PROPS_PATCH: '[NetworkEditor] LOCALIZATION_PROPS_PATCH',
  CREDENTIALS_PAM_INSTANCES_FETCH: '[NetworkEditor] CREDENTIALS_PAM_INSTANCES_FETCH',
  CREDENTIALS_PAM_INSTANCES_PUT: '[NetworkEditor] CREDENTIALS_PAM_INSTANCES_PUT',
  NETWORK_EXPERIENCE_FETCH: '[NetworkEditor] NETWORK_EXPERIENCE_FETCH',
  NETWORK_EXPERIENCE_PUT: '[NetworkEditor] NETWORK_EXPERIENCE_PUT',
  NETWORK_EXPERIENCE_WIDGETS_KEYS_FETCH: '[NetworkEditor] NETWORK_EXPERIENCE_WIDGETS_KEYS_FETCH',
  NETWORK_EXPERIENCE_WIDGETS_KEYS_PUT: '[NetworkEditor] NETWORK_EXPERIENCE_WIDGETS_KEYS_PUT',
  NETWORK_EXPERIENCE_WIDGETS_TOPICS_FETCH: '[NetworkEditor] NETWORK_EXPERIENCE_WIDGETS_TOPICS_FETCH',
  NETWORK_EXPERIENCE_WIDGETS_TOPICS_PUT: '[NetworkEditor] NETWORK_EXPERIENCE_WIDGETS_TOPICS_PUT',
});

export type NetworkEditorState = {
  localizationProperties: GetLocalizationPropertiesFields | null;
  pamInstancesProperties: GetPamInstancesFields | null;
  networkExperienceProperties: GetNetworkExperienceFields | null;
};

export type GetLocalizationPropertiesResponse = BackendResponse<GetLocalizationPropertiesFields>;
export type PatchLocalizationPropertiesResponse = BackendResponse<void>;
export type GetPamInstancesResponse = BackendResponse<GetPamInstancesFields>;
export type PutPamInstancesResponse = BackendResponse<void>;
export type GetNetworkExperienceResponse = BackendResponse<GetNetworkExperienceFields>;
export type PutNetworkExperienceResponse = BackendResponse<UpdatedExperienceItems>;
export type GetNetworkExperienceWidgetsKeysResponse =
  BackendResponse<GetNetworkExperienceWidgetsKeysWithTranslationsFields>;
export type PutNetworkExperienceWidgetsKeysResponse = BackendResponse<UpdatedWidgetsKeys>;
export type GetNetworkExperienceWidgetsAndTopicsResponse = BackendResponse<GetNetworkExperienceWidgetsAndTopicsFields>;
export type PutNetworkExperienceWidgetsAndTopicsResponse = BackendResponse<UpdatedWidgetsAndTopics>;

export type GetLocalizationPropertiesFields = {
  languages: LanguageWithIcon[];
  currencies: Currency[];
  currenciesDictionary: CurrenciesDictionary[];
  countries: Country[];
  countriesDictionary: CountriesDictionary[];
  brandsLocalizationProperties: BrandsLocalizationProperty[];
  regulationsDictionary: RegulationDictionary[];
};

export type BrandsLocalizationProperty = {
  siteId: number;
  brandName: string;
  regulations: BrandsLocalizationPropertyRegulation[];
  languages: LanguageWithIcon[];
};

export type BrandsLocalizationPropertyRegulation = {
  regulationId: number;
  regulationCode: string;
};

export type CountriesDictionary = {
  dictionaryCountryId: number;
  countryCode: string;
  countryName: string;
};

export type Currency = {
  currencyId: number;
  currencyCode: string;
  currencySign: string;
  regulations: CurrencyRegulation[];
};

export type CurrencyRegulation = {
  regulationId: number;
  regulationCode: string;
  regulationName: string;
};

export type CurrenciesDictionary = {
  currencyCode: string;
  currencySign: string;
  dictionaryCurrencyId: number;
};

export type RegulationDictionary = {
  regulationId: number;
  regulationCode: string;
  regulationName: string;
};

export type PatchLocalizationPropertiesRequest = {
  languages: PatchLanguage[];
  currencies: PatchCurrency[];
  countries: PatchCountry[];
  deleteLanguages: number[];
  deleteCurrencies: number[];
  deleteCountries: number[];
};

export type PatchLanguage = {
  languageId?: LanguageWithIcon['languageId'];
  iconFileId: number | null;
} & Pick<LanguageWithIcon, 'languageCode' | 'languageName' | 'fallbackLanguageId'>;

export type PatchCurrency = {
  currencyId?: Currency['currencyId'];
  fallbackRegulations: number[];
} & Pick<Currency, 'currencyCode'>;

export type PatchCountry = {
  countryId?: Country['countryId'];
} & Pick<Country, 'countryCode' | 'regionCode' | 'languageId' | 'currencyId'>;

/**
 * Pam Instances
 */

export type GetPamInstancesFields = {
  pamInstances: PamInstance[];
  regulationsDictionary: RegulationDictionary[];
};

export enum PamInstanceUrlsEnum {
  CMS_ADAPTER = 1,
  EXTERNAL_SERVICES = 2,
  ASG_WEB_API = 3,
  WEB_API = 4,
  ASG_USA_REST_API = 5,
  GAME_SERVER = 6,
  INFO_PAGES_SERVER = 7,
}

export type PamInstanceUrl = {
  url: string;
  urlType: PamInstanceUrlsEnum;
};

export type PamInstance = {
  pamInstanceId: number;
  urls: PamInstanceUrl[];
  pamInstanceDescription: string;
  regulations: number[];
};

export type PutPamInstance = {
  pamInstanceId?: PamInstance['pamInstanceId'];
  urls: PamInstanceUrl[];
  pamInstanceDescription: string;
  regulations: number[];
};

export type PutPamInstancesRequest = {
  pamInstances: PutPamInstance[];
  deletePamInstancesIds: number[];
};

/**
 * Network experience
 */

export type GetNetworkExperienceFields = {
  experience: Experience[];
  gamesCategories: ExperienceGameCategory[];
};

export type ExperienceGameCategory = {
  id: number;
  title: string | null;
  thumbnailUrl: string | null;
  url: string | null;
};

export type UpdatedExperienceItems = {
  updatedExperienceItems: boolean;
};

export type PutNetworkExperienceRequest = PutExperienceRequest;

/**
 * Network experience widgets
 */
export type Widget = {
  widgetId: number;
  widgetName: string;
};

export type WidgetTopic = {
  widgetTopicId: number;
  widgetTopicName: string;
  widgetId: number;
};

export type WidgetKey = {
  widgetKeyId: number;
  widgetKeyName: string;
  widgetTopicId: number;
};

export type WidgetKeyExcludedRegulation = {
  regulationId: number;
  regulationCode: string;
};

export type WidgetKeyWithExcludedRegulations = WidgetKey & {
  regulationsExcluded: WidgetKeyExcludedRegulation[];
};

export type WidgetKeyTranslation = {
  widgetKeyTranslationId: number;
  widgetKeyTranslation: string;
  widgetKeyId: number;
  translationId: number;
  isDefault: boolean;
  languageId: number;
  languageCode: string;
  regulationId: number | null;
  regulationCode: string | null;
};

export type GetNetworkExperienceWidgetsKeysWithTranslationsFields = {
  widgets: Widget[];
  widgetsTopics: WidgetTopic[];
  widgetsKeys: WidgetKeyWithExcludedRegulations[];
  widgetsKeysTranslations: WidgetKeyTranslation[];
};

export type UpdateWidgetKeyTranslation = {
  widgetKeyTranslationId?: number;
  translationId?: number;
};

export type UpdateWidgetKey = {
  widgetKeyId?: number;
  widgetKeyName: string;
  widgetTopicId: number;
  regulationsExcluded: number[];
  keysTranslations: UpdateWidgetKeyTranslation[];
};

export type PutNetworkExperienceWidgetsKeysRequest = {
  widgetKeys: UpdateWidgetKey[];
  deletedWidgetKeyIds: number[];
  deletedWidgetKeyTranslationIds: number[];
};

export type UpdatedWidgetsKeys = {
  updatedWidgetsKeys: boolean;
};

export type GetNetworkExperienceWidgetsAndTopicsFields = {
  widgets: Widget[];
  widgetsTopics: WidgetTopic[];
};

export type UpdateWidgetTopicDto = {
  widgetTopicId?: number;
  widgetTopicName: string;
};

export type UpdateWidget = {
  widgetId?: number;
  widgetName: string;
  widgetsTopics: UpdateWidgetTopicDto[];
};

export type PutNetworkExperienceWidgetsAndTopicsRequest = {
  widgets: UpdateWidget[];
  deletedWidgetIds: number[];
  deletedWidgetsTopicsIds: number[];
};

export type UpdatedWidgetsAndTopics = {
  updatedWidgetsAndTopics: boolean;
};
