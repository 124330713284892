import { BrandAsset } from '../assets/types';
import { SortDirectionEnum, SortOptionsValuesEnum } from 'common/SortEnum';

export const types = Object.freeze({
  NETWORK_MEDIA_IMAGES_FETCH: '[NetworkMedia] NETWORK_MEDIA_IMAGES_FETCH',
  NETWORK_MEDIA_IMAGES_ASSET_POST: '[NetworkMedia] NETWORK_MEDIA_IMAGES_ASSET_POST',
  NETWORK_MEDIA_IMAGES_ASSET_PATCH: '[NetworkMedia] NETWORK_MEDIA_IMAGES_ASSET_PATCH',
  NETWORK_MEDIA_IMAGES_ASSET_DELETE: '[NetworkMedia] NETWORK_MEDIA_IMAGES_ASSET_DELETE',
  NETWORK_MEDIA_ICONS_FETCH: '[NetworkMedia] NETWORK_MEDIA_ICONS_FETCH',
  NETWORK_MEDIA_ICONS_PUT: '[NetworkMedia] NETWORK_MEDIA_ICONS_PUT',
});

export type NetworkMediaState = {
  networkIcons: BrandAsset[] | null;
  networkImages: BrandAsset[] | null;
};

export type GetNetworkImagesRequest = {
  tabName?: string;
  systemGroupId?: number;
  orderBy?: SortOptionsValuesEnum;
  orderDirection?: SortDirectionEnum;
};

export type GetNetworkIconsRequest = {
  tabName?: string;
};
