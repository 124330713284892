import './App.scss';
import { FC } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsalAuth } from 'hooks/use-msal-auth';
import { Outlet } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { NotificationProvider } from 'components/shared/NotificationProvider/NotificationProvider';
import { TabsValidationContext } from 'contexts/Validation/tabs-validation.context';
import { useCreateTabsValidator } from 'hooks/tabs/use-create-tabs-validator';
import { CommonCmsEntities } from 'components/shared/CommonCmsEntities/CommonCmsEntities';
import { NavigationSidebar } from '../layouts/NavigationSidebar/NavigationSidebar';
import { useSaveSessionData } from '../../hooks/use-save-session-data';
import { CONTENT_POPUP_CONTAINER, GLOBAL_POPUP_CONTAINER } from '../../config/portals';

export const AppContent: FC = () => {
  // Redirect to auth page when unauthenticated
  useMsalAuth();

  useSaveSessionData();

  const { configReady } = useAppSelector((state) => state.app);

  const tabValidator = useCreateTabsValidator();

  return (
    <AuthenticatedTemplate>
      <TabsValidationContext.Provider value={tabValidator}>
        <NotificationProvider>
          {configReady && (
            <div className="app">
              <CommonCmsEntities />
              <div className="app__navigation">
                <NavigationSidebar onBeforeTabChange={tabValidator.onBeforeTabChange} />
              </div>
              <div className="app__content">
                <Outlet />
              </div>
              <div id={CONTENT_POPUP_CONTAINER} />
              <div id={GLOBAL_POPUP_CONTAINER} />
            </div>
          )}
          {!configReady && <div>Loading...</div>}
        </NotificationProvider>
      </TabsValidationContext.Provider>
    </AuthenticatedTemplate>
  );
};
