export enum SystemBlockTypes {
  ADDITIONAL_PARAMETERS = 'Additional parameters',
  STATUS = 'Status',
  PRODUCT = 'Product',
  MOBILE_APPS = 'Mobile apps',
  CORE_FEATURE_MANAGEMENT = 'Core feature management',
  GAMES_MANAGEMENT = 'Games management',
  VERTICALS = 'Verticals',
  PAM_INSTANCES = 'PAM instances',
  TEMPLATE_1_CATEGORIES_ASSIGNED_TO_WEB_COMPONENTS_NETWORK = 'Template 1 categories assigned to web components',
  TEMPLATE_1_CATEGORIES_ASSIGNED_TO_WEB_COMPONENTS = 'Categories assigned to web components',
  GOOGLE_ANALYTICS = 'Google Analytics',
  CHAT_SUPPORT = 'Chat support',
  SUPPORT_TICKETS = 'Support tickets',
  CUSTOMER_DATA_PLATFORM = 'Customer data platform',
  CRM_TICKETS = 'CRM tickets',
  T1_FEATURES = 'T1 Features',
  COLOR_PALETTE = 'Color palette',
  BUTTONS_COLORS = 'Buttons colors',
  MAIN_THEME = 'Main theme',
  CUSTOM_THEMES = 'Custom themes',
  LANDING_PAGE_LOGOS = 'Landing page logos',
  FAVICON = 'Favicon',
  BROWSE_LOGO = 'Browse logo',
  OTHER_LOGOS = 'Other logos',
  BROWSE_PAGES_GALLERY = 'Browse pages gallery',
  SPORTS_THUMBNAIL_BACKGROUNDS = 'Sports thumbnail backgrounds',
  PLAYER_AVATARS_GALLERY = 'Player avatars gallery',
  LANDING_PAGES_GALLERY = 'Landing pages gallery',
  PROMOTIONS_GALLERY = 'Promotions gallery',
  TOURNAMENTS_RANKS = 'Tournaments ranks',
  ARTICLES = 'Articles',
  COMPANIES_GAME_THUMBNAILS = 'Company`s game thumbnails',
  COMPANIES_GAME_PROVIDER_IMAGES = 'Company`s game provider images',
  COMPANIES_GAME_PAGE_IMAGES = 'Company`s game page images',
  CUSTOM_LOGOS = 'Custom logos',
  LOYALTY_LEVELS_GALLERY = 'Loyalty levels gallery',
  AWARDS_GALLERY = 'Awards gallery',
  AFFILIATE_SEALS = 'Affiliate seals',
  THUMBNAILS_SKELETON_LOADERS = 'Thumbnails skeleton loaders',
  LOADING_SCREEN = 'Loading screen',
  HOME_ICONS = 'Home icons',
  LOGIN_ICONS = 'Login icons',
  LOGIN_ICONS_SMALL = 'Login icons small',
  LOGIN_ICONS_LARGE = 'Login icons large',
  ACTIONS_AND_INTERACTIONS = 'Actions and indications',
  GAME_PAGE_INTERACTIONS = 'Game page interactions',
  SEARCH_INTERACTIONS = 'Search interactions',
  GENERAL_CATEGORIES = 'General categories',
  CASINO_CATEGORIES = 'Casino categories',
  SPORTS_MENU = 'Sports menu',
  SPORTS_CATEGORIES = 'Sports categories',
  LIVE_CASINO_THUMBNAILS = 'Live casino thumbnails',
  BINGO_THUMBNAILS = 'Bingo thumbnails',
  SOCIAL_MEDIA = 'Social media',
  CONTACT_AND_LINKS = 'Contact and links',
  APPLICATION_STORES = 'Application stores',
  BROWSER_DARK_MODE = 'Browser dark mode',
  FONT = 'Font',
  UI_COMPONENTS = 'UI components',
  ICONS_CONFIGURATION = 'Icons configuration',
  MOBILE_BOTTOM_NAVIGATION_APPEARANCE = 'Mobile bottom navigation appearance',
  DEMO_GAMES = 'Demo games',
  THUMBNAILS_TOOLTIP = 'Thumbnail’s tooltip',
  CASINO_GAMES_CATEGORY_GAMES_POOL = 'Games pool',
  CASINO_GAMES_CATEGORY_CONTENT = 'Category content',
  CASINO_GAMES_GAMES_ORDERING = 'Games ordering',
  INCLUDED_IN_CATEGORY_GROUPS = 'Included in category groups',
  CATEGORIES_INCLUDED = 'Categories included',
  BADGES = 'Badges',
  NETWORK_GAME_THUMBNAILS = 'Game thumbnails',
  NETWORK_GAME_PROVIDER_IMAGES = 'Game provider images',
  NETWORK_GAME_PAGE_IMAGES = 'Game page images',
  GLOBAL_COMPONENTS_FONT = 'Font',
  WIDGETS_THEME = 'Widgets theme',
}

export enum SystemBlockTableTypes {
  LANGUAGES = 'Languages',
  CURRENCIES = 'Currencies',
  COUNTRIES_AND_REGIONS = 'Countries and regions',
  FRONT_END_TOKENS = 'Front-end tokens',
  AE_API_CREDENTIALS = 'AE API credentials',
  RECOMMENDED_GAMES_MODEL = 'Recommended games model',
  LOYALTY_LEVEL_CUSTOM_COLORS = 'Loyalty level custom colors',
  BRAND_DOMAINS = 'Brand domains',
  SCHEDULED_BROWSE_LOGO = 'Scheduled browse logo',
  CUSTOM_HOME_ICONS = 'Custom home icons',
  JACKPOTS = 'Jackpots',
  CUSTOM_CATEGORIES_ICONS = 'Custom categories icons',
  CUSTOM_SPORT_ICONS = 'Custom sport icons',
  LOYALTY_LEVEL_ICONS = 'Loyalty level icons',
  BADGE_BACKGROUNDS = 'Badge backgrounds',
  BADGES = 'Badges',
  CUSTOM_SOCIAL_ICONS = 'Custom social icons',
  REGULATIONS = 'Regulations',
  NEO_LICENSE_PROPERTIES = "Neo's license properties",
  MANAGE_MOBILE_BOTTOM_NAVIGATION_LINKS = 'Manage mobile bottom navigation links',
  DESKTOP_HEADER = 'Desktop header',
  MOBILE_IN_PAGE_VERTICALS_BAR = 'Mobile in-page verticals bar',
  BOTTOM_MENU = 'Bottom menu',
}
