import { SyntheticEvent, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppActions, useAppDispatch, useAppSelector } from '../store/hooks';

export const useSidebarLink = (url: string, onBeforeTabChange: () => Promise<boolean>, isBrandItem?: boolean) => {
  const { siteId, isBrandsList } = useAppSelector((state) => state.app);
  const { handleBrandsList } = useAppActions();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const iaActiveLink = pathname.split('/')[1] === url.split('/')[1];

  const handleMenuClick = useCallback(
    async (e: SyntheticEvent) => {
      // prevent default link behavior
      e.preventDefault();

      if (isBrandsList) {
        dispatch(handleBrandsList(false));
      }

      // check whether link can be changed
      const canChangeTab = await onBeforeTabChange();

      // if link cannot be changed, do nothing
      if (!canChangeTab) {
        return;
      }

      // otherwise, navigate to the link
      if (isBrandItem) {
        navigate(`${url}/${siteId}`);
      } else {
        navigate(url);
      }
    },
    [onBeforeTabChange, navigate, siteId, isBrandsList],
  );

  return {
    iaActiveLink,
    handleMenuClick,
  };
};
