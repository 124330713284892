import { useCallback } from 'react';
import { useAppActions, useAppDispatch } from 'store/hooks';
import { useFetchWithMsal } from 'hooks/use-fetch-with-msal';

export const useMenusGuards = () => {
  const dispatch = useAppDispatch();
  const { fetchMenusGuards } = useAppActions();
  const { execute } = useFetchWithMsal();

  const doGetMenusGuardsRequest = () => {
    dispatch(
      fetchMenusGuards({
        execute,
      }),
    );
  };

  return {
    doGetMenusGuardsRequest: useCallback(doGetMenusGuardsRequest, [execute]),
  };
};
