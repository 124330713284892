import { useAppActions, useAppDispatch, useAppSelector } from '../store/hooks';

export const useMenuLogoProps = () => {
  const { isBrandsList, isBrandsSettingsList } = useAppSelector((state) => state.app);
  const { isMinimizeMode } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const { handleNavigationMode } = useAppActions();

  const setIsMinimizeMenuByLogo = async () => {
    if (!isBrandsList && !isBrandsSettingsList) {
      await dispatch(handleNavigationMode(!isMinimizeMode));
    }
  };

  const isBrandsListAvailable = isBrandsList || isBrandsSettingsList;

  return {
    isBrandsListAvailable,
    isMinimizeMode,
    setIsMinimizeMenuByLogo,
  };
};
