import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import './CurrentBrandAction.scss';
import { ActionButton } from 'components/shared/ActionButton/ActionButton';
import { BgShapeEnum, IconEnums } from 'common/IconEnums';
import { SizeEnums } from 'common/SizeEnums';
import { BrandItem } from '../BrandItem/BrandItem';

interface Props {
  isMinimize: boolean;
  isHovered: boolean;
  toggleList: () => void;
  brandTemplate: number;
  brandType: number;
  asset?: string | null;
}

export const CurrentBrandAction: FC<Props> = ({
  isMinimize,
  isHovered,
  toggleList,
  brandTemplate,
  brandType,
  asset = '',
}) => {
  const navigate = useNavigate();

  const currentBrandClassName = classNames({
    'current-brand-action': true,
    'current-brand-action--minimize': isMinimize,
    'current-brand-action--minimize--hover': isHovered,
  });

  const handleToggleList = useCallback(() => {
    toggleList();
    navigate('/');
  }, []);

  return (
    <div className={currentBrandClassName}>
      <ActionButton
        iconName={IconEnums.ARROW1}
        bgSize={SizeEnums.LARGE}
        bgShape={BgShapeEnum.CIRCLE}
        onClickHandler={handleToggleList}
        className="current-brand-action-back-cta"
      />

      <BrandItem
        brandTemplate={brandTemplate}
        brandType={brandType}
        asset={asset}
        className="current-brand-selected-brand"
      />
    </div>
  );
};
