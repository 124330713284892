import { useEffect } from 'react';
import { throttle } from 'throttle-debounce';
import { useAppActions, useAppDispatch, useAppSelector } from '../store/hooks';

export const useIsMinimizeMenuByScreen = () => {
  const { isMinimizeMode, isBrandsSettingsList, isBrandsList } = useAppSelector((state) => state.app);
  const { handleNavigationMode } = useAppActions();
  const dispatch = useAppDispatch();

  const setIsMinimizeMenuByScreenSize = () => {
    if (window.innerWidth <= 1024 && isBrandsSettingsList && isBrandsList) {
      dispatch(handleNavigationMode(false));
    } else {
      dispatch(handleNavigationMode(true));
    }
  };

  useEffect(() => {
    // For initial page load
    setIsMinimizeMenuByScreenSize();

    const calculateThrottled = throttle(250, setIsMinimizeMenuByScreenSize);

    window.addEventListener('resize', calculateThrottled);

    return () => {
      window.removeEventListener('resize', calculateThrottled);
    };
  }, []);

  useEffect(() => {
    dispatch(handleNavigationMode(isMinimizeMode));
  }, [isMinimizeMode]);
};
