import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppLinks } from 'router/uri';
import { IconEnums } from 'common/IconEnums';

export type RightTabsType = {
  [key: string]: { link: AppLinks; text: string; icon: IconEnums }[];
};

export function useResetNavigationBar(
  tab: string,
  subtab: string,
  rightTabs: RightTabsType,
  rootPage: string,
  siteId = '',
) {
  const navigate = useNavigate();

  // reset selected top and right navigation bar
  useEffect(() => {
    if (tab && !subtab && rightTabs[tab] && Object.keys(rightTabs[tab]).length > 0) {
      navigate(
        `${rootPage.replace(':siteId', siteId)}/${tab.replace(
          / /g,
          '-',
        )}/${rightTabs[tab][0].text.replace(/ /g, '-').toLowerCase()}`,
      );
    }
  }, [tab, subtab]);
}
