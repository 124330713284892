import { ChangeEvent, FC, useRef, useState } from 'react';

import './NewBrandModal.scss';

import { Modal } from 'components/shared/Modal/Modal';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { SystemCta } from 'components/shared/SystemCta/SystemCta';
import { Formik } from 'formik';
import { PureInput } from 'components/shared/PureInput/PureInput';
import { PureInputTypesEnum } from 'components/shared/PureInput/types';
import { Brand, BrandTemplatesEnum } from 'store/brands/types';

interface Props {
  toggleModal: () => void;
  brandName: string;
  brandTemplate: BrandTemplatesEnum;
  submitNewBrand: (brand: Partial<Brand>) => void;
}

export const NewBrandModal: FC<Props> = ({ toggleModal, brandName, brandTemplate, submitNewBrand }) => {
  const [casinoId, setCasinoId] = useState<string>('');
  const ref = useRef(null);
  useOnClickOutside(ref, toggleModal);

  const handleSetCasinoId = (e: ChangeEvent<HTMLInputElement>): void => setCasinoId(e.target.value);

  const onSubmit = async () => {
    submitNewBrand({
      brandName,
      brandTemplate,
      casinoId: casinoId ? Number(casinoId) : null,
    });
    setCasinoId('');
  };

  return (
    <Formik initialValues={[]} onSubmit={() => {}}>
      <div className="new-brand-modal">
        <Modal forwardedRef={ref} className="modal-window">
          <div className="modal-window__title">{`New ${brandName.replace('brand', '')} brand`}</div>
          <div className="modal-window__subtitle">What is the new brand casino ID?</div>
          <PureInput
            type={PureInputTypesEnum.NUMBER}
            value={casinoId}
            handleChange={handleSetCasinoId}
            placeholder="Casino ID"
            className="modal-window__field"
          />
          <div className="modal-window__actions">
            <SystemCta text="Create" onClick={onSubmit} className="action-btn" isDisabled={casinoId === ''} />
            <SystemCta text="Cancel" onClick={toggleModal} className="action-btn cancel-button" />
          </div>
        </Modal>
      </div>
    </Formik>
  );
};
