import './RadioButtons.scss';
import '../RadioButton/RadioButton.scss';
import { FC } from 'react';
import classNames from 'classnames';
import { RadioButtonsProps } from './RadioButtons';

interface Props {
  buttonClassName?: string;
}

export const RadioButtonsPure: FC<Props & RadioButtonsProps> = ({
  items = [],
  className = '',
  checkedValue,
  onValueChange,
  buttonClassName = '',
}) => {
  return (
    <div className={classNames('radio-buttons-container', className)}>
      {items.map(({ text, value }, index) => (
        <button
          key={index}
          className="radio-button-wrapper"
          type="button"
          onClick={() => {
            onValueChange?.(value as never);
          }}
        >
          <div
            className={classNames({
              [buttonClassName]: buttonClassName,
              'radio-button-text': true,
              'radio-button-text--checked': value === checkedValue,
            })}
          >
            <div className="radio-button-text__content">{text}</div>
          </div>
        </button>
      ))}
    </div>
  );
};
