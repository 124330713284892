import { BackendResponse } from '../app/types';

import { SortDirectionEnum, SortOptionsValuesEnum } from 'common/SortEnum';

export const types = Object.freeze({
  ASSETS_IMAGES_FETCH: '[Assets] ASSETS_IMAGES_FETCH',
  ASSETS_IMAGES_ASSET_POST: '[Assets] ASSETS_IMAGES_ASSET_POST',
  ASSETS_IMAGES_ASSET_PATCH: '[Assets] ASSETS_IMAGES_ASSET_PATCH',
  ASSETS_IMAGES_ASSET_DELETE: '[Assets] ASSETS_IMAGES_ASSET_DELETE',
  ASSETS_LOGOS_FETCH: '[Assets] ASSETS_LOGOS_FETCH',
  ASSETS_LOGOS_PUT: '[Assets] ASSETS_LOGOS_PUT',
  ASSETS_ICONS_FETCH: '[Assets] ASSETS_ICONS_FETCH',
  ASSETS_ICONS_PUT: '[Assets] ASSETS_ICONS_PUT',
  ASSETS_GLOBAL_COMPONENTS_FETCH: '[Assets] ASSETS_GLOBAL_COMPONENTS_FETCH',
  ASSETS_GLOBAL_COMPONENTS_PUT: '[Assets] ASSETS_GLOBAL_COMPONENTS_PUT',
  ASSETS_GLOBAL_COMPONENTS_PATCH: '[Assets] ASSETS_GLOBAL_COMPONENTS_PATCH',
  ASSETS_COLORS_GET: '[Assets] ASSETS_COLORS_GET',
  ASSETS_COLORS_PUT: '[Assets] ASSETS_COLORS_PUT',
  ASSETS_COLORS_THEMES_GET: '[Assets] ASSETS_COLORS_THEMES_GET',
  ASSETS_COLORS_THEMES_PUT: '[Assets] ASSETS_COLORS_THEMES_PUT',
  ASSETS_COLORS_PALETTE_GET: '[Assets] ASSETS_COLORS_PALETTE_GET',
  ASSETS_COLORS_PALETTE_PUT: '[Assets] ASSETS_COLORS_PALETTE_PUT',
});

export type AssetsState = {
  images: BrandAsset[] | null;
  logos: BrandLogosAsset | null;
  icons: BrandAsset[] | null;
  colors: GetBrandColors | null;
  palettes: BrandColorPalette[] | null;
  colorThemes: BrandColorTheme[] | null;
  experienceSettings: Experience[] | null;
  sortImages: {
    orderBy: SortOptionsValuesEnum;
    orderDirection: SortDirectionEnum;
  };
};

export type GetBrandAssetsResponse = BackendResponse<GetBrandAssets>;
export type PostBrandAssetResponse = BackendResponse<PostBrandAsset>;
export type PatchBrandAssetResponse = BackendResponse<PatchBrandAsset>;
export type DeleteBrandAssetResponse = BackendResponse<DeleteBrandAsset>;
export type PutBrandAssetsResponse = BackendResponse<PutBrandAsset>;

export type GetBrandAssetsRequest = {
  companyId?: number;
  siteId?: number;
  tabName?: string;
  systemGroupId?: number;
  orderBy?: SortOptionsValuesEnum;
  orderDirection?: SortDirectionEnum;
};

export type PostBrandAssetsRequest = Partial<BrandAsset> & {
  companyId?: number;
};

export type PatchBrandAssetsRequest = Partial<BrandAsset> & {
  companyId?: number;
};

export type BrandAsset = {
  brandAssetName: string;
  brandAssetDescription?: string;
  brandAssetMode: number;
  siteId?: number;
  systemGroupId?: number;
  brandAssetFileType?: string | null;
  brandAssetTypeId?: number | null;
  brandAssetStateId?: number | null;
  brandAssetScheduleId?: number | null;
  brandAssetFileId: number | null;
  brandAssetId?: number;
  brandAssetFileUrl?: string | null;
  brandAssetFileThumbnailUrl?: string | null;
  brandAssetFileName?: string;
};

export type GetBrandAssets = {
  images: BrandAsset[];
};

export type PostBrandAsset = {
  createdBrandAssetId: number;
};

export type PatchBrandAsset = {
  updatedBrandAssetId: number;
};

export type DeleteBrandAsset = {
  deletedBrandAssetId: number;
};

export type GetLogosAssetsResponse = BackendResponse<GetLogosAssetsFields>;

export type ScheduledLogo = BrandAsset & {
  brandAssetScheduleDescription: string;
  from: string;
  to: string;
};

export type BrandLogosAsset = {
  logos: BrandAsset[];
  scheduledLogos: ScheduledLogo[];
};

export type GetLogosAssetsFields = BrandLogosAsset;

export type PutLogosRequest = {
  assets: Partial<BrandAsset>[];
  deletedAssetsIds: number[];
  assetsSchedules: ScheduledLogo[];
  deletedAssetsSchedulesIds: number[];
};

export type PutBrandAsset = {
  updatedBrandAssets: boolean;
};

export type GetBrandIconsResponse = BackendResponse<GetBrandIcons>;
export type PutBrandIconsResponse = BackendResponse<PutBrandIcons>;

export type GetBrandIcons = {
  experienceSettings: Experience[];
  icons: BrandAsset[];
};

export type GetBrandIconsRequest = {
  siteId: string;
  tabName: string;
};

export type PutIconsRequest = {
  assets: Partial<BrandAsset>[];
  deletedAssetsIds: number[];
};

export type PutBrandIcons = {
  updatedBrandAssets: boolean;
};

type Experience = {
  brandExperienceId: number;
  brandExperienceName: string;
  siteId: number;
  systemGroupId: number;
  translationGroupId: null;
  brandExperienceValue: number;
};

type JSONValue = string | number | boolean | { [x: string]: JSONValue } | Array<JSONValue>;

type BrandsComponentsSettingValue = JSONValue;

export type BrandsComponentsSetting = {
  brandsComponentsSettingName: string;
  brandsComponentsSettingValue: BrandsComponentsSettingValue;
  siteId: number | null;
  systemGroupId: number;
  brandsComponentsSettingId?: number;
};

export type BrandsComponentsSettings = BrandsComponentsSetting[];

export type GetBrandsComponentsSettingsResponse = BackendResponse<BrandsComponentsSettings>;

export type GetBrandsComponentsSettingsRequest = {
  siteId: string;
};

export type PutBrandsComponentsSettings = {
  updatedExperienceItems: boolean;
};
export type PutBrandsComponentsSettingsResponse = BackendResponse<PutBrandsComponentsSettings>;

export type PutBrandsComponentsSettingsRequest = {
  items: BrandsComponentsSettings;
};

export type PatchBrandsComponentsSettings = {
  updatedBrandComponentsSettingsItems: boolean;
};

export type PatchBrandsComponentsSettingsResponse = BackendResponse<PatchBrandsComponentsSettings>;

export type PatchBrandsComponentsSettingsRequest = {
  brandsComponentsSettingId: string;
  brandsComponentsSettingValue: BrandsComponentsSettings[number]['brandsComponentsSettingValue'];
};

export type GetBrandColorsResponse = BackendResponse<GetBrandColors>;
export type GetBrandColorsPaletteResponse = BackendResponse<GetBrandColorsPalette>;
export type PutBrandColorsResponse = BackendResponse<PutBrandColors>;
export type GetBrandColorsThemesResponse = BackendResponse<GetBrandColorsThemes>;
export type PutBrandColorsThemesResponse = BackendResponse<BrandColorsThemesResponse>;
export type PutBrandColorsPaletteResponse = BackendResponse<PutBrandColorsPalette>;

export type GetBrandColors = {
  colors: [];
  buttons: [];
  themeColors: [];
  colorsPalette: [];
  widgets: {
    colors: BrandColor[];
    defaults: WidgetsDefaultColor[];
  };
};

export type WidgetsDefaultColor = {
  brandAssetMode: number;
  brandAssetTypeId: number;
  brandColorDefaultId: number;
  brandColorDefaultValue: number;
  brandColorDefaultValueId: number;
  brandColorName: string;
  brandColorTypeId: number;
  systemGroupId?: number;
  siteId: number;
};

export type BrandColor = {
  brandColorId?: number;
  brandColorName: string;
  systemGroupId?: number;
  siteId: number;
  brandColorTypeId: number;
  brandColorThemeId?: number;
  brandColorValueId?: number;
  brandAssetMode: number;
  brandAssetTypeId: number;
  brandColorValue?: string;
  brandColorValuePaletteId?: number;
};

export type BrandTheme = {
  brandColorThemeId?: number;
  brandColorThemeName: string;
  brandColorThemeDescription?: string;
  isThemeDefault: boolean;
  siteId: number;
  systemGroupId?: number;
  themedColors: PutBrandColorsTheme[];
};

export type PutBrandColorsTheme = {
  brandColorId?: number;
  brandColorName: string;
  systemGroupId?: number;
  siteId: number;
  brandColorTypeId: number;
  brandAssetTypeId?: number;
  brandColorThemeId?: number;
  brandAssetMode: number;
  brandColorValue?: string;
  brandColorValuePaletteId?: number;
};

export type BrandColorTheme = {
  brandColorThemeId: number;
  brandColorThemeName: string;
  brandColorThemeDescription: string;
  isThemeDefault: boolean;
  siteId: number;
  systemGroupId: number;
  brandColorValueId: number;
  brandColorValue: string;
  brandColorPaletteId: number;
  brandColorPaletteValue: string;
  brandColorId: number;
  brandColorName: string;
  brandAssetMode: number;
  brandColorTypeId: number;
  brandAssetTypeId: number;
};

export type PutBrandColorsRequest = {
  colors: BrandColor[];
  deletedColorsIds: number[];
};

export type PutBrandColorsThemes = {
  colorsThemes: BrandTheme[];
  deletedColorsThemesIds: number[];
};

export type PutBrandColors = {
  updatedBrandAssets: boolean;
};

export type BrandColorsThemesResponse = {
  updatedBrandAssets: boolean;
};

export type GetBrandColorsThemes = {
  themeColors: BrandColorTheme[];
};

export type GetBrandColorsPalette = BrandColorPalette[];

export type BrandColorPalette = {
  brandColorPaletteId: number;
  brandColorPaletteName: string;
  brandAssetMode: number;
  siteId: number;
  brandColorPaletteValue: string;
  systemGroupId: number;
};

export type PutBrandColorsPaletteRequest = {
  colorsPalette: BrandColorPalette[];
  deletedColorsPaletteIds: number[];
};

export type PutBrandColorsPalette = {
  updatedBrandAssets: boolean;
};
