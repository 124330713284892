import './CmsUserMenu.scss';
import { Menu } from 'components/shared/Menu/Menu';
import { MenuItemProps } from 'components/shared/MenuItem/MenuItem';
import { FC, MouseEventHandler, useRef } from 'react';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { Initials } from '../Initials/Initials';
import { User } from 'store/app/types';

interface Props {
  user: User | null;
  menuList: MenuItemProps[];
  version: string;
  toggleMenu: MouseEventHandler<HTMLElement>;
}

export const CmsUserMenu: FC<Props> = ({ user, menuList = [], version, toggleMenu }) => {
  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, toggleMenu);

  return (
    <div className="user-menu" ref={wrapperRef}>
      <div className="user-menu-image"></div>
      <div className="user-menu-info">
        <Initials userName={user?.userName || ''} className="user-menu-info__initials" />
        <div className="user-menu-info__user-info">
          <div className="user-menu-info__user-info__user-name">{user?.userName}</div>
          <div className="user-menu-info__user-info__company_info">
            <div className="user-menu-info__user-info__company_info__role-name">{`${user?.roleName},`}</div>
            <div className="user-menu-info__user-info__company_info__company-name">{user?.companyName}</div>
          </div>
        </div>
      </div>

      <Menu menuList={menuList} className="user-menu-items" />

      <div className="user-menu-logo">
        <div className="user-menu-logo__img"></div>
        <div className="user-menu-logo__version">V{version}</div>
      </div>
    </div>
  );
};
