import { NavLink as ReactLink } from 'react-router-dom';
import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
  to: string;
  className?: string;
  activeClassName?: string;
  onClick?: MouseEventHandler;
  matchEnd?: boolean;
  title?: string;
}

export const Link: FC<Props> = ({
  to,
  children,
  onClick,
  matchEnd,
  className = '',
  activeClassName = '',
  title = '',
}: Props): JSX.Element => {
  const activeNavLinkHandler = ({ isActive }: { isActive: boolean }) =>
    classNames({
      [className]: true,
      [activeClassName]: isActive,
    });

  return (
    <ReactLink to={to} className={activeNavLinkHandler} onClick={onClick} end={matchEnd} title={title}>
      {children}
    </ReactLink>
  );
};
