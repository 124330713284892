import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { PermissionsService } from '../../services/PermissionsService';
import { useCurrentPermissionsBrand } from '../hooks/use-current-permissions-brand';

type Props = PropsWithChildren & {
  permissions: string[];
  redirectPath?: string;
};

export const PermissionsGuard: FC<Props> = ({ permissions = [], redirectPath = '/', children }) => {
  const { siteId } = useCurrentPermissionsBrand();
  /* How to check permissions. 3d parameter - siteId (current brand) */
  const { permissionsMap } = useAppSelector((state) => state.app);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  useEffect(() => {
    // Check if permissionsMap is loaded
    if (Object.keys(permissionsMap).length > 0) {
      setPermissionsLoaded(true);
    }
  }, [permissionsMap]);

  if (permissionsLoaded) {
    const isAllow = PermissionsService.checkPermissions(permissionsMap, permissions, siteId);

    if (!isAllow) {
      return <Navigate to={redirectPath} replace />;
    } else {
      return <>{children ?? <Outlet />}</>;
    }
  } else {
    return null;
  }
};
