import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssetsState,
  GetBrandAssets,
  GetBrandColors,
  GetBrandColorsPalette,
  GetBrandColorsThemes,
  GetBrandIcons,
  GetLogosAssetsFields,
} from './types';
import {
  fetchBrandAssets,
  fetchBrandIcons,
  fetchLogosAssets,
  getBrandColors,
  getBrandColorsPalette,
  getBrandColorsThemes,
} from './actions';

import { SortDirectionEnum, SortOptionsValuesEnum } from 'common/SortEnum';

const initialState: AssetsState = {
  images: null,
  logos: null,
  icons: null,
  colors: null,
  palettes: null,
  colorThemes: null,
  experienceSettings: null,
  sortImages: {
    orderBy: SortOptionsValuesEnum.CREATED_AT,
    orderDirection: SortDirectionEnum.DESC,
  },
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    clearBrandAssetsState(state) {
      state.images = null;
    },
    clearBrandAssetsLogosState(state) {
      state.logos = null;
    },
    clearBrandAssetsIconsState(state) {
      state.icons = null;
      state.experienceSettings = null;
    },
    setImagesSort(state, action: PayloadAction<AssetsState['sortImages']>) {
      state.sortImages = action.payload;
    },
    clearBrandAssetsColorsState(state) {
      state.colors = null;
    },
    clearBrandAssetsPalettesState(state) {
      state.palettes = null;
    },
    clearBrandAssetsThemesState(state) {
      state.colorThemes = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandAssets.fulfilled, (state: AssetsState, action: PayloadAction<GetBrandAssets | null>) => {
      if (action.payload !== null) {
        state.images = action.payload.images;
      }
    });

    builder.addCase(
      fetchLogosAssets.fulfilled,
      (state: AssetsState, action: PayloadAction<GetLogosAssetsFields | null>) => {
        if (action.payload !== null) {
          state.logos = action.payload;
        }
      },
    );

    builder.addCase(fetchBrandIcons.fulfilled, (state: AssetsState, action: PayloadAction<GetBrandIcons | null>) => {
      if (action.payload !== null) {
        state.icons = action.payload.icons;
        state.experienceSettings = action.payload.experienceSettings;
      }
    });

    builder.addCase(getBrandColors.fulfilled, (state: AssetsState, action: PayloadAction<GetBrandColors | null>) => {
      if (action.payload !== null) {
        state.colors = action.payload;
      }
    });

    builder.addCase(
      getBrandColorsPalette.fulfilled,
      (state: AssetsState, action: PayloadAction<GetBrandColorsPalette | null>) => {
        if (action.payload !== null) {
          state.palettes = action.payload;
        }
      },
    );

    builder.addCase(
      getBrandColorsThemes.fulfilled,
      (state: AssetsState, action: PayloadAction<GetBrandColorsThemes | null>) => {
        if (action.payload !== null) {
          state.colorThemes = action.payload.themeColors;
        }
      },
    );
  },
});
