import { FC, useContext } from 'react';
import { PageTab } from './types';
import { TabsValidationContext } from 'contexts/Validation/tabs-validation.context';
import { TopNavigationBar } from 'components/layouts/TopNavigationBar/TopNavigationBar';
import { PageContentContainer } from 'components/pages/PageContentContainer/PageContentContainer';
import { RightNavigationBar } from 'components/layouts/RightNavigationBar/RightNavigationBar';
import { useTabbedPages } from 'hooks/tabs/use-tabbed-pages';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars-2';
import { useAppSelector } from 'store/hooks';
import { BrandsList } from '../../modules/Brands/BrandsList/BrandsList';
import { NoBrandComponent } from '../NoBrandComponent/NoBrandComponent';
import { useParams } from 'react-router-dom';

interface Props {
  tabsList: PageTab[];
  baseRoute: string;
  defaultTab: string;
  submitButtonText?: string;
  topNavigationClassName?: string;
  moduleName?: string;
  onCLick?: (siteId: number) => void;
  submitButtonWithBorder?: boolean;
}

export const TabbedPage: FC<Props> = ({
  tabsList,
  baseRoute,
  defaultTab,
  submitButtonText = 'Update',
  topNavigationClassName = '',
  moduleName = '',
  onCLick,
  submitButtonWithBorder,
}) => {
  const { isMinimizeMode, isBrandsSettingsList } = useAppSelector((state) => state.app);
  const { brands } = useAppSelector((state) => state.brands);
  const tabValidator = useContext(TabsValidationContext);
  const { currentTab, currentSubTab, tabHasSubTabs, currentSubTabsMenu } = useTabbedPages({
    tabsList,
    baseRoute,
    defaultTab,
  });
  const { siteId } = useParams();

  if (!tabValidator) {
    return null;
  }

  const isBrandModule = moduleName === 'brand-module';

  const pageClassName = classNames(
    'page-content',
    isMinimizeMode && 'page-content--minimize',
    tabHasSubTabs && 'page-content--with-sub-tabs',
    `page-content--${moduleName}`,
  );

  const scrollbarClassName = classNames({
    'tabbed-page': true,
    scrollbar: true,
    [`scrollbar--${moduleName}`]: true,
    'scrollbar--minimize': isMinimizeMode,
    'scrollbar--minimize-with-sub-tabs': isMinimizeMode && tabHasSubTabs,
    'scrollbar--with-sub-tabs': tabHasSubTabs,
  });

  return (
    <>
      <TopNavigationBar
        className={classNames('top-navigation-bar', topNavigationClassName)}
        topTabsList={tabsList}
        submitButtonText={submitButtonText}
        handleUpdate={tabValidator.handleSubmit}
        submitButtonIsDisabled={tabValidator.submitButtonIsDisabled}
        submitButtonIsLoading={tabValidator.submitButtonIsLoading}
        submitButtonIsSucceed={tabValidator.submitButtonIsSucceed}
        onBeforeTabChange={tabValidator.onBeforeTabChange}
        isMinimizeMode={isMinimizeMode}
        isBrandModule={isBrandModule}
        submitButtonWithBorder={submitButtonWithBorder}
      >
        {tabValidator?.actions}
      </TopNavigationBar>
      {isBrandModule && brands && isBrandsSettingsList && (
        <BrandsList
          setActiveBrands={() => {}}
          brandsList={brands}
          isBrandsList={true}
          onClick={onCLick}
          canPin={false}
          currentSiteId={Number(siteId)}
          listClass="per-brand-list"
        />
      )}
      {currentTab && (
        <PageContentContainer>
          <Scrollbars
            className={scrollbarClassName}
            autoHide
            hideTracksWhenNotNeeded
            autoHideDuration={500}
            renderTrackVertical={({ style, ...props }) => (
              <div {...props} className="scrollbar-vertical-track" style={{ ...style }}></div>
            )}
            renderTrackHorizontal={({ style, ...props }) => (
              <div {...props} className="scrollbar-horizontal-track" style={{ ...style }}></div>
            )}
          >
            {currentTab && currentSubTab && <div className={pageClassName}>{currentSubTab.component}</div>}
            {currentTab && !currentSubTab && 'component' in currentTab && (
              <div className={pageClassName}>{currentTab.component}</div>
            )}
          </Scrollbars>
          {tabHasSubTabs && (
            <RightNavigationBar subTabsList={currentSubTabsMenu} onBeforeTabChange={tabValidator.onBeforeTabChange} />
          )}
        </PageContentContainer>
      )}
      {isBrandModule && <NoBrandComponent />}
    </>
  );
};
