import './BrandsList.scss';
import React, { FC, SyntheticEvent } from 'react';
import { BrandItem } from '../BrandItem/BrandItem';
import { AddToListBtn } from 'components/shared/AddToListBtn/AddToListBtn';
import { BrandListed } from './types';
import { Menu } from 'components/shared/Menu/Menu';
import { PureInput } from 'components/shared/PureInput/PureInput';
import { PureInputTypesEnum } from 'components/shared/PureInput/types';
import { NewBrandModal } from '../NewBrandModal/NewBrandModal';
import { NewModularBrandModal } from '../NewModularBrandModal/NewModularBrandModal';
import { atomBrands } from 'common/dummyData';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars-2';
import { useBrandListProps } from '../hooks/use-brand-list-props';

interface Props {
  activeBrands?: number[];
  disabledBrands?: number[];
  setActiveBrands: (e: SyntheticEvent<HTMLDivElement>) => void;
  brandsList: BrandListed[];
  canAddNewBrand?: boolean;
  canPin?: boolean;
  pinAction?: (id: number) => void;
  currentSiteId?: number | null;
  listClass?: string;
  listWrapperClass?: string;
  listContentClass?: string;
  isHovered?: boolean;
  isBrandsList?: boolean;
  onClick?: (siteId: number) => void;
}

export const BrandsList: FC<Props> = ({
  disabledBrands = [],
  setActiveBrands,
  brandsList = [],
  canAddNewBrand = true,
  listClass = '',
  canPin = false,
  pinAction,
  currentSiteId,
  listWrapperClass = 'brands-list__wrapper',
  listContentClass = 'brands-list-content',
  isHovered,
  isBrandsList,
  onClick,
}) => {
  const {
    searchBrand,
    filteredBrands,
    isMenuOpen,
    isNewBrandModalOpen,
    brandTypesMenu,
    toggleMenu,
    isBrandPinned,
    selectedBrandName,
    selectedBrandType,
    isNewModularBrandModalOpen,
    toggleNewModularBrandModal,
    handleSubmitNewBrand,
    toggleNewBrandModal,
    handleSetSearch,
  } = useBrandListProps(brandsList, canPin);

  const listClassName = classNames({
    'brands-list': true,
    'brands-list--active': isBrandsList,
    [listClass]: listClass,
  });

  return (
    <div className={listClassName}>
      <div className={classNames('brands-list__wrapper', listWrapperClass)}>
        <div className="brands-list-searchbox">
          {isBrandsList && (
            <PureInput
              value={searchBrand}
              type={PureInputTypesEnum.SEARCH}
              className="brands-list-searchbox__search"
              placeholder="Brand"
              handleChange={handleSetSearch}
            />
          )}
        </div>

        <Scrollbars
          className="brand-list-scrollbar"
          thumbSize={24}
          autoHide
          hideTracksWhenNotNeeded
          autoHideDuration={500}
          renderTrackVertical={({ style, ...props }) => (
            <div {...props} className="scrollbar-track" style={{ ...style }}></div>
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div {...props} className="scrollbar-track__thumb" style={{ ...style }}></div>
          )}
        >
          <div className={listContentClass}>
            {filteredBrands.map(
              ({ siteId, brandName, brandType, brandTemplate, companyName, assets }, index: number) => (
                <BrandItem
                  key={index}
                  siteId={siteId}
                  brandName={brandName}
                  brandType={brandType}
                  brandTemplate={brandTemplate}
                  companyName={companyName}
                  isSelected={currentSiteId === siteId}
                  isDisabled={disabledBrands?.includes(siteId)}
                  setIsSelected={setActiveBrands}
                  isHovered={isHovered}
                  canPin={canPin}
                  asset={assets?.favicon?.big?.brandAssetFileUrl}
                  isPinned={isBrandPinned(siteId)}
                  onClick={onClick}
                  pinAction={pinAction}
                />
              ),
            )}
          </div>
        </Scrollbars>

        {canAddNewBrand && !searchBrand && isBrandsList && (
          <div className="new-brand-btn">
            <AddToListBtn text="New brand" onClick={toggleMenu} />

            {isMenuOpen && <Menu menuList={brandTypesMenu} className="new-brand-btn__menu" toggleMenu={toggleMenu} />}

            {isNewBrandModalOpen && (
              <NewBrandModal
                toggleModal={toggleNewBrandModal}
                brandName={selectedBrandName}
                brandTemplate={selectedBrandType}
                submitNewBrand={handleSubmitNewBrand}
              />
            )}

            {isNewModularBrandModalOpen && (
              <NewModularBrandModal atomBrands={atomBrands} toggleModal={toggleNewModularBrandModal} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
