import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config/endpoints';
import {
  GetBrandWidgetFields,
  GetBrandWidgetRequest,
  GetBrandWidgetResponse,
  PutBrandWidgetFields,
  PutBrandWidgetRequest,
  PutBrandWidgetResponse,
  types,
} from './types';
import { AsyncThunkParams } from '../app/types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchBrandWidget = createAsyncThunk<
  GetBrandWidgetFields | null,
  AsyncThunkParams<GetBrandWidgetResponse, GetBrandWidgetRequest>
>(types.WIDGET_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams('');

      params.append('widgetId', payload.widgetId.toString());

      const result = await execute(
        'GET',
        `${endpoints.widgetsGetBrandWidget.replace(':siteId', payload.siteId?.toString())}?${params}`,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      console.error('fetchBrandWidget() - payload is not defined');
      return null;
    }
  } catch (err) {
    console.error(types.WIDGET_FETCH, err);
    return null;
  }
});

export const putBrandWidget = createAsyncThunk<
  PutBrandWidgetFields | null,
  AsyncThunkParams<PutBrandWidgetResponse, PutBrandWidgetRequest>
>(types.WIDGET_PUT, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams('');

      params.append('widgetId', payload.widgetId.toString());

      const result = await execute(
        'PUT',
        `${endpoints.widgetsPutBrandWidget.replace(':siteId', payload.siteId?.toString())}?${params}`,
        payload,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      console.error('putBrandWidget() - payload is not defined');
      return null;
    }
  } catch (err) {
    console.error(types.WIDGET_PUT, err);
    throw err;
  }
});
