import { useAppActions, useAppDispatch } from 'store/hooks';
import { useFetchWithMsal } from 'hooks/use-fetch-with-msal';
import { useCallback } from 'react';

export const useCmsCountries = () => {
  const dispatch = useAppDispatch();
  const { fetchCountries } = useAppActions();
  const { execute } = useFetchWithMsal();

  const doGetCountriesRequest = () => {
    dispatch(
      fetchCountries({
        execute,
      }),
    );
  };

  return {
    doGetCountriesRequest: useCallback(doGetCountriesRequest, [execute]),
  };
};
