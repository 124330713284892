type StorageType = 'localStorage' | 'sessionStorage';

export interface PersistConfig {
  key: string;
  storageType: StorageType;
  whitelist?: string[];
  blacklist?: string[];
  fields?: { [key: string]: string[] };
}

const getStorage = (storageType: StorageType) => {
  return storageType === 'localStorage' ? localStorage : sessionStorage;
};

export function loadState(config: PersistConfig) {
  try {
    const storage = getStorage(config.storageType);
    const serializedState = storage.getItem(config.key);
    if (!serializedState) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (e) {
    console.error('Could not load state:', e);
    return undefined;
  }
}

export function saveState(state: Record<string, unknown>, config: PersistConfig) {
  try {
    const storage = getStorage(config.storageType);

    let stateToSave = { ...state };

    if (config.whitelist) {
      stateToSave = config.whitelist.reduce(
        (filteredState, key) => {
          filteredState[key] = state[key];
          return filteredState;
        },
        {} as Record<string, unknown>,
      );
    }

    if (config.blacklist) {
      stateToSave = Object.keys(state).reduce(
        (filteredState, key) => {
          if (!config.blacklist?.includes(key)) {
            filteredState[key] = state[key];
          }
          return filteredState;
        },
        {} as Record<string, unknown>,
      );
    }

    stateToSave = Object.keys(stateToSave).reduce(
      (filteredState, key) => {
        if (!config.fields) {
          return filteredState;
        }

        if (key in config.fields && config.fields[key].length > 0) {
          filteredState[key] = config.fields[key].reduce(
            (partialState, field) => {
              partialState[field] = (stateToSave[key] as Record<string, unknown>)[field];
              return partialState;
            },
            {} as Record<string, unknown>,
          );
        }
        return filteredState;
      },
      { ...stateToSave } as Record<string, unknown>,
    );

    const serializedState = JSON.stringify(stateToSave);
    storage.setItem(config.key, serializedState);
  } catch (e) {
    console.error('Could not save state:', e);
  }
}
