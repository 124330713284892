export enum CoreButtonTypes {
  NEW_COMPANY = 'NEW_COMPANY',
  ADD_BRAND = 'ADD_BRAND',
}

export enum CoreButtonStyles {
  NEW_COMPANY = 'new-company',
  ADD_BRAND = 'add-brand',
}

export enum IconDirectionEnum {
  BEFORE = 'before',
  AFTER = 'after',
}
