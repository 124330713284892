import './SystemCta.scss';
import { Mark } from 'components/shared/Mark/Mark';
import classNames from 'classnames';
import { FC, Fragment, MouseEventHandler } from 'react';
import { DotsSpinner } from 'components/shared/DotsSpinner/DotsSpinner';

export interface Props {
  text: string;
  isLoading?: boolean;
  isSucceed?: boolean;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  // Be careful with submit type, could cause double submitting with Formik handlers
  buttonType?: 'submit' | 'reset' | 'button';
  withBorder?: boolean;
}

export const SystemCta: FC<Props> = ({
  text,
  isLoading,
  isSucceed,
  isDisabled,
  onClick,
  className = '',
  buttonType = 'button',
  withBorder,
}) => {
  const isButtonDisabled = isSucceed || isDisabled;

  const buttonClassName = classNames({
    'system-cta-container': true,
    'system-cta-container--loading': isLoading,
    'system-cta-container__disabled': isButtonDisabled && !isLoading,
    'system-cta-container__disabled--with-border': isButtonDisabled && !isLoading && withBorder,
    [className]: className,
  });

  const buttonContent = [
    !isLoading && !isSucceed && <Fragment key={'text'}>{text}</Fragment>,
    isLoading && <DotsSpinner key={'spinner'} />,
    isSucceed && <Mark key={'success'} />,
  ].filter(Boolean);

  return (
    <button type={buttonType} className={buttonClassName} onClick={onClick} disabled={isButtonDisabled}>
      {buttonContent}
    </button>
  );
};
