import { ChangeEvent, FC, useState } from 'react';
import './BrandColorPicker.scss';
import { ContentColorPickers } from 'components/modules/Assets/ContentColorPickers/ContentColorPickers';
import { SizeEnums } from 'common/SizeEnums';
import { IconEnums } from 'common/IconEnums';
import { useToggle } from 'hooks/use-toggle';
import { radioItems } from './types';
import { AssetsModal } from 'components/modules/Assets/Modals/AssetsModal/AssetsModal';
import classNames from 'classnames';
import { useAppSelector } from 'store/hooks';
import { FormikValues, useFormikContext } from 'formik';
import { Icon } from 'components/shared/Icon/Icon';
import { ThemeRowPickers } from '../Colors/ThemeRow/types';

interface Props {
  type: ThemeRowPickers;
  text: string;
  name: string;
  defaultColor?: string;
  darkColor?: string;
  isMinimize?: boolean;
}

enum DefaultColors {
  TRANSPARENT = '#FFFFFF00',
}

export const BrandColorPicker: FC<Props> = ({
  text,
  type,
  name,
  defaultColor = 'var(--placeholder)',
  darkColor = 'var(--placeholder)',
  isMinimize = false,
}) => {
  const [activeTab, setActiveTab] = useState<string | undefined>(radioItems?.defaultValue);
  const [isModalOpen, toggleModal] = useToggle(false);
  const { isDarkMode } = useAppSelector((state) => state.app);
  const { setFieldValue } = useFormikContext<FormikValues>();

  const handleToggleModal = (value: string) => {
    setFieldValue(name, value);
    toggleModal();
  };

  const handleChangeTab = (e: ChangeEvent<HTMLInputElement>): void => setActiveTab(e.target.value);

  const iconBackgroundColor = isDarkMode
    ? `linear-gradient(90deg, ${defaultColor} 50%, ${darkColor} 50%)`
    : defaultColor;

  const paletteClassName = classNames(
    'brand-color-picker__palette',
    isMinimize && 'brand-color-picker__palette--minimize',
  );

  const contentPickersIconTypes = {
    [ThemeRowPickers.BACKGROUND]: IconEnums.PICKER_BACKGROUND,
    [ThemeRowPickers.BORDER]: IconEnums.PICKER_STROKE,
    [ThemeRowPickers.BOARDERS]: IconEnums.PICKER_STROKE,
    [ThemeRowPickers.TITLE_TEXT]: IconEnums.PICKER_TEXT,
    [ThemeRowPickers.BODY_TEXT]: IconEnums.PICKER_TEXT,
    [ThemeRowPickers.ICONS]: IconEnums.PICKER_ICON,
    [ThemeRowPickers.SEPARATORS]: IconEnums.PICKER_STROKE,
    [ThemeRowPickers.SHADOWS]: IconEnums.PICKER_STROKE,
  };

  const isTransparentColor = defaultColor === DefaultColors.TRANSPARENT.toLowerCase();

  const transparentItemClassName = classNames({
    'brand-color-picker--transparent': true,
    'brand-color-picker--transparent--minimize': isMinimize,
  });

  return (
    <div className="brand-color-picker">
      <div className={paletteClassName} role="presentation" onClick={toggleModal}>
        {!isMinimize && text}
        {!isTransparentColor ? (
          <Icon iconName={contentPickersIconTypes[type]} iconSize={SizeEnums.SMALL} bgColor={iconBackgroundColor} />
        ) : (
          <div
            className={transparentItemClassName}
            style={{ background: `var(--transparent-${contentPickersIconTypes[type]}) no-repeat` }}
          ></div>
        )}
      </div>

      {isModalOpen && (
        <AssetsModal
          className="brand-color-picker__modal"
          toggleModal={toggleModal}
          radioItems={radioItems}
          activeTab={activeTab}
          handleChangeTab={handleChangeTab}
        >
          <ContentColorPickers icon={contentPickersIconTypes[type]} type={type} setColors={handleToggleModal} />
        </AssetsModal>
      )}
    </div>
  );
};
