import { FC, ReactNode } from 'react';

import './PageContentContainer.scss';

interface Props {
  children: ReactNode;
}

export const PageContentContainer: FC<Props> = ({ children }) => (
  <div className="pages-content-container">{children}</div>
);
