import { useAppActions, useAppDispatch } from 'store/hooks';
import { useFetchWithMsal } from 'hooks/use-fetch-with-msal';
import { useCallback } from 'react';

export const useCmsLanguages = () => {
  const dispatch = useAppDispatch();
  const { fetchLanguages } = useAppActions();
  const { execute } = useFetchWithMsal();

  const doGetLanguagesRequest = () => {
    dispatch(
      fetchLanguages({
        execute,
      }),
    );
  };

  return {
    doGetLanguagesRequest: useCallback(doGetLanguagesRequest, [execute]),
  };
};
