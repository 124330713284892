import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config/endpoints';
import {
  Brand,
  BrandSettings,
  BrandSettingsGeneral,
  DeleteBrand,
  DeleteBrandResponse,
  GetBrands,
  GetBrandSettingsResponse,
  GetBrandsResponse,
  PatchBrand,
  PatchBrandResponse,
  PatchUpdateBrandRequest,
  PostNewBrandResponse,
  PutBrandGeneralsResponse,
  PutBrandSettingDetailsRequest,
  PutBrandSettingIntegrationsResponse,
  PutBrandSettingsAuthorizationRequest,
  PutBrandSettingsAuthorizationResponse,
  PutBrandSettingsDetailsResponse,
  PutBrandSettingsIntegrationsRequest,
  types,
} from './types';
import { AsyncThunkParams } from '../app/types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchBrands = createAsyncThunk<
  GetBrands | null,
  AsyncThunkParams<GetBrandsResponse, { companyId: number | null }>
>(types.BRANDS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams({
        companyId: payload.companyId ? payload.companyId.toString() : '',
      });
      const url = `${endpoints.brands}?${params.toString()}`;

      const result = await execute('GET', url);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.BRANDS_FETCH, err);
    return null;
  }
});

export const fetchBrandsFull = createAsyncThunk<
  GetBrands | null,
  AsyncThunkParams<GetBrandsResponse, { companyId: number | null }>
>(types.BRANDS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams({
        companyId: payload.companyId ? payload.companyId.toString() : '',
      });
      const url = `${endpoints.brandsFull}?${params.toString()}`;

      const result = await execute('GET', url);

      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.BRANDS_FETCH, err);
    return null;
  }
});

export const patchBrand = createAsyncThunk<
  PatchBrand | null,
  AsyncThunkParams<PatchBrandResponse, Partial<PatchUpdateBrandRequest>>
>(types.BRAND_PATCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('PATCH', endpoints.brand.replace(':siteId', payload.siteId?.toString() as string), {
        companyId: payload.companyId,
      });
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.BRAND_PATCH, err);
    throw err;
  }
});

export const deleteBrand = createAsyncThunk<
  DeleteBrand | null,
  AsyncThunkParams<DeleteBrandResponse, { siteId: number }>
>(types.BRAND_DELETE, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('DELETE', endpoints.brand.replace(':siteId', payload.siteId?.toString() as string));
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.BRAND_DELETE, err);
    throw err;
  }
});

export const postNewBrand = createAsyncThunk<Brand | null, AsyncThunkParams<PostNewBrandResponse, Partial<Brand>>>(
  types.NEW_BRAND_POST,
  async ({ execute, payload }) => {
    try {
      const result = await execute('POST', endpoints.brandsPostBrand, payload);
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.NEW_BRAND_POST, err);
      return null;
    }
  },
);

export const fetchBrandSettings = createAsyncThunk<
  BrandSettings | null,
  AsyncThunkParams<GetBrandSettingsResponse, { siteId: number }>
>(types.BRANDS_SETTINGS_FETCH, async ({ execute, payload }) => {
  if (!payload) {
    console.error('fetchBrandSettings() - payload is not defined');
    return null;
  }

  try {
    const result = await execute('GET', endpoints.brandsBrandSettings.replace(':siteId', payload.siteId.toString()));

    return BackendResponseService.getBackendResponseData(result);
  } catch (err) {
    console.error(types.BRANDS_SETTINGS_FETCH, err);
    return null;
  }
});

export const putBrandSettingsDetails = createAsyncThunk<
  void | null,
  AsyncThunkParams<PutBrandSettingsDetailsResponse, PutBrandSettingDetailsRequest>
>(types.BRANDS_SETTINGS_DETAILS_PUT, async (props) => {
  try {
    const result = await props.execute('PUT', endpoints.brandsBrandSettingsDetails, props.payload);
    return BackendResponseService.getBackendResponseData(result);
  } catch (err) {
    console.error(types.BRANDS_SETTINGS_DETAILS_PUT, err);
    return null;
  }
});

export const putBrandSettingsGenerals = createAsyncThunk<
  void | null,
  AsyncThunkParams<PutBrandGeneralsResponse, BrandSettingsGeneral & Pick<Brand, 'siteId'>>
>(types.BRAND_PUT_GENERALS, async ({ execute, payload }) => {
  try {
    const result = await execute('PUT', endpoints.brandsPutBrandGenerals, payload);
    return BackendResponseService.getBackendResponseData(result);
  } catch (err) {
    console.error(types.BRAND_PUT_GENERALS, err);
    throw err;
  }
});

export const putBrandSettingsAuthorization = createAsyncThunk<
  void | null,
  AsyncThunkParams<PutBrandSettingsAuthorizationResponse, PutBrandSettingsAuthorizationRequest>
>(types.BRANDS_SETTINGS_AUTHORIZATION_PUT, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('PUT', endpoints.brandsBrandSettingsPutAuthorization, payload);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.BRANDS_SETTINGS_AUTHORIZATION_PUT, err);
    throw err;
  }
});

export const putBrandSettingsIntegrations = createAsyncThunk<
  void | null,
  AsyncThunkParams<PutBrandSettingIntegrationsResponse, PutBrandSettingsIntegrationsRequest>
>(types.BRANDS_SETTINGS_INTEGRATIONS_PUT, async (props) => {
  try {
    const result = await props.execute('PUT', endpoints.brandsBrandSettingsPutIntegrations, props.payload);
    return BackendResponseService.getBackendResponseData(result);
  } catch (err) {
    console.error(types.BRANDS_SETTINGS_INTEGRATIONS_PUT, err);
    return null;
  }
});
