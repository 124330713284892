import { SyntheticEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useTabsLink = (
  url: string,
  onBeforeTabChange: () => Promise<boolean>,
  index: number,
  setActiveTabIndex?: (index: number) => void,
) => {
  const navigate = useNavigate();

  const handleTabClick = useCallback(
    async (e: SyntheticEvent) => {
      // prevent default link behavior
      e.preventDefault();

      // check whether tab can be changed
      const canChangeTab = await onBeforeTabChange();

      // if tab cannot be changed, do nothing
      if (!canChangeTab) {
        return;
      }

      // otherwise, navigate to the tab and mark it as active
      navigate(url);

      if (setActiveTabIndex) {
        setActiveTabIndex(index);
      }
    },
    [onBeforeTabChange, navigate, setActiveTabIndex],
  );

  return {
    handleTabClick,
  };
};
