import { useAppActions, useAppDispatch, useAppSelector } from 'store/hooks';
import { useCallback, useEffect } from 'react';
import { useFetchWithMsal } from 'hooks/use-fetch-with-msal';
import { PutUpdateSession } from 'store/app/types';
import { useCurrentModulePath } from 'hooks/use-current-module-path';
import { useNavigate } from 'react-router-dom';

export const useBrandListActions = () => {
  const dispatch = useAppDispatch();
  const { execute } = useFetchWithMsal();
  const { isBrandsList, isBrandsSettingsList, session, siteId: currentSiteId } = useAppSelector((state) => state.app);
  const { brands } = useAppSelector((state) => state.brands);
  const { handleBrandsList, handleHoveredMode, handleSetSiteId, clearSiteId, updateUserSession, getUserSession } =
    useAppActions();

  const { isBrandLevelModule } = useCurrentModulePath();
  const navigate = useNavigate();

  const currentSession = session as PutUpdateSession;

  // select first brand for customer user if there is only one brand
  useEffect(() => {
    if (brands && brands.length === 1) {
      setCurrentSiteId(brands[0].siteId);
    }
  }, [brands]);

  const isPinBrandInList = (id: number) => {
    const { pinnedBrands } = currentSession.userSessionValue;

    if (!pinnedBrands) {
      return [id];
    }

    if (pinnedBrands?.includes(id)) {
      return pinnedBrands.filter((brandId) => brandId !== id);
    } else {
      return [...pinnedBrands, id];
    }
  };

  const pinBrand = async (id: number) => {
    if (session) {
      await dispatch(
        updateUserSession({
          execute,
          payload: {
            userSessionId: currentSession.userSessionId,
            userSessionValue: {
              ...currentSession.userSessionValue,
              pinnedBrands: isPinBrandInList(id),
              currentSiteId,
            },
          },
        }),
      );
    }
    await dispatch(getUserSession({ execute }));
  };

  const handleOpenBrandsList = useCallback(() => {
    dispatch(handleBrandsList(!isBrandsList));
    if (!isBrandsSettingsList) {
      dispatch(handleHoveredMode(true));
    }
  }, [isBrandsList, isBrandsSettingsList]);

  const setCurrentSiteId = useCallback(
    (siteId: number) => {
      dispatch(handleSetSiteId(siteId));
      dispatch(handleBrandsList(false));
      dispatch(handleHoveredMode(true));

      // if current url is brand level - update it
      if (isBrandLevelModule) {
        navigate(window.location.pathname.replace(/\/\d+/, `/${siteId}`));
      }
    },
    [isBrandLevelModule, navigate],
  );

  const handleClearSiteId = useCallback(() => dispatch(clearSiteId()), []);

  return {
    setCurrentSiteId,
    handleOpenBrandsList,
    handleClearSiteId,
    pinBrand,
  };
};
