import { useEffect, MutableRefObject } from 'react';
import { throttle } from 'throttle-debounce';

export function useScrollPosition<T extends HTMLElement>(
  scrollableRef: MutableRefObject<T | null>,
  scrollPositionChange: (positionKey: string) => void,
  dependencies: [],
) {
  const updateScrollPosition = () => {
    if (scrollableRef?.current) {
      const offsetWidth = scrollableRef.current.offsetWidth;
      const scrollLeft = scrollableRef.current.scrollLeft;
      const scrollWidth = scrollableRef.current.scrollWidth;

      // hide arrow on initial scroll position
      if (scrollLeft === 0) {
        scrollPositionChange('start');
      }

      // show left arrow if scrolled to right
      if (scrollLeft > 0) {
        scrollPositionChange('middle');
      }

      // show right arrow if scroll present
      if (scrollWidth > offsetWidth) {
        scrollPositionChange('scrollable');
      }

      // if scrolled till the end - hide right arrow
      if (scrollWidth - (offsetWidth + scrollLeft) <= 0) {
        scrollPositionChange('end');
      }
    }
  };

  // arrows functionality
  useEffect(() => {
    if (scrollableRef && scrollableRef.current) {
      // initial run
      const calculate = () => {
        updateScrollPosition();
      };

      const calculateThrottled = throttle(250, calculate);

      // calculate on scroll
      scrollableRef?.current?.addEventListener('scroll', calculateThrottled);
      window.addEventListener('resize', calculateThrottled);

      return () => {
        scrollableRef?.current?.removeEventListener('scroll', calculateThrottled);
        window.removeEventListener('resize', calculateThrottled);
      };
    }
  }, dependencies);
}
