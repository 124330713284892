import './CircleSpinner.scss';
import { FC } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const CircleSpinner: FC<Props> = ({ className }) => {
  return <div className={classNames('circle-spinner', className)}></div>;
};
