import { FC, PropsWithChildren } from 'react';

import './SystemBlock.scss';

import { SystemBlockHeaderProps } from './types';
import { SystemBlockHeader } from 'components/shared/SystemBlockHeader/SystemBlockHeader';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
  headerProps?: SystemBlockHeaderProps;
  color?: 'gray';
}

export const SystemBlock: FC<Props> = ({ headerProps, color, children }) => {
  const systemBlockClassNames = classNames({
    'system-block-container': true,
    [`system-block-container__color--${color}`]: color,
  });

  return (
    <div className={systemBlockClassNames}>
      {headerProps && <SystemBlockHeader headerProps={headerProps} />}
      {children}
    </div>
  );
};
