import { lazy } from 'react';
import { RouteObject } from 'react-router';

import { getRoutesPath } from 'router/uri';
import App from 'components/app/App';
import { PermissionsGuard } from '../auth/guards/PermissionsGuard';
import { PermissionsEnum } from '../auth/enums/permissions.enum';
import { MenusGuard } from '../auth/guards/MenusGuard';
import { MenusGuardsEnum } from '../auth/enums/menus-guards.enum';
import { ColorPickersPage } from '../components/pages/Tests/ColorPickersPage/ColorPickersPage';

const Home = lazy(() => import('components/pages/Home' /* webpackPrefetch: true */));
const CmsUsers = lazy(() => import('components/pages/CmsUsers/CmsUsers' /* webpackPrefetch: true */));
const NetworkMediaPage = lazy(
  () => import('components/pages/NetworkMediaPage/NetworkMediaPage' /* webpackPrefetch: true */),
);
const NetworkEditorPage = lazy(
  () => import('components/pages/NetworkEditorPage/NetworkEditorPage' /* webpackPrefetch: true */),
);
const BrandSettings = lazy(() => import('components/pages/BrandSettings/BrandSettings' /* webpackPrefetch: true */));
const AssetsPage = lazy(() => import('components/pages/Assets/Assets' /* webpackPrefetch: true */));
const WidgetsPage = lazy(() => import('components/pages/WidgetsPage/WidgetsPage' /* webpackPrefetch: true */));
const WidgetDetailsPage = lazy(
  () => import('components/pages/WidgetDetailsPage/WidgetDetailsPage' /* webpackPrefetch: true */),
);
const Experience = lazy(() => import('components/pages/Experience/Experience' /* webpackPrefetch: true */));
const Regulations = lazy(() => import('components/pages/RegulationsPage/RegulationsPage' /* webpackPrefetch: true */));
const Menus = lazy(() => import('components/pages/Menus/Menus' /* webpackPrefetch: true */));
const CasinoGames = lazy(() => import('components/pages/CasinoGames/CasinoGames' /* webpackPrefetch: true */));
const CasinoGamesDetails = lazy(
  () => import('components/pages/CasinoGamesDetails/CasinoGamesDetails' /* webpackPrefetch: true */),
);
const CasinoGamesCategoriesDetails = lazy(
  () =>
    import('components/pages/CasinoGamesCategoriesDetails/CasinoGamesCategoriesDetails' /* webpackPrefetch: true */),
);
const NotFound = lazy(() => import('components/pages/NotFound'));
const ToolsIndex = lazy(() => import('components/pages/Tools/ToolsIndex/ToolsIndex'));

export const getRoutesConfig = (): RouteObject[] => {
  const routesPath = getRoutesPath();

  return [
    {
      element: <App />,
      children: [
        {
          path: routesPath.home,
          element: <Home />,
        },
        {
          path: routesPath.users,
          element: (
            <PermissionsGuard permissions={[PermissionsEnum.CMS_USERS_ENTRY]}>
              <CmsUsers />
            </PermissionsGuard>
          ),
          children: [
            {
              path: routesPath.usersTabulatorTab,
              element: <CmsUsers />,
              children: [
                {
                  path: routesPath.usersTabulatorSubTab,
                  element: <CmsUsers />,
                },
              ],
            },
          ],
        },
        {
          path: routesPath.networkEditor,
          element: (
            <PermissionsGuard permissions={[PermissionsEnum.NETWORK_EDITOR_ENTRY]}>
              <NetworkEditorPage />
            </PermissionsGuard>
          ),
          children: [
            {
              path: routesPath.networkEditorTabulatorTab,
              element: <NetworkEditorPage />,
              children: [
                {
                  path: routesPath.networkEditorTabulatorSubTab,
                  element: <NetworkEditorPage />,
                },
              ],
            },
          ],
        },
        {
          path: routesPath.brandSettings,
          element: (
            <PermissionsGuard permissions={[PermissionsEnum.BRANDS_SETTINGS_ENTRY]}>
              <BrandSettings />
            </PermissionsGuard>
          ),
          children: [
            {
              path: routesPath.brandSettingsTabulator,
              element: <BrandSettings />,
            },
            {
              path: routesPath.brandSettingsTabulatorTab,
              element: <BrandSettings />,
            },
          ],
        },
        {
          path: routesPath.widgets,
          element: <WidgetsPage />,
          children: [
            {
              path: routesPath.widgetEditor,
              element: <WidgetDetailsPage />,
            },
            {
              path: routesPath.widgetsTabulator,
              element: <WidgetsPage />,
            },
          ],
        },
        {
          path: routesPath.assets,
          element: <AssetsPage />,
          children: [
            {
              path: routesPath.assetsTabulator,
              element: <AssetsPage />,
            },
            {
              path: routesPath.assetsTabulatorTab,
              element: <AssetsPage />,
            },
            {
              path: routesPath.assetsTabulatorSubTab,
              element: <AssetsPage />,
            },
          ],
        },
        {
          path: routesPath.experience,
          element: <Experience />,
          children: [
            {
              path: routesPath.experienceTabulator,
              element: <Experience />,
            },
            {
              path: routesPath.experienceTabulatorTab,
              element: <Experience />,
            },
          ],
        },
        {
          path: routesPath.regulations,
          element: (
            <MenusGuard menuGuardName={MenusGuardsEnum.REGULATIONS}>
              <Regulations />
            </MenusGuard>
          ),
          children: [
            {
              path: routesPath.regulationsTabulatorTab,
              element: <Regulations />,
            },
          ],
        },
        {
          path: routesPath.menus,
          element: <Menus />,
          children: [
            {
              path: routesPath.menusTabulator,
              element: <Menus />,
              children: [
                {
                  path: routesPath.menusTabulatorTab,
                  element: <Menus />,
                  children: [
                    {
                      path: routesPath.menusTabulatorSubTab,
                      element: <Menus />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: routesPath.casinoGames,
          element: (
            <MenusGuard menuGuardName={MenusGuardsEnum.CASINO_GAMES}>
              <CasinoGames />
            </MenusGuard>
          ),
          children: [
            {
              path: routesPath.casinoGameEditor,
              element: <CasinoGamesDetails />,
            },
            {
              path: routesPath.casinoGamesGameCategoryEditor,
              element: <CasinoGamesCategoriesDetails />,
            },
            {
              path: routesPath.casinoGamesTabulatorTab,
              element: <CasinoGames />,
              children: [
                {
                  path: routesPath.casinoGamesTabulatorSubTab,
                  element: <CasinoGames />,
                },
              ],
            },
          ],
        },
        {
          path: routesPath.networkMedia,
          element: <NetworkMediaPage />,
          children: [
            {
              path: routesPath.networkMediaTabulatorTab,
              element: <NetworkMediaPage />,
              children: [
                {
                  path: routesPath.networkMediaTabulatorSubTab,
                  element: <NetworkMediaPage />,
                },
              ],
            },
          ],
        },
        {
          path: routesPath.testColorPickers,
          element: <ColorPickersPage />,
          children: [
            {
              path: routesPath.testColorPickersTabulator,
              element: <ColorPickersPage />,
            },
          ],
        },
        {
          path: routesPath.tools,
          element: <ToolsIndex />,
          children: [
            {
              path: routesPath.toolsTabulatorTab,
              element: <ToolsIndex />,
              children: [
                {
                  path: routesPath.toolsTabulatorSubTab,
                  element: <ToolsIndex />,
                },
              ],
            },
          ],
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ];
};
