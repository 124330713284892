import { FC } from 'react';
import { TabbedPage } from 'components/shared/TabbedPage/TabbedPage';
import { PageTab } from 'components/shared/TabbedPage/types';
import { AppLinks } from 'router/uri';
import ColorPickers from 'components/pages/Tests/ColorPickers/ColorPickers';

export const tabList: PageTab[] = [
  {
    link: AppLinks.TEST_COLOR_PICKERS,
    tab: '',
    component: <ColorPickers />,
  },
];

export const ColorPickersPage: FC = () => (
  <TabbedPage tabsList={tabList} baseRoute={AppLinks.TEST_COLOR_PICKERS} defaultTab={AppLinks.TEST_COLOR_PICKERS} />
);
