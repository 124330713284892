import { IconEnums } from '../common/IconEnums';
import { BrandTemplatesEnum } from '../store/brands/types';

export const useBrandIcon = () => {
  const brandLogo = {
    [BrandTemplatesEnum.T1]: IconEnums.T1,
    [BrandTemplatesEnum.API]: IconEnums.API,
    [BrandTemplatesEnum.M]: IconEnums.MODULAR,
  };

  const getBrandIcon = (brandTemplate?: number, asset?: string | null) =>
    !asset ? (
      <div
        className="brand-item-icon"
        style={{ background: `var(--${brandLogo[brandTemplate as BrandTemplatesEnum]}) no-repeat` }}
      ></div>
    ) : (
      <img src={asset} alt="Logo" className="brand-item-asset" />
    );

  return {
    getBrandIcon,
  };
};
