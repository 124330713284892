export enum SortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortOptionsValuesEnum {
  NAME = 'name',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}
