import { MenuItemProps } from '../MenuItem/MenuItem';
import { IconEnums } from 'common/IconEnums';
import { UserMenuLinks } from 'common/UserMenuLinks';

export enum UserMenuText {
  HELP = 'Help',
  WHATS_NEW = 'What"s new?',
  GIVE_A_FEEDBACK = 'Give a feedback',
  LOGOUT = 'Logout',
}

export const menuDefaultItems: MenuItemProps[] = [
  {
    text: UserMenuText.GIVE_A_FEEDBACK,
    icon: IconEnums.GIVE_A_FEEDBACK,
    onClick: () => window.open(UserMenuLinks.FEEDBACK),
  },
  {
    text: UserMenuText.LOGOUT,
    icon: IconEnums.LOGOUT,
  },
];
