import { FC } from 'react';
import { Formik } from 'formik';
import { SystemBlock } from 'components/shared/SystemBlock/SystemBlock';
import { CmsForm } from 'components/shared/CmsForm/CmsForm';
import { BrandColorPicker } from 'components/modules/Assets/BrandColorPicker/BrandColorPicker';
import { useFetchPickersColors } from 'components/modules/Assets/Colors/hooks/use-fetch-pickers-colors';
import { ThemeRowPickers } from 'components/modules/Assets/Colors/ThemeRow/types';

const initialState: { [key: string]: string } = {};

const ColorPickers: FC = () => {
  useFetchPickersColors();

  return (
    <Formik initialValues={initialState} onSubmit={() => {}} enableReinitialize>
      {({ values }) => (
        <CmsForm resetForm>
          <div className="color-palette">
            <SystemBlock>
              <BrandColorPicker
                type={ThemeRowPickers.BACKGROUND}
                text="Background"
                name="backgroundColor"
                defaultColor={values.backgroundColor || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.TITLE_TEXT}
                text="Title text"
                name="titleText"
                defaultColor={values.titleText || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.BODY_TEXT}
                text="Body text"
                name="bodyText"
                defaultColor={values.bodyText || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.SEPARATORS}
                text="Separators"
                name="separators"
                defaultColor={values.separators || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.BOARDERS}
                text="Boarders"
                name="boarders"
                defaultColor={values.boarders || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.ICONS}
                text="Icons"
                name="iconsColor"
                defaultColor={values.iconsColor || 'var(--placeholder)'}
              />

              <BrandColorPicker
                type={ThemeRowPickers.BACKGROUND}
                text="Background"
                name="backgroundColorSmall"
                isMinimize
                defaultColor={values.backgroundColorSmall || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.TITLE_TEXT}
                text="Text"
                name="titleTextColorSmall"
                isMinimize
                defaultColor={values.titleTextColorSmall || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.BODY_TEXT}
                text="Text"
                name="bodyTextColorSmall"
                isMinimize
                defaultColor={values.bodyTextColorSmall || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.SEPARATORS}
                text="Separators"
                name="separatorsColorSmall"
                isMinimize
                defaultColor={values.separatorsColorSmall || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.BOARDERS}
                text="Boarders"
                name="boardersColorSmall"
                isMinimize
                defaultColor={values.boardersColorSmall || 'var(--placeholder)'}
              />
              <BrandColorPicker
                type={ThemeRowPickers.ICONS}
                text="Icons"
                name="iconsColorSmall"
                isMinimize
                defaultColor={values.iconsColorSmall || 'var(--placeholder)'}
              />
            </SystemBlock>
          </div>
        </CmsForm>
      )}
    </Formik>
  );
};

export default ColorPickers;
