import { useHover } from './use-hover';
import { useEffect, useRef } from 'react';
import { useRect } from './use-rect';

export const useFixedPosition = () => {
  const [hoverRef, isHover] = useHover();
  const itemRef = useRef(null);

  const { rect, handleResize } = useRect(itemRef);

  useEffect(() => {
    handleResize();
  }, [isHover]);

  return {
    hoverRef,
    isHover,
    rect,
    itemRef,
  };
};
