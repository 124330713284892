import './NavigationSidebar.scss';
import { FC } from 'react';
import classNames from 'classnames';
import { defaultEntries, networkUserEntries, perBrandEntries, widgetsEntry } from './types';
import { NavbarUserMenu } from 'components/shared/NavbarUserMenu/NavbarUserMenu';
import { SidebarBlock } from '../../shared/SidebarBlock/SidebarBlock';
import Scrollbars from 'react-custom-scrollbars-2';
import { useIsMinimizeMenuByScreen } from 'hooks/use-is-minimize-menu-by-screen';
import { BrandsBlock } from '../../modules/Brands/BrandsBlock/BrandsBlock';
import { useNavigationSidebarProps } from 'hooks/use-navigation-sidebar-props';
import { BrandsList } from '../../modules/Brands/BrandsList/BrandsList';
import { Logo } from '../../shared/Logo/Logo';
import { InstancePicker } from '../../shared/InstancePicker/InstancePicker';
import { CurrentBrandAction } from '../../modules/Brands/CurrentBrandAction/CurrentBrandAction';
import { useBrandListActions } from '../../modules/Brands/hooks/use-brand-list-actions';
import { useIsShowWidgets } from 'hooks/widgets/use-is-show-widgets';

interface Props {
  onBeforeTabChange: () => Promise<boolean>;
  isChunk?: boolean;
}

export const NavigationSidebar: FC<Props> = ({ onBeforeTabChange, isChunk = false }) => {
  const {
    isMinimizeMode,
    isMenuAvailable,
    brands,
    menuBrands,
    siteId,
    isNetworkUser,
    isBrandsList,
    isMinimizeHoverMode,
    hoverRef,
    currentInstanceIcon,
    currentInstanceUrl,
    currentInstanceName,
    instanceMenuItems,
    currentBrand,
    isOneCustomerBrand,
  } = useNavigationSidebarProps(isChunk);
  const { setCurrentSiteId, handleOpenBrandsList, handleClearSiteId, pinBrand } = useBrandListActions();
  const isShowWidgets = useIsShowWidgets(currentBrand);

  useIsMinimizeMenuByScreen();

  if (!isMenuAvailable) {
    return null;
  }

  return (
    <>
      <div
        className={classNames({
          'navigation-sidebar': true,
          'navigation-sidebar--minimize': isMinimizeMode,
          'navigation-sidebar--minimize--hover': isMinimizeHoverMode,
        })}
        ref={hoverRef}
      >
        <Logo />
        <Scrollbars
          className="navigation-sidebar__links"
          thumbSize={54}
          autoHide
          hideTracksWhenNotNeeded
          autoHideDuration={500}
          renderTrackVertical={({ style, ...props }) => (
            <div {...props} className="scrollbar-track" style={{ ...style }}></div>
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div {...props} className="scrollbar-track__thumb" style={{ ...style }}></div>
          )}
        >
          <div className="navigation-sidebar__gradient"></div>
          <div
            className={classNames(
              'navigation-sidebar__blocks',
              isMinimizeMode && 'navigation-sidebar__blocks--minimize',
            )}
          >
            {isNetworkUser && (
              <InstancePicker
                menuItems={instanceMenuItems}
                icon={currentInstanceIcon || ''}
                instanceName={currentInstanceName}
                currentInstanceUrl={currentInstanceUrl}
                isHovered={isMinimizeHoverMode}
                isMinimize={isMinimizeMode}
              />
            )}
            {/*<SidebarBlock*/}
            {/*  items={topNavigationBlock}*/}
            {/*  isMinimizeMenuByScreenSize={isMinimizeMode}*/}
            {/*  onBeforeTabChange={onBeforeTabChange}*/}
            {/*  isHovered={isMinimizeHoverMode}*/}
            {/*  toggleList={closeListIfPerCompany}*/}
            {/*  isInstance={true}*/}
            {/*/>*/}
            {!isOneCustomerBrand && (
              <BrandsBlock
                brands={menuBrands}
                isNetworkUser={isNetworkUser}
                toggleBrandsList={handleOpenBrandsList}
                isMinimize={isMinimizeMode}
                isBrandsList={isBrandsList}
                currentSiteId={siteId}
                isHovered={isMinimizeHoverMode}
                onClick={setCurrentSiteId}
              />
            )}
            <div
              className={classNames(
                isOneCustomerBrand && 'per-brand-block--one-brand',
                'per-brand-block',
                siteId && 'per-brand-block--active',
              )}
            >
              {currentBrand && !isOneCustomerBrand && (
                <CurrentBrandAction
                  isHovered={isMinimizeHoverMode}
                  isMinimize={isMinimizeMode}
                  toggleList={handleClearSiteId}
                  asset={currentBrand?.assets?.favicon?.big?.brandAssetFileUrl}
                  brandTemplate={currentBrand.brandTemplate}
                  brandType={currentBrand.brandType}
                />
              )}
              {isShowWidgets && (
                <SidebarBlock
                  items={widgetsEntry}
                  isMinimizeMenuByScreenSize={isMinimizeMode}
                  onBeforeTabChange={onBeforeTabChange}
                  isHovered={isMinimizeHoverMode}
                />
              )}
              <SidebarBlock
                items={perBrandEntries}
                isMinimizeMenuByScreenSize={isMinimizeMode}
                onBeforeTabChange={onBeforeTabChange}
                isHovered={isMinimizeHoverMode}
              />
            </div>
            <SidebarBlock
              items={defaultEntries}
              isMinimizeMenuByScreenSize={isMinimizeMode}
              onBeforeTabChange={onBeforeTabChange}
              isHovered={isMinimizeHoverMode}
            />
            {isNetworkUser && (
              <SidebarBlock
                items={networkUserEntries}
                isMinimizeMenuByScreenSize={isMinimizeMode}
                onBeforeTabChange={onBeforeTabChange}
                isHovered={isMinimizeHoverMode}
              />
            )}
          </div>
          <div className="navigation-sidebar__gradient--bottom"></div>
        </Scrollbars>
        <div className="navigation-sidebar__user">
          <NavbarUserMenu isMinimize={isMinimizeMode} className="navbar-initials" />
        </div>
      </div>
      {isBrandsList && (
        <BrandsList
          setActiveBrands={() => {}}
          brandsList={brands || []}
          currentSiteId={siteId}
          canPin={true}
          canAddNewBrand={false}
          pinAction={pinBrand}
          isHovered={isMinimizeHoverMode}
          isBrandsList={isBrandsList}
          onClick={setCurrentSiteId}
          listClass="navigation-brands-list"
        />
      )}
    </>
  );
};
