import './Separator.scss';
import { FC } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const Separator: FC<Props> = ({ className }) => (
  <div className={classNames('navbar-separator', className)}></div>
);
