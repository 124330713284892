import { SystemBlockTableTypes, SystemBlockTypes } from 'common/SystemBlockEnums';
import { loyaltyLevelColorsData } from 'common/dummyData';
import {
  SystemBlockColorPaletteItemProps,
  SystemBlockLoyaltyColorsTableItemProps,
} from 'components/shared/SystemBlock/types';

export enum ColorPaletteText {
  RED = 'Red',
  BLUE = 'Blue',
  YELLOW = 'Yellow',
  GREEN = 'Green',
  PURPLE = 'Purple',
  ORANGE = 'Orange',
  CYAN = 'Cyan',
  WHITE = 'White',
  GREY = 'Grey',
  BLACK = 'Black',
  TRANSPARENT = 'Transparent',
}

export const colorPaletteItems = [
  {
    itemName: ColorPaletteText.RED,
  },
  {
    itemName: ColorPaletteText.BLUE,
  },
  {
    itemName: ColorPaletteText.YELLOW,
  },
  {
    itemName: ColorPaletteText.GREEN,
  },
  {
    itemName: ColorPaletteText.PURPLE,
  },
  {
    itemName: ColorPaletteText.ORANGE,
  },
  {
    itemName: ColorPaletteText.CYAN,
  },
  {
    itemName: ColorPaletteText.WHITE,
  },
  {
    itemName: ColorPaletteText.GREY,
  },
  {
    itemName: ColorPaletteText.BLACK,
  },
  {
    itemName: ColorPaletteText.TRANSPARENT,
  },
];

export const colorPaletteTableBlocks: SystemBlockColorPaletteItemProps = {
  headerProps: {
    systemBlockHeaderText: SystemBlockTypes.COLOR_PALETTE,
  },
  blockItemsProps: {
    itemsProps: colorPaletteItems,
  },
};

export const loyaltyLevelColorsTableBlocks: SystemBlockLoyaltyColorsTableItemProps = {
  headerProps: {
    systemBlockHeaderText: SystemBlockTableTypes.LOYALTY_LEVEL_CUSTOM_COLORS,
    buttonText: 'New color',
  },
  tableProps: {
    tableItemsProps: loyaltyLevelColorsData,
  },
};
