import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExperienceState, GetExperience, GetExperienceGamesFields } from './types';
import { fetchExperience, fetchExperienceGames } from './actions';

const initialState: ExperienceState = {
  experienceData: null,
  experienceGamesProperties: null,
};

export const experienceSlice = createSlice({
  name: 'experience',
  initialState,
  reducers: {
    clearExperienceState(state) {
      state.experienceData = null;
    },
    clearExperienceGamesState(state) {
      state.experienceGamesProperties = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchExperience.fulfilled,
      (state: ExperienceState, action: PayloadAction<GetExperience | null>) => {
        if (action.payload !== null) {
          state.experienceData = action.payload;
        }
      },
    );
    builder.addCase(
      fetchExperienceGames.fulfilled,
      (state: ExperienceState, action: PayloadAction<GetExperienceGamesFields | null>) => {
        if (action.payload !== null) {
          state.experienceGamesProperties = action.payload;
        }
      },
    );
  },
});
