import { Form, useFormikContext } from 'formik';
import { FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { TabsValidationContext } from 'contexts/Validation/tabs-validation.context';
import { useNotification } from 'contexts/Notifications/useNotification';
import { NotificationMessages } from '../Notification/types';

type Props = PropsWithChildren & {
  resetForm?: boolean;
  isSuccessNotification?: boolean;
};

export const CmsForm: FC<Props> = ({ children, resetForm, isSuccessNotification = false }) => {
  const formState = useFormikContext();
  const tabValidator = useContext(TabsValidationContext);
  const { open, systemNotification, closeSystemNotification } = useNotification();
  const [formHasChanges, setFormHasChanges] = useState(false);

  /**
   * Prevent tab change if form has changes and not saved
   */
  const onBeforeTabChange = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      if (formState.dirty) {
        return systemNotification({
          title: 'Leaving too soon?',
          subtitle: 'Changes you made may not be saved',
          confirmButtonText: 'Leave',
          cancelButtonText: 'Cancel',
          onCancel: () => {
            resolve(false);
            closeSystemNotification();
          },
          onConfirm: () => {
            resolve(true);
            closeSystemNotification();
            if (resetForm) {
              formState.resetForm();
            }
          },
        });
      }

      resolve(true);
    });
  }, [formState.dirty]);

  const onBeforeSubmit = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      if (formState.dirty) {
        return systemNotification({
          title: 'Irreversible changes',
          subtitle: 'Are you sure you want to apply the following changes?',
          confirmButtonText: 'Apply now',
          cancelButtonText: 'Cancel',
          onCancel: () => {
            resolve(false);
            closeSystemNotification();
          },
          onConfirm: () => {
            resolve(true);
            closeSystemNotification();
          },
        });
      }

      resolve(true);
    });
  }, [formState.dirty]);

  const handleSubmit = useCallback(async () => {
    if (tabValidator && formState.isValid) {
      const submit = async () => {
        tabValidator.setSubmitButtonIsLoading(true);
        try {
          await formState.submitForm();
          tabValidator.setSubmitButtonIsSucceed(true);
          if (isSuccessNotification) {
            open(NotificationMessages.CHANGES_SUCCESS);
          }
          setFormHasChanges(false);
          formState.resetForm();
        } catch (err) {
          console.error(err);
          open(NotificationMessages.CHANGES_ERROR);
        } finally {
          tabValidator.setSubmitButtonIsLoading(false);
          tabValidator.setCheckBeforeSubmit(false);
        }
      };

      if (!tabValidator.checkBeforeSubmit || (await tabValidator.onBeforeSubmit())) {
        await submit();
      } else {
        tabValidator.resetState();
        formState.resetForm();
        setFormHasChanges(false);
      }
    }
  }, [tabValidator?.onBeforeSubmit, formState.submitForm, tabValidator?.checkBeforeSubmit, formState.isValid]);

  useEffect(() => {
    if (tabValidator) {
      if (!formHasChanges && formState.dirty) {
        setFormHasChanges(true);
        tabValidator.setSubmitButtonIsDisabled(true);
      } else if (formHasChanges && !formState.dirty) {
        setFormHasChanges(false);
        tabValidator.setSubmitButtonIsDisabled(true);
      } else if (formHasChanges) {
        tabValidator.setSubmitButtonIsDisabled(!formState.isValid);
      }
    }
  }, [tabValidator, formState.dirty, formState.isValid, formHasChanges]);

  useEffect(() => {
    if (tabValidator) {
      tabValidator.setHandleSubmit(() => handleSubmit);
      tabValidator.setOnBeforeTabChange(() => onBeforeTabChange);
      tabValidator.setOnBeforeSubmit(() => onBeforeSubmit);
    }
  }, [tabValidator, handleSubmit, onBeforeTabChange]);

  return <Form>{children}</Form>;
};
