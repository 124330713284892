import { FC } from 'react';
import { Icon } from 'components/shared/Icon/Icon';
import { ToolTip } from 'components/shared/ToolTip/ToolTip';
import { useFixedPosition } from 'hooks/use-fixed-position';
import { IconEnums } from 'common/IconEnums';

interface Props {
  brandColorName: string;
  brandColorValue: string;
  isDarkMode?: boolean;
  icon: IconEnums;
  setColors: (defaultColor: string) => void;
}

export const ContentColorPickerItem: FC<Props> = ({ brandColorName, brandColorValue, isDarkMode, icon, setColors }) => {
  const { rect, isHover, hoverRef, itemRef } = useFixedPosition();

  return (
    <div
      key={brandColorValue + brandColorName}
      className="picker-wrapper"
      role="presentation"
      onClick={() => setColors(brandColorValue)}
      ref={hoverRef}
    >
      <div
        className="picker-wrapper__item"
        style={{
          background: `var(--picker-${icon})`,
        }}
        ref={itemRef}
      >
        {brandColorName.toLowerCase() !== 'transparent' ? (
          <Icon
            iconName={icon}
            bgColor={
              isDarkMode ? `linear-gradient(90deg, ${brandColorValue} 50%, ${brandColorValue} 50%)` : brandColorValue
            }
          />
        ) : (
          <div
            className="picker-wrapper__item--transparent"
            style={{ background: `var(--transparent-${icon}) no-repeat` }}
          ></div>
        )}
        {isHover && (
          <ToolTip className="picker-wrapper__item__tooltip" top={rect.top + 25} left={rect.left + 50}>
            {brandColorName}
          </ToolTip>
        )}
      </div>
    </div>
  );
};
