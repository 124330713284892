import { RadioButtonType } from './RadioButtons';

export enum RadioTypes {
  LISTED = 'LISTED',
  ALIGNMENT = 'ALIGNMENT',
  SPREAD = 'SPREAD',
  DISABLED_ENABLED = 'DISABLED_ENABLED',
}

export const alignmentRadioItems: RadioButtonType[] = [
  {
    text: 'Hide',
    value: 'hide',
  },
  {
    text: '',
    value: 'alignLeft',
  },
  {
    text: '',
    value: 'alignCenter',
  },
  {
    text: '',
    value: 'alignRight',
  },
];

export const spreadRadioItems: RadioButtonType[] = [
  {
    text: '',
    value: 'rest',
  },
  {
    text: '',
    value: 'spread',
  },
];

export const disabledEnableRatioItems: RadioButtonType[] = [
  {
    text: 'Disable',
    value: '',
  },
  {
    text: 'Enable',
    value: '1',
  },
];
