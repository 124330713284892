import './BrandItem.scss';
import { FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { BrandTemplatesEnum, BrandTypesEnum } from 'store/brands/types';
import { IconEnums } from 'common/IconEnums';
import { Icon } from 'components/shared/Icon/Icon';
import { useBrandIcon } from 'hooks/use-brand-icon';

interface Props {
  siteId?: number;
  brandName?: string;
  brandType?: BrandTypesEnum;
  brandTemplate: BrandTemplatesEnum;
  companyName?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  setIsSelected?: (e: SyntheticEvent<HTMLDivElement, Event>) => void;
  isMinimize?: boolean;
  className?: string;
  isHovered?: boolean;
  canPin?: boolean;
  pinAction?: (id: number) => void;
  isPinned?: boolean;
  onClick?: (siteId: number) => void;
  asset?: string | null;
}

export const BrandItem: FC<Props> = ({
  siteId,
  brandName,
  brandTemplate,
  isMinimize,
  className = '',
  isSelected,
  isHovered,
  canPin = false,
  pinAction = () => {},
  isPinned,
  onClick,
  asset,
}) => {
  const { getBrandIcon } = useBrandIcon();

  const brandItemClassName = classNames({
    'brand-item': true,
    [className]: className,
  });

  const infoClassName = classNames({
    'brand-item__info': true,
    'brand-item__info--minimize': isMinimize,
    'brand-item__info--selected': isSelected,
    'brand-item__info--minimize--hover': isHovered,
  });

  return (
    <div
      className={brandItemClassName}
      role="presentation"
      onClick={() => {
        if (onClick && siteId) {
          onClick(siteId);
        }
      }}
    >
      <div className={infoClassName}>
        {getBrandIcon(brandTemplate, asset)}

        {brandName && (
          <div className="brand-item-name" title={brandName}>
            {brandName}
          </div>
        )}
      </div>

      {canPin && (
        <>
          {isPinned && (
            <div
              className="brand-item-pinned"
              onClick={(e) => {
                e.stopPropagation();
                if (siteId) {
                  pinAction(siteId);
                }
              }}
              role="presentation"
            >
              <Icon iconName={IconEnums.PINNED} />
            </div>
          )}

          {!isPinned && (
            <div
              className="brand-item-pin"
              onClick={(e) => {
                e.stopPropagation();
                if (siteId) {
                  pinAction(siteId);
                }
              }}
              role="presentation"
            >
              <Icon iconName={IconEnums.PIN} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
