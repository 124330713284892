import { BackendResponse } from '../app/types';
import { EntitySaveOperationEnum } from '../app/enums/entity-save-operation.enum';

export const types = Object.freeze({
  WIDGET_FETCH: '[Widgets] WIDGET_FETCH',
  WIDGET_PUT: '[Widget] WIDGET_PUT',
});

export type WidgetsState = {
  widgetEditor: GetBrandWidgetFields | null;
};

export type GetBrandWidgetResponse = BackendResponse<GetBrandWidgetFields>;
export type PutBrandWidgetResponse = BackendResponse<PutBrandWidgetFields>;

export type GetBrandWidgetFields = {
  widgetTypeId: number;
  widgetStatus: number;
  siteId: number;
  updatedAt: Date | null;
  fields: string[];
};

export type GetBrandWidgetRequest = {
  siteId: number;
  widgetId: number;
};

export type PutBrandWidgetRequest = {
  siteId: number;
  widgetId: number;
  meta: PutBrandWidgetMeta;
  fields: Record<string, unknown>;
};

export type PutBrandWidgetMeta = {
  operationId: EntitySaveOperationEnum;
};

export type PutBrandWidgetFields = {
  updatedWidget: number;
};
