import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetNetworkIconsRequest, GetNetworkImagesRequest, types } from './types';
import { AsyncThunkParams } from '../app/types';
import { endpoints } from '../../config/endpoints';
import {
  DeleteBrandAsset,
  DeleteBrandAssetResponse,
  GetBrandAssets,
  GetBrandAssetsResponse,
  PatchBrandAsset,
  PatchBrandAssetResponse,
  PostBrandAsset,
  PostBrandAssetResponse,
  GetBrandIcons,
  GetBrandIconsResponse,
  PutBrandAsset,
  PutBrandAssetsResponse,
  PutIconsRequest,
  PostBrandAssetsRequest,
  PatchBrandAssetsRequest,
} from '../assets/types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchNetworkImages = createAsyncThunk<
  GetBrandAssets | null,
  AsyncThunkParams<GetBrandAssetsResponse, GetNetworkImagesRequest>
>(types.NETWORK_MEDIA_IMAGES_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams('');

      // optional tabName param
      if (payload.tabName) {
        params.append('tabName', payload.tabName.toString());
      }
      // optional systemGroupId param
      if (payload.systemGroupId) {
        params.append('systemGroupId', payload.systemGroupId.toString());
      }

      if (payload.orderBy) {
        params.append('orderBy', payload.orderBy.toString());
      }

      if (payload.orderDirection) {
        params.append('orderDirection', payload.orderDirection.toString());
      }

      const urlParams = params.toString() ? `?${params.toString()}` : '';

      const url = `${endpoints.networkMediaGetImages}${urlParams}`;

      const result = await execute('GET', url);

      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.NETWORK_MEDIA_IMAGES_FETCH, err);
    throw err;
  }
});

export const postNetworkImages = createAsyncThunk<
  PostBrandAsset | null,
  AsyncThunkParams<PostBrandAssetResponse, PostBrandAssetsRequest>
>(types.NETWORK_MEDIA_IMAGES_ASSET_POST, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('POST', endpoints.networkMediaPostImages, payload);

      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.NETWORK_MEDIA_IMAGES_ASSET_POST, err);
    throw err;
  }
});

export const patchNetworkImages = createAsyncThunk<
  PatchBrandAsset | null,
  AsyncThunkParams<PatchBrandAssetResponse, PatchBrandAssetsRequest>
>(types.NETWORK_MEDIA_IMAGES_ASSET_PATCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('PATCH', endpoints.networkMediaPatchImages, payload);

      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.NETWORK_MEDIA_IMAGES_ASSET_PATCH, err);
    throw err;
  }
});

export const deleteNetworkImages = createAsyncThunk<
  DeleteBrandAsset | null,
  AsyncThunkParams<DeleteBrandAssetResponse, { assetId: number }>
>(types.NETWORK_MEDIA_IMAGES_ASSET_DELETE, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute(
        'DELETE',
        endpoints.networkMediaDeleteImages.replace(':assetId', payload.assetId.toString()),
        payload,
      );

      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.NETWORK_MEDIA_IMAGES_ASSET_DELETE, err);
    throw err;
  }
});

export const fetchNetworkIcons = createAsyncThunk<
  GetBrandIcons | null,
  AsyncThunkParams<GetBrandIconsResponse, GetNetworkIconsRequest>
>(types.NETWORK_MEDIA_ICONS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams('');

      // optional tabName param
      if (payload.tabName) {
        params.append('tabName', payload.tabName.toString());
      }

      const urlParams = params.toString() ? `?${params.toString()}` : '';

      const url = `${endpoints.networkMediaGetIcons}${urlParams}`;

      const result = await execute('GET', url);

      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.NETWORK_MEDIA_ICONS_FETCH, err);
    throw err;
  }
});

export const putNetworkIcons = createAsyncThunk<
  PutBrandAsset | null,
  AsyncThunkParams<PutBrandAssetsResponse, PutIconsRequest>
>(types.NETWORK_MEDIA_ICONS_PUT, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('PUT', endpoints.networkMediaPutIcons, payload);

      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.NETWORK_MEDIA_ICONS_PUT, err);
    throw err;
  }
});
