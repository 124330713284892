export enum AssetsModesEnum {
  DEFAULT_MODE = 1,
  DARK_MODE = 2,
}

export interface UploadRadioItemsI {
  defaultValue: AssetsModesEnum.DEFAULT_MODE;
  items: {
    name: string;
    value: AssetsModesEnum;
  }[];
}
