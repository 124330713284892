import { FC, ReactElement } from 'react';
import { useRoutes } from 'react-router-dom';
import { RouteObject } from 'react-router';

interface Props {
  routes: RouteObject[];
}

export const WithRouter: FC<Props> = ({ routes }): ReactElement | null => {
  return useRoutes(routes);
};
