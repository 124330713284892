import { useToggle } from 'hooks/use-toggle';
import { ChangeEvent, useEffect, useState } from 'react';
import { BrandListed } from '../BrandsList/types';
import { Brand, BrandTemplatesEnum, BrandTypesEnum } from 'store/brands/types';
import { useStatusForm } from '../../BrandSettings/Status/hooks/use-status-form';
import { MenuItemProps } from 'components/shared/MenuItem/MenuItem';
import { IconEnums } from 'common/IconEnums';
import { useAppActions, useAppDispatch, useAppSelector } from 'store/hooks';
import { PutUpdateSession } from 'store/app/types';

export const useBrandListProps = (brandsList: BrandListed[], canPin: boolean) => {
  const [isNewBrandModalOpen, toggleNewBrandModal] = useToggle(false);
  const [isNewModularBrandModalOpen, toggleNewModularBrandModal] = useToggle(false);
  const [searchBrand, setSearchBrand] = useState<string>('');
  const [filteredBrands, setFilteredBrands] = useState<BrandListed[]>([]);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [selectedBrandName, setSelectedBrandName] = useState<string>('');
  const [selectedBrandType, setSelectedBrandType] = useState<BrandTemplatesEnum>(BrandTemplatesEnum.T1);
  const dispatch = useAppDispatch();
  const { handleNavigationMode, handleHoveredMode } = useAppActions();
  const { isBrandsSettingsList, session } = useAppSelector((state) => state.app);
  const currentSession = session as PutUpdateSession;

  const { submitNewBrand } = useStatusForm();

  const handleMenuItemClick = (brandTypeName: string, brandTemplate: BrandTemplatesEnum): void => {
    setSelectedBrandName(brandTypeName);
    setSelectedBrandType(brandTemplate);
    toggleNewBrandModal();
    toggleMenu();
  };

  const handleSubmitNewBrand = async (brand: Partial<Brand>) => {
    await submitNewBrand(brand);

    if (isNewBrandModalOpen) {
      toggleNewBrandModal();
    }
  };

  const brandTypesMenu: MenuItemProps[] = [
    {
      text: 'API brand',
      icon: IconEnums.API_MINIMIZE,
      onClick: () => {
        handleMenuItemClick('API brand', BrandTemplatesEnum.API);
      },
    },
    {
      text: 'Template 1',
      icon: IconEnums.T1_MINIMIZE,
      onClick: () => {
        handleMenuItemClick('Template 1', BrandTemplatesEnum.T1);
      },
    },
    {
      text: 'Modular portal brand',
      icon: IconEnums.MODULAR_MINIMIZE,
      onClick: () => {
        handleMenuItemClick('Modular portal brand', BrandTemplatesEnum.M);
      },
    },
    {
      text: 'Sandbox portal brand',
      icon: IconEnums.MODULAR_MINIMIZE,
      onClick: () => {
        handleSubmitNewBrand({
          casinoId: null,
          brandTemplate: BrandTemplatesEnum.M,
          brandType: BrandTypesEnum.CUSTOMER_BRAND,
        });
      },
    },
    {
      text: 'Atom portal brand',
      icon: IconEnums.ATOM_MINIMIZE,
      onClick: () => {
        handleSubmitNewBrand({
          casinoId: null,
          brandTemplate: BrandTemplatesEnum.M,
          brandType: BrandTypesEnum.ATOMIC,
        });
      },
    },
  ];

  const handleSetSearch = (e: ChangeEvent<HTMLInputElement>): void => setSearchBrand(e.target.value);

  const isBrandPinned = (brandId: number) => currentSession.userSessionValue.pinnedBrands?.includes(brandId);

  const filterBrandsByName = (list: BrandListed[]) =>
    list.filter((item: BrandListed) => item.brandName.toLowerCase().includes(searchBrand.toLowerCase()));

  const sortBrandsByPin = (list: BrandListed[]) => {
    if (canPin) {
      return list.reduce(
        (acc: BrandListed[], item) =>
          currentSession.userSessionValue.pinnedBrands?.includes(item.siteId) ? [item, ...acc] : [...acc, item],
        [],
      );
    } else {
      return list;
    }
  };

  useEffect(() => {
    dispatch(handleNavigationMode(true));
    dispatch(handleHoveredMode(false));

    const filteredList: BrandListed[] = sortBrandsByPin(filterBrandsByName(brandsList));

    setFilteredBrands(filteredList);

    return () => {
      if (!isBrandsSettingsList) {
        dispatch(handleHoveredMode(true));
      }
    };
  }, [searchBrand, brandsList]);

  return {
    searchBrand,
    filteredBrands,
    isMenuOpen,
    isBrandPinned,
    isNewBrandModalOpen,
    brandTypesMenu,
    selectedBrandName,
    selectedBrandType,
    isNewModularBrandModalOpen,
    toggleNewModularBrandModal,
    toggleNewBrandModal,
    toggleMenu,
    handleSubmitNewBrand,
    handleSetSearch,
  };
};
