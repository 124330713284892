import { BrandTypesEnum } from 'store/brands/types';

export const defaultUsersLogItems = [
  {
    userName: 'Stas Boiko',
    date: '2023-12-19T15:28:46.493Z',
  },
  {
    userName: 'Odi Segal',
    date: '2023-12-19T15:28:46.493Z',
  },
  {
    userName: 'Mand M',
    date: '2023-12-19T15:28:46.493Z',
  },
  {
    userName: 'Took Took',
    date: '2023-12-19T15:28:46.493Z',
  },
  {
    userName: 'Rishi Sunak',
    date: '2023-12-19T15:28:46.493Z',
  },
  {
    userName: 'Mand M',
    date: '2023-12-19T15:28:46.493Z',
  },
  {
    userName: 'Took Took',
    date: '2023-12-19T15:28:46.493Z',
  },
  {
    userName: 'Rishi Sunak',
    date: '2023-12-19T15:28:46.493Z',
  },
];

export const aspireEngageFrontEndTokensData = [
  {
    id: 1,
    tableItemName: 'Token 1',
    frontEndTokensDescription: 'Token description',
    frontEndTokensToken: 'T',
    frontEndTokensId: 'Token ID',
  },
];

export const aspireEngageAeApiCredentialsData = [
  {
    id: 1,
    tableItemName: 'Credentials 1',
    aeApiCredentialsDescription: 'Credentials description',
    aeApiCredentialsApiToken: 'T',
    aeApiCredentialsSecretKey: 'Secret key',
  },
];

export const aspireEngageRecommendedGamesModelData = [
  {
    id: 1,
    tableItemName: 'Model 1',
    recommendedGamesModelDescription: 'Model description',
    recommendedGamesModelMobile: 'Model mobile',
    recommendedGamesModelDesktop: 'Model Desktop',
    recommendedGamesModelSize: 1,
  },
];

export const aspireEngageAdditionalParametersData = [
  {
    itemName: 'Aggregate',
    additionalParametersAggregate: '1',
  },
];

export const atomBrands = [
  {
    brandType: BrandTypesEnum.ATOMIC,
    brandName: 'Brand name',
    date: '2023-12-19T15:28:46.493Z',
  },
  {
    brandType: BrandTypesEnum.ATOMIC,
    brandName: 'Brand name',
    date: '2023-12-19T15:28:46.493Z',
  },
  {
    brandType: BrandTypesEnum.ATOMIC,
    brandName: 'Brand name',
    date: '2023-12-19T15:28:46.493Z',
  },
];

export const loyaltyLevelColorsData = [
  {
    id: 1,
    tableItemName: 'color',
  },
];
export const logosLandingPageLogosData = [
  {
    itemName: 'Default logo',
    toolTipText: 'Help text Help text Help text Help text Help text Help text Help text',
  },
];

export const logosScheduledBrowseLogoData = [
  {
    tableItemName: 'Schedule 1',
  },
  {
    tableItemName: 'Time period',
  },
  {
    tableItemName: 'Full menu mode logo',
    toolTipText: 'Help text Help text Help text Help text Help text Help text Help text',
  },
  {
    tableItemName: 'Minimized menu mode logo',
    toolTipText: 'Help text Help text Help text Help text Help text Help text Help text',
  },
];

export const imagesTournamentsRanksData = [
  {
    title: 'Rank1',
    toolTipText: 'Help text Help text Help text Help text Help text Help text Help text',
  },
  {
    title: 'Rank2',
    toolTipText: 'Help text Help text Help text Help text Help text Help text Help text',
  },
  {
    title: 'Rank3',
    toolTipText: 'Help text Help text Help text Help text Help text Help text Help text',
  },
  {
    title: 'Rank4',
    toolTipText: 'Help text Help text Help text Help text Help text Help text Help text',
  },
  {
    title: 'Rank5',
    toolTipText: 'Help text Help text Help text Help text Help text Help text Help text',
  },
];

export const logosThumbnailsSkeletonLoadersData = [
  {
    itemName: 'Promotion fold',
  },
  {
    itemName: 'Promotion banner',
  },
  {
    itemName: 'Casino game thumbnail',
  },
  {
    itemName: 'Live casino thumbnail',
  },
  {
    itemName: 'Bingo',
  },
  {
    itemName: 'Sport',
  },
  {
    itemName: 'Jackpot',
  },
  {
    itemName: 'Winner',
  },
  {
    itemName: 'Info item',
  },
];

export const logosLoadingScreenData = [
  {
    itemName: 'Casino game',
  },
];

export const defaultRoles = [
  {
    roleId: 'R001',
    roleName: 'Role name',
  },
  {
    roleId: 'R002',
    roleName: 'Role name',
  },
  {
    roleId: 'R003',
    roleName: 'Role name',
  },
  {
    roleId: 'R004',
    roleName: 'Role name',
  },
];

export const defaultTasksGroups = [
  {
    title: 'Browse pages',
    groupId: '1',
    tasks: [
      [
        {
          taskId: '1',
          taskDescription: 'Task',
        },
        {
          taskId: '2',
          taskDescription: 'Task',
        },
        {
          taskId: '3',
          taskDescription: 'Task',
        },
      ],
      [
        {
          taskId: '1',
          taskDescription: 'Task',
        },
      ],
    ],
  },
  {
    title: 'Games categories',
    groupId: '2',
    tasks: [
      [
        {
          taskId: '1',
          taskDescription: 'Task',
        },
        {
          taskId: '2',
          taskDescription: 'Task',
        },
        {
          taskId: '3',
          taskDescription: 'Task',
        },
      ],
      [
        {
          taskId: '1',
          taskDescription: 'Task',
        },
      ],
    ],
  },
];
