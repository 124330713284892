import { useAppActions, useAppDispatch } from 'store/hooks';
import { useFetchWithMsal } from 'hooks/use-fetch-with-msal';
import { useCallback } from 'react';

export const useCmsRegulations = () => {
  const dispatch = useAppDispatch();
  const { fetchRegulations } = useAppActions();
  const { execute } = useFetchWithMsal();

  const doGetRegulationsRequest = () => {
    dispatch(
      fetchRegulations({
        execute,
      }),
    );
  };

  return {
    doGetRegulationsRequest: useCallback(doGetRegulationsRequest, [execute]),
  };
};
