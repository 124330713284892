export const endpoints = {
  getPermissionsMap: 'v1/access-control/map',
  getUserSession: 'v1/users/session',
  putUserSession: 'v1/users/session',
  localizationProperties: 'v1/network-editor/localization',
  credentialsPamInstances: 'v1/network-editor/pam-instances',
  networkExperience: 'v1/network-editor/network-experience',
  networkExperiencePut: 'v1/network-editor/network-experience',
  networkExperienceWidgetsKeys: '/v1/network-editor/network-experience/widgets/keys',
  networkExperienceWidgetsKeysPut: '/v1/network-editor/network-experience/widgets/keys',
  networkExperienceWidgetsTopics: '/v1/network-editor/network-experience/widgets/topics',
  networkExperienceWidgetsTopicsPut: '/v1/network-editor/network-experience/widgets/topics',
  cmsUsers: 'v1/users',
  cmsUsersGetUsers: 'v1/users',
  cmsUsersPostUser: 'v1/users/user',
  cmsUsersPatchUser: 'v1/users/user/:userId',
  cmsUsersDeleteUser: 'v1/users/user/:userId',
  cmsUsersGetCompanies: 'v1/companies',
  cmsUsersPostCompany: 'v1/companies/company',
  cmsUsersPatchCompany: 'v1/companies/company/:companyId',
  cmsUsersDeleteCompany: 'v1/companies/company/:companyId',
  uploaderFile: 'v1/files/file',
  licenses: 'v1/regulation-properties',
  regulationPropertiesRegulations: 'v1/regulation-properties/regulations',
  regulationPropertiesRegulatedFeatures: 'v1/regulation-properties/regulated-features',
  brands: 'v1/brands',
  brandsFull: 'v1/brands/full',
  brandsPostBrand: 'v1/brands/brand',
  brandsPutBrandGenerals: 'v1/brands/brand-settings/general',
  brand: 'v1/brands/brand/:siteId',
  brandsBrandSettings: 'v1/brands/brand-settings/:siteId',
  brandsBrandSettingsDetails: 'v1/brands/brand-settings/details',
  brandsBrandSettingsPutAuthorization: 'v1/brands/brand-settings/authorization',
  brandsBrandSettingsPutIntegrations: 'v1/brands/brand-settings/integrations',
  languages: 'v1/translations/languages',
  countries: 'v1/countries',
  brandAssetsGetImages: 'v1/brands-assets/images',
  brandAssetsPostAsset: 'v1/brands-assets/images/image',
  brandAssetsPatchAsset: 'v1/brands-assets/images/image',
  brandAssetsDeleteAsset: 'v1/brands-assets/images/image/:assetId',
  brandAssetsGetLogos: 'v1/brands-assets/logos/:siteId',
  brandAssetsPutLogos: 'v1/brands-assets/logos',
  brandAssetsGetIcons: 'v1/brands-assets/icons/:siteId',
  brandAssetsPutIcons: 'v1/brands-assets/icons',
  brandComponentsSettings: 'v1/brands-components-settings/settings-items/:siteId',
  brandComponentsSettingsPut: 'v1/brands-components-settings/settings-items',
  brandComponentsSettingsPatch: 'v1/brands-components-settings/settings-item',
  brandAssetsGetColors: 'v1/brands-assets/colors/:siteId',
  brandAssetsPutColors: 'v1/brands-assets/colors',
  brandAssetsGetColorsThemes: 'v1/brands-assets/colors/themes/:siteId',
  brandAssetsPutColorsThemes: 'v1/brands-assets/colors/themes',
  brandAssetsGetColorsPalette: 'v1/brands-assets/colors/palette/:siteId',
  brandAssetsPutColorsPalette: 'v1/brands-assets/colors/palette',
  casinoGamesGetGames: 'v1/games',
  casinoGame: 'v1/games/game/:companyGameId',
  casinoGameGetGameSettings: 'v1/games/settings',
  casinoGameUpdateGameSettings: 'v1/games/settings',
  casinoGamesGetNewGames: 'v1/games/new',
  casinoGamesUpdateGameState: 'v1/games/game-state',
  casinoGamesGetGamesCategories: 'v1/games/categories',
  casinoGamesPostGameCategory: 'v1/games/categories/category',
  casinoGamesPutGameCategory: 'v1/games/categories/category/:categoryId',
  casinoGamesPatchGameCategory: 'v1/games/categories/category/content/:categoryId',
  casinoGamesDeleteGameCategory: 'v1/games/categories/category/content/:categoryId',
  casinoGamesGetCategoryGroups: 'v1/games/category-groups',
  casinoGamesGetCategoryGroup: 'v1/games/category-groups/category-group/:categoryGroupId',
  casinoGamesPostCategoryGroup: 'v1/games/category-groups/category-group',
  casinoGamesPutCategoryGroup: 'v1/games/category-groups/category-group/:categoryGroupId',
  casinoGamesDeleteCategoryGroup: 'v1/games/category-groups/category-group/:categoryGroupId ',
  menusGetMenuItems: 'v1/menus/menu-items/:siteId',
  menusPutMenuItems: 'v1/menus/menu-items',
  menusGetGuards: 'v1/menus-guards',
  experience: 'v1/brands-experience/experience-items/:siteId',
  experienceGames: 'v1/brands-experience/experience-games-items/:siteId',
  experiencePut: 'v1/brands-experience/experience-items',
  experiencePatch: 'v1/brands-experience/experience-item',
  networkMediaGetImages: 'v1/network-media/images',
  networkMediaPostImages: 'v1/network-media/images/image',
  networkMediaPatchImages: 'v1/network-media/images/image',
  networkMediaDeleteImages: 'v1/network-media/images/image/:assetId',
  networkMediaGetIcons: 'v1/network-media/icons',
  networkMediaPutIcons: 'v1/network-media/icons',
  widgetsGetBrandWidget: 'v1/widgets/brand/:siteId',
  widgetsPutBrandWidget: 'v1/widgets/brand/:siteId',
};
