import { useEffect, useState } from 'react';
import { useToggle } from 'hooks/use-toggle';
import { useAppSelector } from 'store/hooks';
import { BrandColorPalette, BrandColorTheme } from 'store/assets/types';
import { ThemeRowPickers } from '../Colors/ThemeRow/types';
import { DefaultThemeRowsNamesEnum } from '../Colors/MainTheme/types';
import { ColorPaletteText } from '../Colors/ColorPalette/types';
import { SystemGroupColorsEnum } from 'common/SystemGroup/SystemGroupColorsEnum';

export const useContentPickersProps = (type: ThemeRowPickers) => {
  const [isBrandTab, toggleTab] = useToggle(false);
  const [pickersValues, setPickersValues] = useState<{ brandColorName: string; brandColorValue: string }[]>([]);
  const { colorThemes, palettes } = useAppSelector((state) => state.assets);

  const themesRowsOrder = [
    DefaultThemeRowsNamesEnum.PRIMARY_COLOR,
    DefaultThemeRowsNamesEnum.SECONDARY_COLOR,
    DefaultThemeRowsNamesEnum.TERTIARY_COLOR,
  ];

  const paletteRowsOrder = [
    ColorPaletteText.RED,
    ColorPaletteText.BLUE,
    ColorPaletteText.YELLOW,
    ColorPaletteText.GREEN,
    ColorPaletteText.PURPLE,
    ColorPaletteText.ORANGE,
    ColorPaletteText.CYAN,
    ColorPaletteText.WHITE,
    ColorPaletteText.GREY,
    ColorPaletteText.BLACK,
    ColorPaletteText.TRANSPARENT,
  ];

  const tabItems = [
    {
      text: 'Brand',
      value: false,
    },
    {
      text: 'Palette',
      value: true,
    },
  ];

  const filteredThemesByType = () =>
    (colorThemes as BrandColorTheme[])?.filter(({ brandColorTypeId }) => brandColorTypeId === type);

  const sortThemesByOrder = (themes: BrandColorTheme[]) =>
    themes.reduce((acc, theme) => {
      const currentTheme = theme?.brandColorName as DefaultThemeRowsNamesEnum;
      if (themesRowsOrder.includes(currentTheme)) {
        acc[themesRowsOrder.indexOf(currentTheme)] = theme;
      }

      return acc;
    }, [] as BrandColorTheme[]);

  const sortPaletteByOrder = (palette?: BrandColorPalette[] | null) =>
    palette?.reduce((acc, item) => {
      const currentPalette = item?.brandColorPaletteName as ColorPaletteText;
      if (paletteRowsOrder.includes(currentPalette)) {
        acc[paletteRowsOrder.indexOf(currentPalette)] = item;
      }

      return acc;
    }, [] as BrandColorPalette[]);

  const convertedThemes = () =>
    (sortThemesByOrder(filteredThemesByType()) as BrandColorTheme[])?.map((theme) => ({
      brandColorName: `${theme?.brandColorThemeName} ${theme?.brandColorName.toLowerCase()}`,
      brandColorValue: theme?.brandColorValue,
    }));

  const convertedPalettes = (items?: BrandColorPalette[]) =>
    items?.map((palette) => ({
      brandColorName: palette?.brandColorPaletteName,
      brandColorValue: palette?.brandColorPaletteValue,
    }));

  const getDefaultsPaletteColors = () =>
    palettes?.filter(
      ({ systemGroupId }) => systemGroupId === SystemGroupColorsEnum['colors-colors-palette-loyalty-level-custom'],
    );

  const combinedPaletteColors = () => [
    ...(convertedPalettes(sortPaletteByOrder(palettes)) || []),
    ...(convertedPalettes(getDefaultsPaletteColors()) || []),
  ];

  useEffect(() => {
    if (!isBrandTab) {
      setPickersValues(convertedThemes());
    } else {
      setPickersValues(combinedPaletteColors());
    }
  }, [isBrandTab]);

  return {
    isBrandTab,
    toggleTab,
    pickersValues,
    tabItems,
  };
};
