import { FC, useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './Tabs.scss';

import { Tab } from 'components/shared/Tab/Tab';
import { IconEnums, BgShapeEnum } from 'common/IconEnums';
import { SizeEnums } from 'common/SizeEnums';
import { useScrollPosition } from 'hooks/tabs/use-scroll-position';
import { ActionButton } from 'components/shared/ActionButton/ActionButton';
import { TabsService } from 'services/TabsService';
import { useAppSelector } from 'store/hooks';
import classNames from 'classnames';
import { AppLinks } from 'router/uri';

interface Props {
  topTabsList: { link: string; tab: string }[];
  onBeforeTabChange?: () => Promise<boolean>;
}

export const Tabs: FC<Props> = ({ topTabsList, onBeforeTabChange }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabBGWidth, setTabBGWidth] = useState(0);
  const [tabBGLeft, setTabBGLeft] = useState(4);
  const tabsRef = useRef<HTMLDivElement[]>([]);
  const { tab, subtab, siteId } = useParams();
  const { pathname } = useLocation();
  const { brandSettings } = useAppSelector((state) => state.brands);

  const scrollableItemsElement = useRef<HTMLDivElement>(null);
  const [leftArrowVisible, setLeftArrowVisible] = useState(false);
  const [rightArrowVisible, setRightArrowVisible] = useState(false);

  // position tabs background according to selected tab
  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex < 0 ? 0 : activeTabIndex];
      setTabBGLeft(currentTab?.offsetLeft ?? 4);
      setTabBGWidth(currentTab?.offsetWidth ?? 0);
    }

    const delay = setTimeout(() => {
      setTabPosition();
    }, 100);

    window.addEventListener('resize', setTabPosition);

    return () => {
      clearTimeout(delay);
      window.removeEventListener('resize', setTabPosition);
    };
  }, [activeTabIndex, tabsRef, topTabsList]);

  // reset selected tab index when navigate back from sidebar or direct navigation
  useEffect(() => {
    setActiveTabIndex(topTabsList.findIndex((topTab) => TabsService.getTagSlugByName(topTab.tab) === tab));
  }, [tab, subtab, topTabsList]);

  // arrows functionality
  useScrollPosition(
    scrollableItemsElement,
    (positionKey) => {
      switch (positionKey) {
        case 'start':
          setLeftArrowVisible(false);
          break;
        case 'middle':
          setLeftArrowVisible(true);
          break;
        case 'scrollable':
          setRightArrowVisible(true);
          break;
        case 'end':
          setRightArrowVisible(false);
          break;
      }
    },
    [],
  );

  const arrowClickHandler = (scrollByPx: number) => {
    if (scrollableItemsElement?.current) {
      scrollableItemsElement.current.scrollBy({
        top: 0,
        left: scrollByPx,
        behavior: 'smooth',
      });
    }
  };

  const tabsClassName = classNames({
    'tabs-container': true,
    'tabs-container--scrollable': true,
    'tabs-container--no-brand': pathname === AppLinks.BRAND_SETTINGS && !brandSettings,
  });

  return (
    <>
      {leftArrowVisible && (
        <div className="left-arrow">
          <ActionButton
            iconName={IconEnums.ARROW1}
            onClickHandler={() => arrowClickHandler(-100)}
            iconSize={SizeEnums.LARGE}
            bgShape={BgShapeEnum.CIRCLE}
            bgColor="#DEE2E6"
          />
        </div>
      )}
      <div className={tabsClassName} ref={scrollableItemsElement}>
        <span className="tabs-container__active-bg" style={{ left: tabBGLeft, width: tabBGWidth }}></span>
        {topTabsList.map((tabObj, index: number) => (
          <div key={index} ref={(el) => (tabsRef.current[index] = el as HTMLDivElement)}>
            <Tab
              index={index}
              tab={tabObj.tab}
              setActiveTabIndex={setActiveTabIndex}
              to={tabObj.link
                .replace(':siteId', siteId ?? '')
                .replace(':tab', TabsService.getTagSlugByName(tabObj.tab))
                .replace('/:subtab', '')}
              className="tab"
              activeClassName="tab--active"
              onBeforeTabChange={onBeforeTabChange}
            />
          </div>
        ))}
      </div>
      {rightArrowVisible && (
        <div className="right-arrow">
          <ActionButton
            iconName={IconEnums.ARROW1}
            onClickHandler={() => arrowClickHandler(100)}
            iconSize={SizeEnums.LARGE}
            bgShape={BgShapeEnum.CIRCLE}
            rotateIconDegree={180}
            bgColor="#DEE2E6"
          />
        </div>
      )}
    </>
  );
};
