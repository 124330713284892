import { FC } from 'react';
import { useParams } from 'react-router-dom';
import './RightNavigationBar.scss';
import { Tab } from 'components/shared/Tab/Tab';
import { PageSubTab } from 'components/shared/TabbedPage/types';

interface Props {
  subTabsList: Omit<PageSubTab, 'component'>[];
  onBeforeTabChange?: () => Promise<boolean>;
}

export const RightNavigationBar: FC<Props> = ({ subTabsList, onBeforeTabChange }) => {
  const { tab, siteId } = useParams();

  return (
    <div className="right-navigation-bar">
      {subTabsList?.map((subtab, index: number) => (
        <div key={index} className="right-navigation-bar__link">
          {subtab.sectionHeader && <div className="sub-tab-section-header">{subtab.sectionHeader}</div>}
          <Tab
            index={index}
            tab={subtab.text}
            to={subtab.link
              .replace(':siteId', siteId ?? '')
              .replace(':tab', tab ? (tab.replace(/ /g, '-') as string) : '')
              .replace(':subtab', subtab.text.replace(/ /g, '-').toLowerCase())}
            className="sub-tab"
            activeClassName="sub-tab--active"
            icon={subtab.icon}
            onBeforeTabChange={onBeforeTabChange}
          />
        </div>
      ))}
    </div>
  );
};
