export enum BrandColorPickerTypesEnum {
  BACKGROUND = 'background',
  STROKE = 'stroke',
  TEXT = 'text',
  ICONS = 'icons',
}

export const radioItems = {
  defaultValue: 'brand',
  items: [
    {
      name: 'Brand',
      value: 'brand',
    },
    {
      name: 'Palette',
      value: 'palette',
    },
  ],
};
