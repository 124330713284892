import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config/endpoints';
import {
  CompanyGameForEditor,
  GetGameRequest,
  GetGameResponse,
  GetGames,
  GetGameSettings,
  GetGameSettingsResponse,
  GetGamesRequest,
  GetGamesResponse,
  GetNewGames,
  GetNewGamesRequest,
  GetNewGamesResponse,
  PatchGameStateRequest,
  GetGamesCategories,
  GetGamesCategoriesResponse,
  PostAddGameCategoryRequest,
  PostGameCategory,
  PostGameCategoryResponse,
  PutGameCategory,
  PutGameCategoryResponse,
  PutUpdateGameCategoryRequest,
  types,
  UpdateGameRequest,
  UpdateGameSettings,
  GetGamesCategoriesRequest,
  GetCategoryGroups,
  GetCategoryGroupsResponse,
  CreateCategoryGroup,
  PutCategoryGroups,
  GetCategoryGroup,
  PutArchiveGameCategoryRequest,
  PutGameSettingsResponse,
  GetCategoryGroupResponse,
  PatchGameCategory,
  PatchGameCategoryResponse,
  PatchUpdateGameCategory,
  DeleteGameCategoryResponse,
  DeleteGameCategory,
  DeleteGameCategoryRequest,
  GetGameSettingsRequest,
} from './types';
import { AsyncThunkParams, ViewTabOption } from '../app/types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchGames = createAsyncThunk<GetGames | null, AsyncThunkParams<GetGamesResponse, GetGamesRequest>>(
  types.GAMES_FETCH,
  async ({ execute, payload }) => {
    try {
      if (payload) {
        const params = new URLSearchParams('');

        if (payload.companyId) {
          params.append('companyId', payload.companyId.toString());
        }

        const addFilterValuesToRequest = (key: string, values?: number[]) => {
          if (values && values.length > 0) {
            values.forEach((item, index) => {
              params.append(`filter[${key}][${index}]`, item.toString());
            });
          }
        };

        const addCombinationsToRequest = (values: (ViewTabOption | null | undefined)[]) => {
          values.forEach((item, index) => {
            if (item) {
              params.append(`combinations[${index}][siteId]`, item?.siteId.toString());
              params.append(`combinations[${index}][regulationId]`, item?.regulationId.toString());
            }
          });
        };

        const addSortParamsToRequest = (key: string, values?: string) =>
          values && params.append(`${key}`, values.toString());

        addFilterValuesToRequest('statusIds', payload.statusIds);
        addFilterValuesToRequest('platformIds', payload.platformIds);
        addFilterValuesToRequest('regulationIds', payload.regulationIds);
        addCombinationsToRequest([
          payload.regulationsIds,
          payload.countriesIds,
          payload.demoMode,
          payload.platformsIds,
        ]);

        addSortParamsToRequest('orderBy', payload.orderBy);
        addSortParamsToRequest('orderDirection', payload.orderDirection);
        addSortParamsToRequest('companyInstance', payload.companyInstance);
        addSortParamsToRequest('filter[search]', payload.searchInput);

        const urlParams = params.toString() ? `?${params.toString()}` : '';
        const url = `${endpoints.casinoGamesGetGames}${urlParams}`;
        const result = await execute('GET', url);
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.GAMES_FETCH, err);
      return null;
    }
  },
);

export const fetchGameData = createAsyncThunk<
  CompanyGameForEditor | null,
  AsyncThunkParams<GetGameResponse, GetGameRequest>
>(types.GAME_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams({
        companyId: payload.companyId ? payload.companyId.toString() : '',
      });

      if (payload.companyInstance) {
        params.append('companyInstance', payload.companyInstance.toString());
      }

      const url = `${endpoints.casinoGame.replace(
        ':companyGameId',
        String(payload.companyGameId),
      )}?${params.toString()}`;

      const result = await execute('GET', url);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.GAME_FETCH, err);
    return null;
  }
});

export const fetchGameSettings = createAsyncThunk<
  GetGameSettings | null,
  AsyncThunkParams<GetGameSettingsResponse, GetGameSettingsRequest>
>(types.GAME_SETTINGS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams({
        companyId: payload.companyId ? payload.companyId.toString() : '',
        companyGameId: payload.companyGameId.toString(),
      });

      if (payload.companyInstance) {
        params.append('companyInstance', payload.companyInstance.toString());
      }

      const url = `${endpoints.casinoGameGetGameSettings}?${params.toString()}`;

      const result = await execute('GET', url);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.GAME_SETTINGS_FETCH, err);
    return null;
  }
});

export const updateGameSettings = createAsyncThunk<
  void | null,
  AsyncThunkParams<PutGameSettingsResponse, UpdateGameSettings>
>(types.GAME_SETTINGS_UPDATE, async ({ payload, execute }) => {
  try {
    if (payload) {
      const result = await execute('PUT', endpoints.casinoGameUpdateGameSettings, payload);
      return BackendResponseService.getBackendResponseData(result);
    }
  } catch (err) {
    console.error(types.GAME_SETTINGS_UPDATE, err);
    return null;
  }
});

export const updateGameData = createAsyncThunk<void | null, AsyncThunkParams<undefined, UpdateGameRequest>>(
  types.GAME_UPDATE,
  async ({ payload, execute }) => {
    try {
      if (payload) {
        const url = endpoints.casinoGame.replace(':companyGameId', String(payload.companyGameId));

        await execute('PUT', url, payload);
      }
    } catch (err) {
      console.error(types.GAME_UPDATE, err);
      return null;
    }
  },
);

export const fetchNewGames = createAsyncThunk<
  GetNewGames | null,
  AsyncThunkParams<GetNewGamesResponse, GetNewGamesRequest>
>(types.NEW_GAMES_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams({
        companyId: payload.companyId.toString(),
      });

      // optional search param
      if (payload.searchInput) {
        params.append('search', payload.searchInput.toString());
      }

      const url = `${endpoints.casinoGamesGetNewGames}?${params.toString()}`;
      const result = await execute('GET', url);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.NEW_GAMES_FETCH, err);
    return null;
  }
});

export const updateGameState = createAsyncThunk<void | null, AsyncThunkParams<void, PatchGameStateRequest>>(
  types.UPDATE_GAME_STATE,
  async ({ execute, payload }) => {
    try {
      if (payload) {
        await execute('PATCH', endpoints.casinoGamesUpdateGameState, payload);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.UPDATE_GAME_STATE, err);
      return null;
    }
  },
);

export const fetchGamesCategories = createAsyncThunk<
  GetGamesCategories | null,
  AsyncThunkParams<GetGamesCategoriesResponse, GetGamesCategoriesRequest>
>(types.GAMES_CATEGORIES_FETCH, async ({ execute, payload }) => {
  if (!payload) {
    console.error('fetchGamesCategories() - payload is not defined');
    return null;
  }
  try {
    const params = new URLSearchParams('');

    if (payload.companyId) {
      params.append('companyId', payload.companyId.toString());
    }

    if (payload.companyInstance) {
      params.append('companyInstance', payload.companyInstance.toString());
    }

    const result = await execute('GET', `${endpoints.casinoGamesGetGamesCategories}?${params.toString()}`);

    return BackendResponseService.getBackendResponseData(result);
  } catch (err) {
    console.error(types.GAMES_CATEGORIES_FETCH, err);
    return null;
  }
});

export const postGameCategory = createAsyncThunk<
  PostGameCategory | null,
  AsyncThunkParams<PostGameCategoryResponse, PostAddGameCategoryRequest>
>(types.GAMES_CATEGORIES_POST, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('POST', `${endpoints.casinoGamesPostGameCategory}`, payload);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.GAMES_CATEGORIES_POST, err);
    throw err;
  }
});

export const putGameCategory = createAsyncThunk<
  PutGameCategory | null,
  AsyncThunkParams<PutGameCategoryResponse, PutUpdateGameCategoryRequest | PutArchiveGameCategoryRequest>
>(types.GAMES_CATEGORIES_PUT, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute(
        'PUT',
        `${endpoints.casinoGamesPutGameCategory.replace(':categoryId', payload.categoryId?.toString() as string)}`,
        payload,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.GAMES_CATEGORIES_PUT, err);
    throw err;
  }
});

export const updateCategoryContent = createAsyncThunk<
  PatchGameCategory | null,
  AsyncThunkParams<PatchGameCategoryResponse, PatchUpdateGameCategory>
>(types.GAMES_CATEGORIES_PATCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute(
        'PATCH',
        `${endpoints.casinoGamesPatchGameCategory.replace(':categoryId', payload.categoryId?.toString() as string)}`,
        payload,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.GAMES_CATEGORIES_PATCH, err);
    throw err;
  }
});

export const deleteGameCategory = createAsyncThunk<
  DeleteGameCategory | null,
  AsyncThunkParams<DeleteGameCategoryResponse, DeleteGameCategoryRequest>
>(types.GAMES_CATEGORIES_DELETE, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute(
        'DELETE',
        `${endpoints.casinoGamesDeleteGameCategory.replace(':categoryId', payload.categoryId?.toString() as string)}`,
        payload,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.GAMES_CATEGORIES_DELETE, err);
    throw err;
  }
});

export const fetchCategoryGroups = createAsyncThunk<
  GetCategoryGroups | null,
  AsyncThunkParams<GetCategoryGroupsResponse, { companyId: number; search?: string }>
>(types.CATEGORY_GROUPS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute(
        'GET',
        `${endpoints.casinoGamesGetCategoryGroups}?companyId=${payload.companyId}&search=${payload.search || ''}`,
      );

      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.CATEGORY_GROUPS_FETCH, err);
    return null;
  }
});

export const fetchCategoryGroup = createAsyncThunk<
  GetCategoryGroup | null,
  AsyncThunkParams<GetCategoryGroupResponse, { companyId: number; categoryGroupId: number }>
>(types.CATEGORY_GROUP_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute(
        'GET',
        `${endpoints.casinoGamesGetCategoryGroup.replace(
          ':categoryGroupId',
          payload.categoryGroupId.toString(),
        )}?companyId=${payload.companyId}`,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.CATEGORY_GROUP_FETCH, err);
    return null;
  }
});

export const postCategoryGroup = createAsyncThunk<void | null, AsyncThunkParams<undefined, CreateCategoryGroup>>(
  types.CATEGORY_GROUP_POST,
  async ({ execute, payload }) => {
    if (payload) {
      try {
        await execute('POST', `${endpoints.casinoGamesPostCategoryGroup}`, payload);
      } catch (err) {
        console.error(types.CATEGORY_GROUP_POST, err);
        return null;
      }
    }
  },
);

export const putCategoryGroups = createAsyncThunk<void | null, AsyncThunkParams<undefined, PutCategoryGroups>>(
  types.CATEGORY_GROUP_PUT,
  async ({ execute, payload }) => {
    if (payload) {
      try {
        await execute(
          'PUT',
          `${endpoints.casinoGamesPutCategoryGroup.replace(':categoryGroupId', payload.categoryGroupId.toString())}`,
          payload,
        );
      } catch (err) {
        console.error(types.CATEGORY_GROUP_PUT, err);
        return null;
      }
    }
  },
);

export const deleteCategoryGroup = createAsyncThunk<void | null, AsyncThunkParams<void, { categoryGroupId: number }>>(
  types.CATEGORY_GROUP_DELETE,
  async ({ execute, payload }) => {
    if (payload) {
      try {
        await execute(
          'DELETE',
          `${endpoints.casinoGamesDeleteCategoryGroup.replace(':categoryGroupId', payload.categoryGroupId.toString())}`,
        );
      } catch (err) {
        console.error(types.CATEGORY_GROUP_DELETE, err);
        return null;
      }
    }
  },
);
