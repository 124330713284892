import { FC } from 'react';
import './BrandsBlock.scss';
import { Icon } from 'components/shared/Icon/Icon';
import { IconEnums } from 'common/IconEnums';
import { Brand } from 'store/brands/types';
import { BrandItem } from '../BrandItem/BrandItem';
import classNames from 'classnames';

interface Props {
  brands?: Brand[] | null;
  isNetworkUser: boolean;
  toggleBrandsList: () => void;
  isMinimize: boolean;
  isBrandsList: boolean;
  currentSiteId: number | null;
  isHovered: boolean;
  onClick: (siteId: number) => void;
}

export const BrandsBlock: FC<Props> = ({
  brands,
  isNetworkUser,
  toggleBrandsList,
  isMinimize,
  isBrandsList,
  currentSiteId,
  isHovered,
  onClick,
}) => {
  const brandsBlockClassName = classNames({
    'brands-block': true,
    'brands-block--minimize': isMinimize,
    'brands-block--minimize--hover': isHovered,
  });

  return (
    <div className={brandsBlockClassName}>
      {brands &&
        !currentSiteId &&
        brands.map(({ siteId, brandName, brandType, brandTemplate, assets }) => (
          <BrandItem
            key={brandName}
            siteId={siteId}
            brandName={brandName}
            brandType={brandType}
            brandTemplate={brandTemplate}
            isMinimize={isMinimize}
            isHovered={isHovered}
            asset={assets?.favicon?.big?.brandAssetFileUrl}
            className="brands-block-item"
            onClick={onClick}
          />
        ))}

      {isNetworkUser && !currentSiteId && (
        <div
          className={classNames('brands-block-cta', isBrandsList && 'brands-block-cta--active')}
          onClick={toggleBrandsList}
          role="presentation"
          title="Select brand"
        >
          <Icon iconName={isBrandsList ? IconEnums.NAV_BRANDS_ACTIVE : IconEnums.NAV_BRANDS} />
          <div className="brands-block-cta__text">Brands</div>
        </div>
      )}
    </div>
  );
};
