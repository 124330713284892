import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BrandSettings, BrandsState, GetBrands } from './types';
import { fetchBrands, fetchBrandSettings } from './actions';

const initialState: BrandsState = {
  brands: null,
  brandSettings: null,
};

export const brandsSlice = createSlice({
  name: 'brandsUsers',
  initialState,
  reducers: {
    clearBrandsState(state) {
      state.brands = null;
    },
    clearBrandSettingsState(state) {
      state.brandSettings = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrands.fulfilled, (state: BrandsState, action: PayloadAction<GetBrands | null>) => {
      if (action.payload !== null) {
        state.brands = action.payload;
      }
    });

    builder.addCase(fetchBrandSettings.fulfilled, (state: BrandsState, action: PayloadAction<BrandSettings | null>) => {
      if (action.payload !== null) {
        state.brandSettings = action.payload;
      }
    });
  },
});
