export enum DefaultThemeRowsNamesEnum {
  PRIMARY_COLOR = 'Primary color',
  SECONDARY_COLOR = 'Secondary color',
  TERTIARY_COLOR = 'Tertiary color',
}

export const defaultThemeRowsNames = [
  DefaultThemeRowsNamesEnum.PRIMARY_COLOR,
  DefaultThemeRowsNamesEnum.SECONDARY_COLOR,
  DefaultThemeRowsNamesEnum.TERTIARY_COLOR,
];
