import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config/endpoints';
import {
  GetLicensesFields,
  GetLicensesResponse,
  GetRegulatedFeaturesFields,
  GetRegulatedFeaturesRequest,
  GetRegulatedFeaturesResponse,
  PutLicensesRequest,
  PutLicensesResponse,
  PutRegulatedFeatureRequest,
  PutRegulatedFeaturesResponse,
  types,
} from './types';
import { AsyncThunkParams } from '../app/types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchLicenses = createAsyncThunk<GetLicensesFields | null, AsyncThunkParams<GetLicensesResponse>>(
  types.LICENSES_PROPS_FETCH,
  async (props) => {
    try {
      const result = await props.execute('GET', endpoints.licenses);
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.LICENSES_PROPS_FETCH, err);
      return null;
    }
  },
);

export const putLicenses = createAsyncThunk<boolean | null, AsyncThunkParams<PutLicensesResponse, PutLicensesRequest>>(
  types.LICENSES_PROPS_PUT,
  async ({ execute, payload }) => {
    try {
      const result = await execute('PUT', endpoints.licenses, payload);
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.LICENSES_PROPS_PUT, err);
      throw err;
    }
  },
);

export const fetchRegulatedFeatures = createAsyncThunk<
  GetRegulatedFeaturesFields | null,
  AsyncThunkParams<GetRegulatedFeaturesResponse, GetRegulatedFeaturesRequest>
>(types.REGULATED_FEATURES_PROPS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams('');

      // optional companyId param
      if (payload.companyId) {
        params.append('companyId', payload.companyId.toString());
      }

      const urlParams = params.toString() ? `?${params.toString()}` : '';
      const url = `${endpoints.regulationPropertiesRegulatedFeatures}${urlParams}`;

      const result = await execute('GET', url);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.REGULATED_FEATURES_PROPS_FETCH, err);
    return null;
  }
});

export const putRegulatedFeature = createAsyncThunk<
  boolean | null,
  AsyncThunkParams<PutRegulatedFeaturesResponse, PutRegulatedFeatureRequest>
>(types.REGULATED_FEATURES_PROPS_PUT, async ({ execute, payload }) => {
  try {
    if (payload) {
      const url = endpoints.regulationPropertiesRegulatedFeatures;

      const result = await execute('PUT', url, payload);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      return null;
    }
  } catch (err) {
    console.error(types.REGULATED_FEATURES_PROPS_PUT, err);
    throw err;
  }
});
