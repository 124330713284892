import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { NotificationContext } from 'contexts/Notifications/notification.context';
import { createPortal } from 'react-dom';
import { Notification } from 'components/shared/Notification/Notification';
import { NoInternetNotification } from '../NoInternetNotification/NoInternetNotification';
import { useToggle } from 'hooks/use-toggle';
import { MajorLoadingNotification } from '../MajorLoadingNotification/MajorLoadingNotification';
import { SystemNotification, SystemNotificationProps } from '../SystemNotification/SystemNotification';
import { NotificationContextActions, SaveNotification } from './types';
import { NotificationWrapper } from '../NotificationWrapper/NotificationWrapper';
import { useLocation } from 'react-router-dom';

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notification, setNotification] = useState<SaveNotification | null>(null);
  const [isSystemNotification, setSystemNotification] = useState<SystemNotificationProps | null>(null);
  const [isInternet, toggleIsInternet] = useToggle(true);
  const [isMajorLoading, toggleMajorLoading] = useToggle(false);
  const location = useLocation();

  useEffect(() => {
    close();
  }, [location]);

  const open = ({ message, type, delay }: SaveNotification): void =>
    setNotification((prevState) => ({
      ...prevState,
      message,
      type,
      delay,
    }));
  const close = (): void => setNotification(null);

  const noInternet = (): void => toggleIsInternet();

  const majorLoading = (): void => toggleMajorLoading();

  const systemNotification = ({
    title,
    subtitle,
    confirmButtonText,
    cancelButtonText,
    onCancel,
    onConfirm,
  }: SystemNotificationProps): void =>
    setSystemNotification((prevState) => ({
      ...prevState,
      title,
      subtitle,
      confirmButtonText,
      cancelButtonText,
      onConfirm,
      onCancel,
    }));

  const closeSystemNotification = () => {
    setSystemNotification(null);
  };

  const contextValue = useMemo(
    (): NotificationContextActions => ({
      open,
      noInternet,
      majorLoading,
      systemNotification,
      closeSystemNotification,
    }),
    [],
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      {notification &&
        createPortal(
          <Notification
            type={notification.type}
            message={notification.message}
            onClick={close}
            delay={notification.delay}
          />,
          document.body,
        )}

      {isSystemNotification &&
        createPortal(
          <NotificationWrapper>
            <SystemNotification
              onConfirm={isSystemNotification.onConfirm}
              confirmButtonText={isSystemNotification.confirmButtonText}
              cancelButtonText={isSystemNotification.cancelButtonText}
              onCancel={isSystemNotification.onCancel}
              subtitle={isSystemNotification.subtitle}
              title={isSystemNotification.title}
            />
          </NotificationWrapper>,
          document.body,
        )}

      {!isInternet &&
        createPortal(
          <NotificationWrapper>
            <NoInternetNotification />
          </NotificationWrapper>,
          document.body,
        )}

      {isMajorLoading &&
        createPortal(
          <NotificationWrapper>
            <MajorLoadingNotification seconds={59} />
          </NotificationWrapper>,
          document.body,
        )}
    </NotificationContext.Provider>
  );
};
