import './NavbarUserMenu.scss';
import { FC } from 'react';
import { CmsUserMenu } from '../CmsUserMenu/CmsUserMenu';
import classNames from 'classnames';
import { Initials } from '../Initials/Initials';
import { useUserMenuProps } from 'hooks/use-user-menu-props';
import { createPortal } from 'react-dom';
import { GLOBAL_POPUP_CONTAINER } from 'config/portals';

interface Props {
  isMinimize: boolean;
  className?: string;
}

export const NavbarUserMenu: FC<Props> = ({ isMinimize, className = '' }) => {
  const { menuItems, isMenuOpen, menuToggle, user, userName, build } = useUserMenuProps();

  const containerClassName = classNames({
    'user-initials-container': true,
    'user-initials-container--mini': isMinimize,
    [className]: className,
  });

  return (
    <div className={containerClassName} onClick={menuToggle} role="presentation">
      <div className="user-initials-info">
        <Initials userName={user?.userName || ''} className="user-initials-info__avatar" />
        <div className="user-initials-info__text">{userName()}</div>
      </div>

      {isMenuOpen &&
        createPortal(
          <CmsUserMenu user={user} toggleMenu={menuToggle} menuList={menuItems} version={build} />,
          document.getElementById(GLOBAL_POPUP_CONTAINER) as HTMLElement,
        )}
    </div>
  );
};
