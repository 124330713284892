import { useEffect } from 'react';
import { useAppActions, useAppDispatch } from 'store/hooks';
import { useFetchWithMsal } from 'hooks/use-fetch-with-msal';
import { useParams } from 'react-router-dom';

export const useFetchPickersColors = () => {
  const { getBrandColorsPalette, getBrandColorsThemes } = useAppActions();
  const dispatch = useAppDispatch();
  const { execute } = useFetchWithMsal();
  const { siteId = '' } = useParams();

  useEffect(() => {
    dispatch(
      getBrandColorsThemes({
        execute,
        payload: {
          siteId: Number(siteId),
        },
      }),
    );

    dispatch(
      getBrandColorsPalette({
        execute,
        payload: {
          siteId: Number(siteId),
        },
      }),
    );
  }, [execute]);
};
