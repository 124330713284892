import { BackendResponse, Language, TranslatableField } from '../app/types';
import { MenuItemTypeEnums } from '../../common/MenuItemTypeEnums';
import { MenusGuardsEnum } from '../../auth/enums/menus-guards.enum';

export const types = Object.freeze({
  MENUS_FETCH: '[Menus] MENUS_FETCH',
  MENUS_PUT: '[Menus] MENUS_PUT',
  MENUS_GUARDS_FETCH: '[Menus] MENUS_GUARDS_FETCH',
});

export type MenusState = {
  menus: GetMenusFields | null;
  menusGuards: GetMenusGuardsFields | null;
};

export type GetMenusResponse = BackendResponse<GetMenusFields>;
export type PutMenusResponse = BackendResponse<PutMenusFields>;
export type GetMenusGuardsResponse = BackendResponse<GetMenusGuardsFields>;

export type GetMenusFields = {
  menuItems: MenusItem[];
  categoryGroups: CategoryGroup[];
  menuItemTypes: MenusItemTypes[];
  translatableFieldLanguages: Language[];
};

export type GetMenusGuardsFields = {
  [MenusGuardsEnum.REGULATIONS]: boolean;
  [MenusGuardsEnum.CASINO_GAMES]: boolean;
};

export type MenusItem = {
  menuItemId: number;
  menuTypeId: number;
  menuTypeName: string;
  menuItemTypeId: MenuItemTypeEnums;
  menuItemTypeName: string;
  siteId: number;
  menuItemOrder: number;
  loginStatus: number | null;
  categoryGroupId: number | null;
  categoryGroupName: string | null;
  title: TranslatableField | null;
  brandAssetId: number | null;
  brandAssetFileUrl: string | null;
  brandAssetFileThumbnailUrl: string | null;
  brandAssetSelectedId: number;
  brandAssetSelectedFileUrl: string;
  brandAssetSelectedFileThumbnailUrl: string;
  menuItemLink: string | null;
};

export type CategoryGroup = {
  categoryGroupId: number;
  categoryGroupName: string;
  companyId: number;
};

export type MenusItemTypes = {
  menuItemTypeId: MenuItemTypeEnums;
  menuItemTypeName: string;
};

export type GetMenuItemsRequest = {
  siteId: number;
  menuType: number;
};

export type PutMenuItemsRequest = {
  menuItems: PutMenuItem[];
  deletedMenuItemsIds: number[];
};

export type PutMenuItem = {
  menuItemId?: MenusItem['menuItemId'];
  menuItemLink?: MenusItem['menuItemLink'];
  brandAssetId?: MenusItem['brandAssetId'];
  brandAssetSelectedId?: MenusItem['brandAssetSelectedId'];
  loginStatus?: MenusItem['loginStatus'];
} & Pick<MenusItem, 'menuTypeId' | 'menuItemTypeId' | 'siteId' | 'menuItemOrder' | 'categoryGroupId' | 'title'>;

export type PutMenusFields = {
  updatedMenuItems: number;
};
