import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetMenusFields, GetMenusGuardsFields, MenusState } from './types';
import { fetchMenus, fetchMenusGuards } from './actions';

const initialState: MenusState = {
  menus: null,
  menusGuards: null,
};

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    clearMenusState(state) {
      state.menus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenus.fulfilled, (state: MenusState, action: PayloadAction<GetMenusFields | null>) => {
      if (action.payload !== null) {
        state.menus = action.payload;
      }
    });
    builder.addCase(
      fetchMenusGuards.fulfilled,
      (state: MenusState, action: PayloadAction<GetMenusGuardsFields | null>) => {
        if (action.payload !== null) {
          state.menusGuards = action.payload;
        }
      },
    );
  },
});
