import { useToggle } from './use-toggle';
import { useAppSelector } from '../store/hooks';
import { useLoginActions } from './use-login-actions';
import { menuDefaultItems, UserMenuText } from '../components/shared/NavbarUserMenu/types';
import { useCallback } from 'react';

export const useUserMenuProps = () => {
  const [isMenuOpen, menuToggle] = useToggle(false);
  const { user, build } = useAppSelector((state) => state.app);

  const { logout } = useLoginActions();

  const menuItems = menuDefaultItems.map((menuItemProps) => {
    if (menuItemProps.text === UserMenuText.LOGOUT) {
      menuItemProps.onClick = () => {
        logout();
      };
    }
    return menuItemProps;
  });

  const userName = useCallback(() => {
    if (user && user.userName.length > 14) {
      return `${user.userName.split(' ')[0]} ${user.userName.split(' ')[1][0]}`;
    }

    return user?.userName;
  }, [user?.userName]);

  return {
    isMenuOpen,
    user,
    menuItems,
    menuToggle,
    userName,
    build,
  };
};
