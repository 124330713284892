import { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { TabsService } from 'services/TabsService';

export function useNavigateDefaultRoute(baseRute: string, defaultRoute: string) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const baseWithParameters = Object.keys(params).reduce((acc, key) => {
    return acc.replace(`:${key}`, params[key] as string);
  }, baseRute);

  useEffect(() => {
    if (location.pathname === baseWithParameters) {
      navigate(`${location.pathname}/${TabsService.getTagSlugByName(defaultRoute)}`);
    }
  }, [location]);
}
