import { FC } from 'react';
import './ContentColorPickers.scss';
import { IconEnums } from 'common/IconEnums';
import { useContentPickersProps } from '../hooks/use-content-pickers-props';
import { RadioButtonsPure } from 'components/shared/RadioButtons/RadioButtonsPure';
import { RadioTypes } from 'components/shared/RadioButtons/types';
import { ThemeRowPickers } from '../Colors/ThemeRow/types';
import { ContentColorPickerItem } from '../ContentColorPickerItem/ContentColorPickerItem';

interface Props {
  icon: IconEnums;
  type: ThemeRowPickers;
  setColors: (defaultColor: string) => void;
  isDarkMode?: boolean;
}

export const ContentColorPickers: FC<Props> = ({ icon, type, setColors, isDarkMode }) => {
  const { isBrandTab, toggleTab, pickersValues, tabItems } = useContentPickersProps(type);

  return (
    <>
      <RadioButtonsPure
        name={''}
        checkedValue={isBrandTab}
        type={RadioTypes.LISTED}
        items={tabItems}
        onValueChange={toggleTab}
        className="content-color-pickers__tabs"
        buttonClassName="content-color-pickers__tabs__item"
      />
      <div className="content-color-pickers">
        {pickersValues?.map(({ brandColorValue, brandColorName }) => (
          <ContentColorPickerItem
            key={brandColorValue + brandColorName}
            brandColorName={brandColorName}
            brandColorValue={brandColorValue}
            isDarkMode={isDarkMode}
            icon={icon}
            setColors={setColors}
          />
        ))}
      </div>
    </>
  );
};
