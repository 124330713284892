import './AssetsModal.scss';
import { ChangeEventHandler, FC, MouseEventHandler, PropsWithChildren, useRef } from 'react';
import { Modal } from 'components/shared/Modal/Modal';
import classNames from 'classnames';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { AssetsRadioButtons, RadioItems } from 'components/modules/Assets/AssetsRadioButtons/AssetsRadioButtons';
import { AssetsModesEnum, UploadRadioItemsI } from '../UploadModal/UploadModal/types';
import { useAppSelector } from 'store/hooks';

interface Props extends PropsWithChildren {
  radioItems?: RadioItems | UploadRadioItemsI;
  className?: string;
  toggleModal: MouseEventHandler<HTMLElement>;
  activeTab?: string | AssetsModesEnum;
  handleChangeTab?: ChangeEventHandler<HTMLInputElement>;
  defaultImage?: string;
  darkModeImage?: string;
}

export const AssetsModal: FC<Props> = ({
  children,
  radioItems,
  className,
  toggleModal,
  activeTab,
  handleChangeTab = () => {
    return;
  },
  defaultImage,
  darkModeImage,
}) => {
  const { isDarkMode } = useAppSelector((state) => state.app);
  const ref = useRef(null);
  useOnClickOutside(ref, toggleModal);

  return (
    <Modal className={classNames('assets-modal', className)} forwardedRef={ref}>
      {radioItems && isDarkMode && (
        <AssetsRadioButtons
          radioItems={radioItems}
          selectedOption={activeTab}
          handleChangeTab={handleChangeTab}
          defaultImage={defaultImage}
          darkModeImage={darkModeImage}
        />
      )}
      {children}
    </Modal>
  );
};
