import { useAppActions, useAppDispatch, useAppSelector } from '../store/hooks';
import { useFetchWithMsal } from './use-fetch-with-msal';
import { useEffect } from 'react';
import { PutUpdateSession, PutUpdateSessionRequest } from '../store/app/types';
import { useIsFirstRender } from './use-is-first-render';
import { useCurrentModulePath } from './use-current-module-path';

export const useSaveSessionData = () => {
  const dispatch = useAppDispatch();
  const { execute } = useFetchWithMsal();
  const { updateUserSession } = useAppActions();
  const { isMinimizeMode, session, siteId } = useAppSelector((state) => state.app);
  const currentSession = session as PutUpdateSession;
  const isFirstRender = useIsFirstRender();
  const { modulePath } = useCurrentModulePath();

  const updateSession = async (payload: PutUpdateSessionRequest) => {
    await dispatch(updateUserSession({ execute, payload }));
  };

  useEffect(() => {
    if (!isFirstRender && currentSession) {
      const newSession = {
        userSessionId: currentSession.userSessionId,
        userSessionValue: {
          ...currentSession.userSessionValue,
          isMinimizeMode,
          currentSiteId: siteId,
          pathname: modulePath,
        },
      };
      const isChanged = JSON.stringify(newSession) !== JSON.stringify(currentSession);
      if (isChanged) {
        updateSession(newSession);
      }
    }
  }, [isMinimizeMode, execute, siteId, session, modulePath]);
};
