import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAppSelector } from 'store/hooks';
import { loginRequest } from 'config/auth';
import { JwtService } from '../services/JwtService';
import { IPublicClientApplication } from '@azure/msal-browser';

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @returns
 */
export const useFetchWithMsal = () => {
  const { instance } = useMsal();
  const { apiUrl } = useAppSelector((state) => state.app);

  const doRequest = async (token: string, method: string, endpoint: string, dataProps: object | null = null) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const isFormData = dataProps instanceof FormData;
    headers.append('Authorization', bearer);

    // content type is not required for sending files - browser will generate correct content type automatically
    if (dataProps && !isFormData) {
      headers.append('Content-Type', 'application/json');
    }

    let body = null;
    // pass formData as is, otherwise stringify JSON
    if (dataProps && isFormData) {
      body = dataProps;
    } else if (dataProps) {
      body = JSON.stringify(dataProps);
    }

    const options = {
      method,
      headers,
      body,
    };

    const url = `${apiUrl}/${endpoint}`;

    return fetch(url, options);
  };

  /**
   * Execute a fetch request with the given options
   */
  const execute = async (method: string, endpoint: string, dataProps: object | null = null) => {
    const token = await getToken(instance);

    if (token) {
      try {
        const response = await doRequest(token, method, endpoint, dataProps);

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        if (response.headers.get('Content-Type')?.includes('application/json')) {
          return await response.json();
        }

        return true;
      } catch (e) {
        throw e;
      }
    }
  };

  return {
    execute: useCallback(execute, []), // to avoid infinite calls when inside a `useEffect`
  };
};

/**
 * Get token string either vie acquireToken() or from a login result
 */
async function getToken(instance: IPublicClientApplication) {
  const tokenSilentRequest = {
    account: instance.getActiveAccount() || undefined,
    ...loginRequest,
  };
  const tokenResponse = await instance.acquireTokenSilent(tokenSilentRequest);

  const token = tokenResponse ? tokenResponse.idToken : null;

  // Due to bug in MSAL, token might be expired, in this case force refresh required to cache fresh token
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
  if (!token || JwtService.isTokenExpired(token)) {
    // attempt to force refresh the token
    const tokenResponseForce = await instance.acquireTokenSilent({
      ...tokenSilentRequest,
      forceRefresh: true,
    });

    return tokenResponseForce ? tokenResponseForce.idToken : null;
  }

  return token;
}
