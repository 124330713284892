import { ChangeEventHandler, FC } from 'react';
import './AssetsRadioButtons.scss';
import classNames from 'classnames';
import { AssetsModesEnum, UploadRadioItemsI } from '../Modals/UploadModal/UploadModal/types';

export interface RadioItems {
  defaultValue: string;
  items: {
    name: string;
    value: string;
  }[];
}

interface Props {
  radioItems?: RadioItems | UploadRadioItemsI;
  selectedOption?: string | AssetsModesEnum;
  handleChangeTab: ChangeEventHandler<HTMLInputElement>;
  defaultImage?: string | null;
  darkModeImage?: string | null;
}

export const AssetsRadioButtons: FC<Props> = ({
  radioItems,
  selectedOption,
  handleChangeTab,
  defaultImage,
  darkModeImage,
}) => {
  const isDotAvailable = (selectedOption === AssetsModesEnum.DEFAULT_MODE ? darkModeImage : defaultImage) === '';

  return (
    <div className="assets-radio-buttons">
      {radioItems?.items.map(({ name, value }, index) => (
        <label
          key={index}
          className={classNames('radio-button-label', selectedOption === value && 'radio-button-label--checked')}
        >
          <input
            type="radio"
            className="radio-button-input"
            checked={selectedOption === value}
            value={value}
            onChange={handleChangeTab}
          />
          {name}
          {selectedOption !== value && isDotAvailable && (
            <div className="radio-button-label__notification-circle"></div>
          )}
        </label>
      ))}
    </div>
  );
};
