import { useAppActions, useAppDispatch, useAppSelector } from '../store/hooks';
import { CompanyTypeEnum, PutUpdateSession } from '../store/app/types';
import { useEffect } from 'react';
import { useHover } from './use-hover';
import { useFetchWithMsal } from './use-fetch-with-msal';
import { useChangeInstances } from './use-change-instances';

export const useNavigationSidebarProps = (isChunk?: boolean) => {
  const [hoverRef, isHovered] = useHover();
  const { user, isMinimizeMode, isHoveredMode, isMenuAvailable, isBrandsList, siteId, session } = useAppSelector(
    (state) => state.app,
  );
  const { brands } = useAppSelector((state) => state.brands);
  const dispatch = useAppDispatch();
  const { fetchBrandsFull } = useAppActions();
  const { execute } = useFetchWithMsal();
  const { instanceMenuItems, currentInstanceIcon, currentInstanceUrl, currentInstanceName } = useChangeInstances();
  const currentSession = session as PutUpdateSession;

  const isNetworkUser = user?.companyType === CompanyTypeEnum.NETWORK;

  const isMinimizeHoverMode = isMinimizeMode && isHovered && isHoveredMode;

  useEffect(() => {
    if (!isChunk && user) {
      dispatch(
        fetchBrandsFull({
          execute,
          payload: {
            companyId: isNetworkUser ? null : user.companyId,
          },
        }),
      );
    }
  }, [execute, user]);

  const currentBrand = brands?.find((brand) => brand.siteId === siteId);

  const pinnedBrands = () => {
    if (isNetworkUser && currentSession.userSessionValue.pinnedBrands) {
      return brands?.filter((brand) => currentSession.userSessionValue.pinnedBrands?.includes(brand.siteId));
    }
  };

  const menuBrands = isNetworkUser ? pinnedBrands() : brands;

  const isOneCustomerBrand = brands && brands?.length === 1 && !isNetworkUser;

  return {
    isBrandsList,
    brands,
    menuBrands,
    siteId,
    isNetworkUser,
    isMinimizeMode,
    isMenuAvailable,
    isMinimizeHoverMode,
    hoverRef,
    currentInstanceIcon,
    currentInstanceUrl,
    currentInstanceName,
    instanceMenuItems,
    currentBrand,
    isOneCustomerBrand,
  };
};
