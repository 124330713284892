import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpoints } from 'config/endpoints';
import {
  GetMenuItemsRequest,
  GetMenusFields,
  GetMenusGuardsFields,
  GetMenusGuardsResponse,
  GetMenusResponse,
  PutMenuItemsRequest,
  PutMenusFields,
  PutMenusResponse,
  types,
} from './types';
import { AsyncThunkParams } from '../app/types';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchMenus = createAsyncThunk<
  GetMenusFields | null,
  AsyncThunkParams<GetMenusResponse, GetMenuItemsRequest>
>(types.MENUS_FETCH, async ({ execute, payload }) => {
  try {
    if (payload) {
      const params = new URLSearchParams('');

      params.append('menuType', payload.menuType.toString());

      const result = await execute(
        'GET',
        `${endpoints.menusGetMenuItems.replace(':siteId', payload.siteId?.toString() as string)}?${params}`,
      );
      return BackendResponseService.getBackendResponseData(result);
    } else {
      console.error('fetchMenus() - payload is not defined');
      return null;
    }
  } catch (err) {
    console.error(types.MENUS_FETCH, err);
    return null;
  }
});

export const putAddMenuItem = createAsyncThunk<
  PutMenusFields | null,
  AsyncThunkParams<PutMenusResponse, PutMenuItemsRequest>
>(types.MENUS_PUT, async ({ execute, payload }) => {
  try {
    if (payload) {
      const result = await execute('PUT', endpoints.menusPutMenuItems, payload);
      return BackendResponseService.getBackendResponseData(result);
    } else {
      console.error('putAddMenuItem() - payload is not defined');
      return null;
    }
  } catch (err) {
    console.error(types.MENUS_PUT, err);
    throw err;
  }
});

export const fetchMenusGuards = createAsyncThunk<GetMenusGuardsFields | null, AsyncThunkParams<GetMenusGuardsResponse>>(
  types.MENUS_GUARDS_FETCH,
  async ({ execute }) => {
    try {
      const result = await execute('GET', `${endpoints.menusGetGuards}`);
      return BackendResponseService.getBackendResponseData(result);
    } catch (err) {
      console.error(types.MENUS_GUARDS_FETCH, err);
      return null;
    }
  },
);
