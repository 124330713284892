import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { PageSubTab, PageTab } from 'components/shared/TabbedPage/types';
import { RightTabsType, useResetNavigationBar } from './use-reset-navigation-bar';
import { useNavigateDefaultRoute } from './use-navigate-default-route';
import { TabsService } from 'services/TabsService';
import { AppLinks } from 'router/uri';
import { TabsValidationContext } from 'contexts/Validation/tabs-validation.context';

interface Props {
  tabsList: PageTab[];
  baseRoute: string;
  defaultTab: string;
}

export const useTabbedPages = ({ tabsList, baseRoute, defaultTab }: Props) => {
  const { tab = '', subtab = '', siteId = '' } = useParams();
  const [currentTab, setCurrentTab] = useState<PageTab | null>(null);
  const [currentSubTab, setCurrentSubTab] = useState<PageSubTab | null>(null);
  const [currentSubTabsMenu, setCurrentSubTabsMenu] = useState<PageSubTab[]>([]);
  const [tabsForResetHook, setTabsForResetHook] = useState<RightTabsType | null>(null);

  const tabValidator = useContext(TabsValidationContext);

  // set default tab if no tab is provided in url
  useNavigateDefaultRoute(baseRoute, defaultTab);
  useResetNavigationBar(tab, subtab, tabsForResetHook || {}, baseRoute, siteId);

  // set current tab and sub-tab objects in state
  useEffect(() => {
    const detectedTab = tabsList.find((tabConfig) => TabsService.getTagSlugByName(tabConfig.tab) === tab);

    if (!detectedTab) {
      setCurrentTab(null);
      return;
    }

    setCurrentTab(detectedTab);

    const hasSubTabs = 'rightTabs' in detectedTab;

    if (subtab && hasSubTabs && detectedTab.rightTabs[subtab]) {
      setCurrentSubTab(detectedTab.rightTabs[subtab]);
      setCurrentSubTabsMenu(Object.values(detectedTab.rightTabs));
    } else {
      setCurrentSubTab(null);
    }

    if (tabValidator) {
      tabValidator.resetState();
    }
  }, [tab, subtab, tabsList]);

  // build tabs array for useResetNavigationBar hook
  useEffect(() => {
    const rightTabs: RightTabsType = {};

    for (const tabsListElement of tabsList) {
      const slug = TabsService.getTagSlugByName(tabsListElement.tab);

      if ('rightTabs' in tabsListElement) {
        rightTabs[slug] = Object.values(tabsListElement.rightTabs).map((rightTab) => ({
          link: rightTab.link as AppLinks,
          text: rightTab.text,
          icon: rightTab.icon,
        }));
      } else {
        rightTabs[slug] = [];
      }
    }

    setTabsForResetHook(rightTabs);
  }, [tabsList]);

  const tabHasSubTabs = Boolean(currentTab && currentSubTabsMenu.length > 1 && 'rightTabs' in currentTab);

  return {
    currentTab,
    currentSubTab,
    tabValidator,
    currentSubTabsMenu,
    tabHasSubTabs,
  };
};
