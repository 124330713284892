import { FC, MouseEventHandler, ReactElement } from 'react';

import './CoreButton.scss';

import classNames from 'classnames';
import { Icon } from 'components/shared/Icon/Icon';
import { IconEnums } from 'common/IconEnums';
import { IconDirectionEnum } from './types';
import { SizeEnums } from 'common/SizeEnums';

interface Props {
  text?: string;
  icon?: IconEnums;
  iconPosition?: IconDirectionEnum;
  rotateIconDegree?: number;
  onClick: MouseEventHandler;
  className: string;
  error?: boolean;
  disabled?: boolean;
  withNotification?: boolean;
  iconSize?: SizeEnums;
  isSelected?: boolean;
  withoutBackground?: boolean;
  textElement?: ReactElement;
}

export const CoreButton: FC<Props> = ({
  text,
  icon,
  onClick,
  className,
  iconPosition = IconDirectionEnum.BEFORE,
  rotateIconDegree,
  error = false,
  disabled = false,
  withNotification = false,
  iconSize,
  isSelected,
  withoutBackground = false,
  textElement,
}) => {
  const coreButtonClassNames = classNames({
    'core-button-container': true,
    [className]: className,
    'core-button-container--error': error,
    'core-button-container--disabled': disabled,
    'core-button-container--selected': isSelected,
    'core-button-container--without-background': withoutBackground,
  });

  return (
    <div
      className={coreButtonClassNames}
      onClick={
        !disabled
          ? onClick
          : () => {
              return;
            }
      }
      role="presentation"
    >
      {icon && iconPosition === IconDirectionEnum.BEFORE && (
        <Icon iconName={icon} rotateIconDegree={rotateIconDegree} iconSize={iconSize} />
      )}
      {(text || textElement) && (
        <div className="core-button-text">
          {text}
          {textElement}
        </div>
      )}
      {icon && iconPosition === IconDirectionEnum.AFTER && (
        <Icon iconName={icon} rotateIconDegree={rotateIconDegree} iconSize={iconSize} />
      )}
      {withNotification && <div className="notification-circle"></div>}
    </div>
  );
};
