import { FC } from 'react';
import classNames from 'classnames';
import './SystemBlockHeader.scss';
import { Icon } from 'components/shared/Icon/Icon';
import { IconEnums } from 'common/IconEnums';
import { SizeEnums } from 'common/SizeEnums';
import { SystemBlockHeaderProps } from 'components/shared/SystemBlock/types';
import { Separator } from 'components/shared/Separator/Separator';
import { ToolTip } from 'components/shared/ToolTip/ToolTip';
import { useHover } from 'hooks/use-hover';
import { Initials } from 'components/shared/Initials/Initials';
import { CoreButton } from 'components/shared/CoreButton/CoreButton';

interface Props {
  headerProps: SystemBlockHeaderProps;
}

export const SystemBlockHeader: FC<Props> = ({
  headerProps: {
    systemBlockHeaderText,
    userName,
    buttonText,
    toggleHeaderButton,
    toolTipText,
    isButtonToggled,
    handleToggledHeaderButton,
    isHeaderLine = true,
  },
}) => {
  const coreButtonClassName = classNames({
    'action-button': true,
    ['action-button--ml-20']: userName,
  });
  const [hoverRef, isHovered] = useHover<HTMLSpanElement>();

  return (
    <>
      <div className="system-block-header">
        <div className="system-block-header__header-text">{systemBlockHeaderText}</div>
        {toolTipText && (
          <span className="system-block-header__header-info" ref={hoverRef}>
            <Icon iconName={IconEnums.EXCLAMATION_MARK} iconSize={SizeEnums.SMALL} />
            {isHovered && <ToolTip>{toolTipText}</ToolTip>}
          </span>
        )}
        {userName && <Initials userName={userName} className="system-block-header__user-initials" />}
        {buttonText && toggleHeaderButton && (
          <>
            <CoreButton
              text={buttonText}
              icon={IconEnums.PLUS}
              onClick={toggleHeaderButton}
              className={coreButtonClassName}
              iconSize={SizeEnums.SMALL_MEDIUM}
            />
            {isButtonToggled && handleToggledHeaderButton && handleToggledHeaderButton()}
          </>
        )}
      </div>
      {isHeaderLine && <Separator className="no-margin" />}
    </>
  );
};
