import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'config/auth';
import { useCallback } from 'react';

export const useLoginActions = () => {
  const { instance } = useMsal();

  const login = useCallback(() => {
    instance.loginRedirect(loginRequest).catch((err) => console.log(err));
  }, [instance]);

  const logout = useCallback(() => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  }, [instance]);

  return {
    login,
    logout,
  };
};
