import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import './TopNavigationBar.scss';

import { SystemCta } from 'components/shared/SystemCta/SystemCta';
import { Tabs } from 'components/shared/Tabs/Tabs';
import classNames from 'classnames';
import { PageTab } from 'components/shared/TabbedPage/types';

interface Props extends PropsWithChildren {
  topTabsList: Omit<PageTab, 'component' | 'rightTabs'>[];
  submitButtonText: string;
  submitButtonIsLoading?: boolean;
  submitButtonIsSucceed?: boolean;
  submitButtonIsDisabled?: boolean;
  handleUpdate: MouseEventHandler;
  className?: string;
  onBeforeTabChange?: () => Promise<boolean>;
  isMinimizeMode?: boolean;
  isBrandModule?: boolean;
  submitButtonWithBorder?: boolean;
}

export const TopNavigationBar: FC<Props> = ({
  topTabsList,
  submitButtonText,
  submitButtonIsLoading = false,
  submitButtonIsSucceed = false,
  submitButtonIsDisabled = false,
  handleUpdate,
  className = '',
  onBeforeTabChange,
  children,
  isMinimizeMode,
  isBrandModule,
  submitButtonWithBorder,
}) => {
  const navigationClassName = classNames({
    'top-navigation-bar-container': true,
    'top-navigation-bar-container--minimize': isMinimizeMode,
    'top-navigation-bar-container--minimize--brand-module': isBrandModule,
    [className]: className,
  });

  return (
    <div className={navigationClassName}>
      {topTabsList.length > 1 && <Tabs topTabsList={topTabsList} onBeforeTabChange={onBeforeTabChange} />}
      <div className="submit-btn">
        {children || (
          <SystemCta
            text={submitButtonText}
            isLoading={submitButtonIsLoading}
            isSucceed={submitButtonIsSucceed}
            isDisabled={submitButtonIsDisabled || submitButtonIsLoading}
            onClick={handleUpdate}
            withBorder={submitButtonWithBorder}
          />
        )}
      </div>
    </div>
  );
};
