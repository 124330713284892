import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetBrandWidgetFields, WidgetsState } from './types';
import { fetchBrandWidget } from './actions';

const initialState: WidgetsState = {
  widgetEditor: null,
};

export const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    clearWidgetsState(state) {
      state.widgetEditor = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchBrandWidget.fulfilled,
      (state: WidgetsState, action: PayloadAction<GetBrandWidgetFields | null>) => {
        if (action.payload !== null) {
          state.widgetEditor = action.payload;
        }
      },
    );
  },
});
