import { FC, PropsWithChildren } from 'react';

import './ToolTip.scss';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
  className?: string;
  top?: number;
  left?: number;
}

export const ToolTip: FC<Props> = ({ children, className, top, left }) => {
  return (
    <div className={classNames('tool-tip-container', className)} style={{ top, left }}>
      {children}
    </div>
  );
};
