import { RadioItems } from 'components/modules/Assets/AssetsRadioButtons/AssetsRadioButtons';

export enum ColorTypes {
  LIGHT = 1,
  DARK = 2,
}

export enum AssetTypes {
  REST_COLOR = 13,
  SELECTED_COLOR = 14,
}

export enum ThemeRowPickers {
  BACKGROUND = 1,
  TITLE_TEXT = 2,
  ICONS = 3,
  SEPARATORS = 4,
  BOARDERS = 5,
  BODY_TEXT = 6,
  SHADOWS = 7,
  PALETTE = 6,
  BORDER = 20,
}

export enum ThemeRowPickersNamesEnum {
  BACKGROUND = 'Background',
  TITLE_TEXT = 'Title text',
  BODY_TEXT = 'Body text',
  ICONS = 'Icons',
  SEPARATORS = 'Separators',
  BOARDERS = 'Boarders',
  SHADOWS = 'Shadows',
}

type ThemeRowPicker = {
  name: ThemeRowPickersNamesEnum;
  pickerType: ThemeRowPickers;
};

export const themeRowPickersNames: ThemeRowPicker[] = [
  {
    name: ThemeRowPickersNamesEnum.BACKGROUND,
    pickerType: ThemeRowPickers.BACKGROUND,
  },
  {
    name: ThemeRowPickersNamesEnum.TITLE_TEXT,
    pickerType: ThemeRowPickers.TITLE_TEXT,
  },
  {
    name: ThemeRowPickersNamesEnum.BODY_TEXT,
    pickerType: ThemeRowPickers.BODY_TEXT,
  },
  {
    name: ThemeRowPickersNamesEnum.ICONS,
    pickerType: ThemeRowPickers.ICONS,
  },
  {
    name: ThemeRowPickersNamesEnum.SEPARATORS,
    pickerType: ThemeRowPickers.SEPARATORS,
  },
  {
    name: ThemeRowPickersNamesEnum.BOARDERS,
    pickerType: ThemeRowPickers.BOARDERS,
  },
  {
    name: ThemeRowPickersNamesEnum.SHADOWS,
    pickerType: ThemeRowPickers.SHADOWS,
  },
];

export const radioItems: RadioItems = {
  defaultValue: 'light',
  items: [
    {
      name: 'Light HEX',
      value: 'light',
    },
    {
      name: 'Dark HEX',
      value: 'dark',
    },
  ],
};
