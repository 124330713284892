import { AppLinks } from 'router/uri';
import { lazy, useMemo } from 'react';
const Status = lazy(() => import('components/modules/BrandSettings/Status/Status/Status' /* webpackPrefetch: true */));
const Details = lazy(
  () =>
    import(
      'components/modules/BrandSettings/Details/Details/Details'
      /* webpackPrefetch: true */
    ),
);
const Authorization = lazy(
  () =>
    import(
      'components/modules/BrandSettings/Authorization/Authorization/Authorization'
      /* webpackPrefetch: true */
    ),
);
const Integrations = lazy(
  () =>
    import(
      'components/modules/BrandSettings/Integrations/Integrations/Integrations'
      /* webpackPrefetch: true */
    ),
);

const brandsTabList = [
  {
    link: AppLinks.BRAND_SETTINGS_TABULATOR_TAB,
    tab: 'Status',
    component: <Status />,
  },
  {
    link: AppLinks.BRAND_SETTINGS_TABULATOR_TAB,
    tab: 'Details',
    component: <Details />,
  },
  {
    link: AppLinks.BRAND_SETTINGS_TABULATOR_TAB,
    tab: 'Authorization',
    component: <Authorization />,
  },
];

const integrationsTab = {
  link: AppLinks.BRAND_SETTINGS_TABULATOR_TAB,
  tab: 'Integrations',
  component: <Integrations />,
};

export const useBrandsSettingsTabs = (isRegulationsIds: boolean) =>
  useMemo(() => (isRegulationsIds ? [...brandsTabList, integrationsTab] : brandsTabList), [isRegulationsIds]);

export enum BrandSettingsTabsCodes {
  STATUS = 'status',
  DETAILS = 'Details',
  AUTHORIZATION = 'authorization',
  INTEGRATIONS = 'integrations',
}
